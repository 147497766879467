import React from 'react';

import Sidebar from './Sidebar';
import Header from './Header';
import style from './layout.module.scss';

import { APP_CONTENT } from 'utils/constants';
import SidebarProvider from 'contexts/sidebar';
import AttendanceProvider from 'contexts/attendance';
import SocketProvider from 'contexts/socket';
import Home from 'pages/Home';
import Attendance from 'pages/Attendance';
import Timesheet from 'pages/Timesheet';

import { useGlobalContext } from 'contexts';

function Layout() {
  const [state] = useGlobalContext();
  const { contentToShow } = state;
  const content: any = {
    [APP_CONTENT.HOME]: <Home />,
    [APP_CONTENT.TIMESHEET]: <Timesheet />,
    [APP_CONTENT.ATTENDANCE]: <Attendance />,
  };

  const isHome = contentToShow === APP_CONTENT.HOME;

  const styles = {
    layout: isHome ? style.HomeLayoutWebCmpt : style.layoutWebCmpt,
    sidebar: isHome ? style.homeSidebar : style.sidebar,
    container: isHome ? style.homeContainer : style.container,
    header: isHome ? style.homeHeader : style.header,
    content: isHome ? style.homeContent : style.content,
  };

  return (
    <SocketProvider>
      <div className={styles.layout}>
        <div className={styles.sidebar}>
          <SidebarProvider>
            <Sidebar />
          </SidebarProvider>
        </div>
        <AttendanceProvider>
          <div className={styles.container}>
            <div className={styles.header}>
              <Header />
            </div>
            <div className={styles.content}>{content[contentToShow]}</div>
          </div>
        </AttendanceProvider>
      </div>
    </SocketProvider>
  );
}

export default Layout;
