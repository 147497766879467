import React, { useState } from 'react';

import style from './TimeInputEditable.module.scss';

import TimeInput from 'components/TimeInput';
import * as ReactTimeInputPolyfill from 'components/TimeInputPolyfill/ReactTimeInputPolyfill';

type TimeInputEditableProps = {
  currentValue: string;
  onInputChange: any;
  className?: string;
  format?: ReactTimeInputPolyfill.TimeFormat;
  readonly?: boolean;
};

export default function TimeInputEditable(props: TimeInputEditableProps): JSX.Element {
  const { currentValue, onInputChange, format, className, readonly } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <TimeInput
      currentValue={currentValue}
      onInputChange={onInputChange}
      format={format}
      className={`${className} ${style.timeField} ${isEditing ? style.active : ''}`}
      onBlur={() => (isEditing && setIsEditing(false))}
      onClick={() => (!isEditing && setIsEditing(true))}
      disabled={readonly}
    />
  );
}

TimeInputEditable.defaultProps = {
  className: '',
  format: '24',
  readonly: false,
};
