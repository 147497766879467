import React from 'react';

export default function AttendanceError(): JSX.Element {
  return (
    <div className="ui floating negative message">
      <div className="header">
        There is a problem loading the attendances.
      </div>
      <p>
        Please call system administrator
      </p>
    </div>
  );
}
