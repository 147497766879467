import { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { RequestOptions } from 'service/RequestOptions';

import { getOptionsLimit } from 'utils/paginationSetup';
import { CANCEL_CALLBACK_ERROR } from 'utils/constants';

export function useApi(options: Promise<RequestOptions>): any[] {
  const [hadFirstLoad, setHadFirstLoad] = useState(false);
  const [cancelOperation, setCancelOperation] = useState<any>(undefined);
  const [state, setState] = useState<any>({
    error: false, loading: true, data: null, totalPages: 0,
  });

  function setData(params: any, error = false) {
    const data = params.data?.data;
    const totalPages = Math.ceil(params.data?.data.totalRows / params.data?.data.limit);
    const paginationSetup = getOptionsLimit(params.data?.data.totalRows);
    setState({
      error, loading: false, data, totalPages, paginationSetup,
    });
  }

  async function refresh() {
    if (typeof cancelOperation !== typeof undefined) {
      cancelOperation.cancel(CANCEL_CALLBACK_ERROR);
    }
    const cancelToken = axios.CancelToken.source();
    setCancelOperation(cancelToken);
    try {
      const requestOptions = await (_.isFunction(options) ? options() : options);
      requestOptions.options.cancelToken = cancelToken.token;
      const data = await requestOptions.toAxios();
      setData(data);
      setHadFirstLoad(true);
    } catch (error) {
      if (error?.message !== CANCEL_CALLBACK_ERROR) {
        setData({}, error);
      }
    }
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  const { data, loading } = state;
  return [data, state, refresh, setState, loading, hadFirstLoad];
}
