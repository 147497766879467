import React, { useState } from 'react';
import { noop } from 'lodash';
import dayjs from 'dayjs';

import { FiEye, FiPlusSquare } from 'react-icons/fi';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import Icon from '../../../components/Icon';
import { convertMilSec } from '../../../utils/convertMilSec';

import { Avatar } from 'components/Avatar';
import style from './User.module.scss';

import UserActions from 'layout/Sidebar/Users/UserActions';
import { APP_CONTENT, EVENT_TYPE } from 'utils/constants';

const getTotalHours = (mode: string, total: number, totalAct: number): string => {
  if (mode === APP_CONTENT.ATTENDANCE) return convertMilSec(total);
  if (mode === APP_CONTENT.TIMESHEET) return convertMilSec(totalAct);
  return '';
};

type Mode = 'attendance' | 'timesheet' | 'home' | string;

function getUserStatusProps(userStatus: string, picture: any, name: string): any {
  const userStatusData: any = {
    checkIn: {
      isCheckIn: true,
      initialTextStyle: style.available,
      initialLabelText: 'AVAILABLE',
    },
    checkOut: {
      isCheckIn: false,
      initialTextStyle: `${style.available} ${style.notAvailable}`,
      initialLabelText: 'NOT AVAILABLE',
    },
  };
  const { isCheckIn, initialTextStyle, initialLabelText } = userStatusData[userStatus];
  return {
    avatarProps: { src: picture, name, size: 50, isCheckIn },
    labelStatusProps: { className: initialTextStyle, text: initialLabelText },
  };
}

type UserAttendanceItemProps = {
  userId: string,
  date: any,
  milliseconds: number,
  onAttendanceDetailClick: (userId: string, date: string) => void;
}

function UserAttendanceItem({
  userId, date, milliseconds, onAttendanceDetailClick }: UserAttendanceItemProps): JSX.Element {
  return (
    <li className={style.detailListItem}>
      <span className={style.detailListItemDate}>{date.format('ddd, MMM Do')}</span>
      <span className={style.detailListItemHours}>{convertMilSec(milliseconds)}</span>
      <div className={style.detailListItemActions}>
        <FiEye
          className={style.detailListItemDetailIcon}
          size={25}
          onClick={() => onAttendanceDetailClick(userId, date)}
        />
      </div>
    </li>
  );
}

type UserAttendanceDetailsProps = {
  userId: string,
  data: number[],
  dateRange: any[],
  onAttendanceDetailClick: (userId: string, date: any) => void,
};

function UserAttendanceDetails({
  userId, data, dateRange, onAttendanceDetailClick }: UserAttendanceDetailsProps): JSX.Element {
  return (
    <div className={style.detailContainer}>
      <div className={style.detailReport}>
        <></>
      </div>
      <ul className={style.detailList}>
        {
          data.length === dateRange.length && data.map((value: number, index: number) => (
            <UserAttendanceItem
              key={`${userId}-${dateRange[index]}`}
              userId={userId}
              date={dateRange[index]}
              milliseconds={value}
              onAttendanceDetailClick={() => onAttendanceDetailClick(userId, dateRange[index])}
            />
          ))
        }
      </ul>
    </div>
  );
}

type UserTimesheetItemProps = {
  userId: string,
  date: any,
  milliseconds: number,
  globalUserIsAdmin: boolean,
  globalUserIsManager: boolean,
  globalUserId: string,
  onAddActivityClick: (userId: string, date: string) => void,
  onDownloadDailyReportClick: (userId: string, date: string) => void,
};

function UserTimesheetItem({
  userId,
  date,
  milliseconds,
  globalUserIsAdmin,
  globalUserIsManager,
  globalUserId,
  onAddActivityClick,
  onDownloadDailyReportClick,
}: UserTimesheetItemProps): JSX.Element {
  return (
    <li className={style.detailListItem}>
      <span className={style.detailListItemDate}>{date.format('ddd, MMM Do')}</span>
      <span className={style.detailListItemHours}>{convertMilSec(milliseconds)}</span>
      <div className={style.detailListItemActions}>
        {(globalUserIsAdmin || globalUserIsManager || userId === globalUserId) &&
        (<FiPlusSquare
          className={`${style.detailListItemPlusIcon}`}
          onClick={() => onAddActivityClick(userId, date)}
          size={25}
        />)
        }
        {
          milliseconds !== 0 && (
            <Icon.Report
              className={style.detailListItemReportIcon}
              onClick={() => onDownloadDailyReportClick(userId, date)}
              size={25}
            />
          )
        }
      </div>
    </li>
  );
}

type UserTimesheetDetailsProps = {
  userId: string,
  data: number[],
  total: number,
  dateRange: any[],
  globalUserIsAdmin: boolean,
  globalUserIsManager: boolean,
  globalUserId: string,
  onAddActivityClick: (userId: string, date: string) => void,
  onDownloadDailyReportClick: (userId: string, date: string) => void,
  onDownloadReportClick: (userId: string) => void,
};

function UserTimesheetDetails({
  userId,
  data,
  dateRange,
  total,
  globalUserIsAdmin,
  globalUserIsManager,
  globalUserId,
  onAddActivityClick,
  onDownloadDailyReportClick,
  onDownloadReportClick,
}: UserTimesheetDetailsProps): JSX.Element {
  return (
    <div className={style.detailContainer}>
      <div className={style.detailReport}>
        {
          total !== 0 && (
            <Icon.Report className={style.downloadIcon} size={25} onClick={() => onDownloadReportClick(userId)} />
          )
        }
      </div>
      <ul className={style.detailList}>
        {
          data.length === dateRange.length && data.map((value: number, index: number) => (
            <UserTimesheetItem
              key={`${userId}-${dateRange[index].unix()}`}
              userId={userId}
              date={dateRange[index]}
              milliseconds={value}
              globalUserIsAdmin={globalUserIsAdmin}
              globalUserIsManager={globalUserIsManager}
              globalUserId={globalUserId}
              onAddActivityClick={onAddActivityClick}
              onDownloadDailyReportClick={onDownloadDailyReportClick}
            />
          ))
        }
      </ul>
    </div>
  );
}

type UserInfo = {
  id: string,
  name: string,
  email: string,
  status: string,
  picture: any,
  updatedAt: string,
  totalDays: number[],
  total: number,
  totalActDays: number[],
  totalAct: number,
  preferredUsername: string,
};

type UserProps = {
  user: UserInfo,
  actions?: Array<any>,
  selected?: boolean,
  mode: Mode,
  dateRange?: any[],
  globalUserIsAdmin: boolean,
  globalUserIsManager: boolean,
  globalUserId: string,
  onClick?: (user: any) => void,
  onAttendanceDetailClick?: (userId: string, date: any) => void,
  onAddActivityClick?: (userId: string, date: any) => void,
  onDownloadReportClick?: (userId: string) => void,
  onDownloadDailyReportClick?: (userId: string, date: any) => void,
}

export default function User({
  user,
  actions,
  selected,
  mode,
  dateRange,
  globalUserIsAdmin,
  globalUserIsManager,
  globalUserId,
  onClick,
  onAttendanceDetailClick,
  onAddActivityClick, onDownloadReportClick, onDownloadDailyReportClick,
}: UserProps): JSX.Element {
  const [showDetails, setShowDetails] = useState(false);
  const { avatarProps, labelStatusProps } = getUserStatusProps(user.status ?? EVENT_TYPE.CHECK_OUT, user.picture, user.preferredUsername);

  const handleOnClick = () => {
    setShowDetails(!showDetails);
    if (onClick) {
      onClick(user);
    }
  };

  return (
    <div className={style.container}>
      <div
        className={selected ? `${style.userInfoContainer} ${style.userSelected}` : style.userInfoContainer}
        onClick={handleOnClick}
        onKeyDown={() => ({})}
        tabIndex={0}
        role="button"
      >
        <div className={style.userImage}>
          <Avatar {...avatarProps} />
        </div>
        <div className={style.userInfo}>
          <div className={`autoDots ${style.userInfoName}`}>
            {user.preferredUsername}
          </div>
          <div className={`autoDots ${style.userInfoEmail}`}>
            {user.email}
          </div>
          <div className={style.userStatus}>
            <span className={labelStatusProps.className}>{labelStatusProps.text}</span>
            {/* <span className={style.lastUserConnection}>
              {user.updatedAt ? dayjs(user.updatedAt).format('h:mm A') : ''}
            </span> */}
          </div>
        </div>
        <div className={style.userTotalHours}>
          {
            mode !== APP_CONTENT.HOME && (
              <>
                <span>
                  {getTotalHours(mode, user.total, user.totalAct)}
                </span>
                {
                  showDetails ? (
                    <MdKeyboardArrowUp size={25} />
                  ) : (
                    <MdKeyboardArrowDown size={25} />
                  )
                }
              </>
            )
          }
          <span className={style.lastUserConnection}>
            {user.updatedAt ? dayjs(user.updatedAt).format('h:mm A') : ''}
          </span>
        </div>
        <div className={style.actions}>
          <UserActions className={style.userActions} actions={actions || []} />
        </div>
      </div>
      {
        showDetails && mode === APP_CONTENT.ATTENDANCE && (
          <UserAttendanceDetails
            data={user.totalDays}
            dateRange={dateRange ?? []}
            onAttendanceDetailClick={
              (userId: string, date: any) => onAttendanceDetailClick && onAttendanceDetailClick(userId, date)
            }
            userId={user.id}
          />
        )
      }
      {
        showDetails && mode === APP_CONTENT.TIMESHEET && (
          <UserTimesheetDetails
            total={user.totalAct}
            data={user.totalActDays}
            dateRange={dateRange ?? []}
            userId={user.id}
            globalUserIsAdmin={globalUserIsAdmin}
            globalUserIsManager={globalUserIsManager}
            globalUserId={globalUserId}
            onAddActivityClick={
              (userId: string, date: string) => onAddActivityClick && onAddActivityClick(userId, date)
            }
            onDownloadReportClick={
              (userId: string) => onDownloadReportClick && onDownloadReportClick(userId)
            }
            onDownloadDailyReportClick={
              (userId: string, date: string) => onDownloadDailyReportClick && onDownloadDailyReportClick(userId, date)
            }
          />
        )
      }
    </div>
  );
}

User.defaultProps = {
  actions: [],
  selected: false,
  dateRange: [],
  onClick: noop,
  onAttendanceDetailClick: noop,
  onAddActivityClick: noop,
  onDownloadReportClick: noop,
  onDownloadDailyReportClick: noop,
};
