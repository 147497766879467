import React, { useCallback, useState, useEffect } from 'react';
import { Icon, Placeholder } from 'semantic-ui-react';
import _ from 'lodash';

import style from './CardDetails.module.scss';

import { getLastActivity } from 'layout/Sidebar/Users/UserItem/utils';

import { Avatar } from 'components/Avatar';
import { EVENT_TYPE } from 'utils/constants';

const CardDetails = ({ onOpen, type, createdAt = '' }: any) => {
  const [state, setState] = useState<any>({ loading: true, data: {} });

  const workWithoutState = !!createdAt;

  const getClassname = (isTrue: string, isFalse: string) => {
    if (workWithoutState) {
      return type === EVENT_TYPE.CHECK_IN ? isTrue : isFalse;
    }
    return state.data.type === EVENT_TYPE.CHECK_IN ? isTrue : isFalse;
  };

  const handleOnOpen = useCallback(async () => {
    try {
      const data = await onOpen();
      const dateToFormat = createdAt || data.updatedAt;
      const connectionHour = getLastActivity(dateToFormat, false);
      setState({ loading: false, data, connectionHour });
    } catch (error) {
      setState({ ...state, loading: false });
    }
  }, [onOpen, createdAt]); // eslint-disable-line

  useEffect(() => {
    handleOnOpen();
  }, [type, handleOnOpen]);

  return state.loading || _.isEmpty(state.data) ? (
    <Placeholder className={style.placeholder}>
      <Placeholder.Header image>
        <Placeholder.Line length="very long" />
        <Placeholder.Line length="very long" />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line length="full" />
      </Placeholder.Paragraph>
    </Placeholder>
  ) : (
    <>
      <div className={style.userInfo}>
        <Avatar
          src={state.data.picture}
          name={state.data.preferredUsername}
          size={50}
          isCheckIn={getClassname('true', 'false') === 'true' ? true : false}
        />
        <div className={style.userStateInfo}>
          <div className={style.userState}>
            <Icon
              {...{
                name: 'arrow circle right',
                size: 'large',
                className: `arrowSize ${getClassname('connected', 'disconnected')}`,
              }}
            />
            <div className={style.connectionHour}>{state.connectionHour}</div>
          </div>
          <div className={`available ${getClassname('connected', 'disconnected')}`}>
            {`${workWithoutState ? getClassname('check in', 'check out') : getClassname('available', 'not available')}`}
          </div>
        </div>
      </div>
      <div>
        <div className={style.userName}>{state.data.preferredUsername}</div>
      </div>
    </>
  );
};

export default CardDetails;
