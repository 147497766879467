import querystring from 'querystring';

import join from 'url-join';

import { QueryOptions, QueryOptionsTimesheet } from '../types';
import { apiUrl } from '../config/settings';

import { BaseService } from './BaseService';
import { RequestOptions } from './RequestOptions';
import _ from 'lodash';

function buildApiUrl(url: string, path: string, ...args: any): string {
  const extraPaths = args.filter(Boolean).map(String);
  return extraPaths.length > 0 ? join(url, path, ...extraPaths) : join(url, path);
}

export class UserService extends BaseService {
  constructor() {
    super('/api/v1/users');
  }

  // eslint-disable-next-line class-methods-use-this
  addIfNotNull(base: any, field: string, value: any) {
    // eslint-disable-next-line no-param-reassign
    if (value) base[field] = value;
  }

  buildOptions(options: QueryOptionsTimesheet) {
    const { skip, limit, fields, filters, orders, startWeek, endWeek, tz } = options;

    const queryObject = {};
    this.addIfNotNull(queryObject, 'skip', skip);
    this.addIfNotNull(queryObject, 'limit', limit);
    this.addIfNotNull(queryObject, 'fields', fields);
    this.addIfNotNull(queryObject, 'filters[]', filters);
    this.addIfNotNull(queryObject, 'orders[]', orders);
    this.addIfNotNull(queryObject, 'startWeek', startWeek);
    this.addIfNotNull(queryObject, 'endWeek', endWeek);
    this.addIfNotNull(queryObject, 'tz', tz);

    const query = querystring.encode(queryObject);
    return query ? `?${query}` : '';
  }

  async me(queryOptions = {}): Promise<RequestOptions> {
    const options = await this.getAll(queryOptions, 'me');
    return options;
  }

  async getTimesheetByUser(userId: string, projectId: string, queryOptions: QueryOptionsTimesheet): Promise<any> {
    const url = buildApiUrl(apiUrl, this.path);
    const queryString = this.buildOptions(queryOptions);
    return this.options({ url: `${url}/${userId}/timesheets${queryString}&projectId=${projectId}` });
  }
  async search(query: string, filter: any, queryOptions: QueryOptions): Promise<RequestOptions> {
    const filterUri = !_.isEmpty(filter) ? `filter=${encodeURIComponent(JSON.stringify(filter))}&` : '';
    const queryUri = encodeURIComponent(query)
    const url = this.urlFor(queryOptions, this.path, `?query=${queryUri}&${filterUri}`);

    return this.options({ url });
  }

  async getUsers(queryOptions: QueryOptions = {}): Promise<any> {
    return this.getAll(queryOptions);
  }

  async getEventsByUser(
    userId: string, startDate: string, endDate: string, timezone: string, queryOptions: QueryOptions, groupId?: string,
  ): Promise<any> {
    const groupIdQuery = groupId ? `groupId=${groupId}&` : '';
    return this.options({
      url: this.urlFor(
        queryOptions,
        `${this.path}/${userId}/events`,
        `?startDate=${startDate}&endDate=${endDate}${timezone ? `&tz=${timezone}` : ''}&${groupIdQuery}`),
    });
  }

  async disableUser(userId: string, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/${userId}/disable`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, path) });
  }

  async updateUserPreferences(preferences: any, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/preferences`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, path), data: preferences });
  }

  async getUsersNotSelected(users: any, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/notSelected?`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, path), data: users });
  }

  async getUserProjects(userId: string, groupId?: string) {
    return this.options({ url: this.urlFor({}, `${this.path}/${userId}/projects?groupId=${groupId}`) });
  }

  async getUserManager(userId: string): Promise<any> {
    const url = buildApiUrl(apiUrl, this.path);
    return this.options({ url: `${url}/${userId}/manager` });
  }

  async addFavoriteGroup(groupId: string, userId: string, queryOptions: QueryOptions = {}): Promise<RequestOptions> {
    const requestPath = `${this.path}/${userId}/favorites/${groupId}`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, requestPath) });
  }

  async removeFavoriteGroup(groupId: string, userId: string, queryOptions: QueryOptions = {}): Promise<RequestOptions> {
    const requestPath = `${this.path}/${userId}/favorites/${groupId}`;
    return this.options({ method: 'delete', url: this.urlFor(queryOptions, requestPath) });
  }

  async getUserById(userId: string): Promise<any> {
    const url = buildApiUrl(apiUrl, this.path);
    return this.options({ url: `${url}/${userId}` });
  }
}
