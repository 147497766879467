import React from 'react';
import { Loader as SemanticLoader, SemanticSIZES } from 'semantic-ui-react';
import Lottie from 'react-lottie';

import style from './Loader.module.scss';

import animationData from 'assets/animation-data.json';

interface LoaderProps {
  className?: string;
  size?: string;
  [key: string]: any;
}

const LOGO_SIZES: any = {
  massive: 73,
  huge: 66,
  big: 59,
  large: 55,
  medium: 37,
  small: 27,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
export default function Loader({ className, size, ...props }: LoaderProps): JSX.Element {
  const selectedSize = LOGO_SIZES[!size ? 'large' : size];
  return (
    <SemanticLoader
      {...props}
      size={size as SemanticSIZES}
      className={`${className} ${style.removeDefaultAnimation} ${style[`logoLoading-${size}`]}`}
      content={<Lottie options={defaultOptions} width={selectedSize} height={selectedSize} />}
    />
  );
}

Loader.defaultProps = {
  className: '',
  size: 'large',
};
