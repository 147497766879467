import React from 'react';
import L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import MarkersList from '../Marker/MarkersList';

import style from './CustomMap.module.scss';

import { DEFAULT_ZOOM, MIN_ZOOM } from 'utils/constants';
import { getLocation } from 'utils/eventHandler';

import './MarkerGroup.scss';

function clusterCustomIcon(cluster: L.MarkerCluster): any {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'leaflet-marker-icon marker-group',
    iconSize: L.point(45, 45, true),
  });
}

function showClusterDetails({ layer }: any) {
  if (!layer || !layer.getChildCount) return;
  const usersCount = layer.getChildCount();
  layer[usersCount <= 10 ? 'spiderfy' : 'zoomToBounds']();
}

type CustomMapProps = {
  events: any[],
  location: any,
  zoomParam: number,
  onOpen: (userId: string) => void,
}

export default function CustomMap(props: CustomMapProps): JSX.Element {
  const { events, location, zoomParam, onOpen } = props;
  const { currentCoordinates } = getLocation(location);
  const zoom = zoomParam || DEFAULT_ZOOM;

  return (
    <Map className={style.mapSize} center={currentCoordinates} zoom={zoom} tap={false} minZoom={MIN_ZOOM} >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors. Search by <a href="https://locationiq.com">LocationIQ.com</a>'
      />
      <MarkerClusterGroup
        onClick={showClusterDetails}
        showCoverageOnHover={false}
        iconCreateFunction={clusterCustomIcon}
        zoomToBoundsOnClick={false}
      >
        <MarkersList userEvents={events} onOpen={onOpen} />
      </MarkerClusterGroup>
    </Map>
  );
}
