import React, { KeyboardEvent, SyntheticEvent, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItemProps, DropdownOnSearchChangeData } from 'semantic-ui-react';

import MemberItem from '../MemberItem';

import { isUUID, isEmail } from 'utils/validations';

import { UserService } from 'service';

const mapUserToDropdownOption = (user: any): DropdownItemProps => ({
  key: user._id,
  value: user._id,
  text: user.preferredUsername,
  email: user.email,
  content: (
    <MemberItem user={user} />
  ),
});

const mapMembers = (originalMembers?: Array<any>, emails?: Array<string>) => {
  const membersMapped = originalMembers?.map(mapUserToDropdownOption) ?? [];
  const emailsMapped = emails?.map((email: string) => ({ text: email, value: email, key: email })) ?? [];
  return membersMapped.concat(emailsMapped);
};

const optionExists = (options: Array<any>, key: string) => options.some((option: any) => option.key === key);

const userService = new UserService();

type Props = {
  members: Array<any>;
  originalMembers?: Array<any>;
  pendingUsers?: Array<any>;
  onMembersChanged: any;
  multiple?: boolean;
  allowAdditions?: boolean;
  allowSelections?: boolean;
};

export default function MemberSelector({
  members,
  originalMembers,
  pendingUsers,
  onMembersChanged,
  multiple,
  allowAdditions,
  allowSelections,
}: Props): JSX.Element {
  const defaultOptions = mapMembers(originalMembers, pendingUsers);
  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const [searchQueryInput, setSearchQueryInput] = useState('');
  const [searchResultOptions, setSearchResultOptions] = useState<any>([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const getAllUsers = async (usersNotSelected: Array<string> = []) => {
    const allUsersRequestOptions = await userService.getUsersNotSelected(usersNotSelected, { limit: 10 });
    const result = await allUsersRequestOptions.toAxios();
    return result.data?.data ?? [];
  };

  const loadUsers = useCallback(async (currentOptions: Array<any> = []) => {
    if (allowSelections) {
      const result = await getAllUsers(currentOptions.map((option) => option.key));
      setSearchResultOptions(result.map(mapUserToDropdownOption));
    }
  }, [allowSelections]);

  useEffect(() => {
    loadUsers(originalMembers?.map((originalMember: any) => ({ key: originalMember._id })));
  }, [loadUsers]); // eslint-disable-line

  useEffect(() => {
    if (searchTerm !== '' || !isFirstSearch) {
      const delayDebounceFn = setTimeout(async () => {
        setLoading(true);
        setIsFirstSearch(false)
        try {
          const searchRequestOptions = await userService.search(searchTerm, {}, { limit: 10 });
          let result = await searchRequestOptions.toAxios();
          result = result.data?.data?.users ?? [];
          setSearchResultOptions(result.map(mapUserToDropdownOption));
        } catch (err) {
          toast.error('Error searching users');
        } finally {
          setLoading(false);
        }
      }, 500);
  
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, 500]);

  const onSearchChange = async (
    event: SyntheticEvent<HTMLElement, Event>,
    { searchQuery }: DropdownOnSearchChangeData,
  ) => {
    setSearchQueryInput(searchQuery);
    setSearchTerm(searchQuery);
  };

  const onChange = (event: SyntheticEvent<HTMLElement, Event>, data: any) => {
    const value = multiple ? data.value : [data.value];
    const newValues = value.filter((item: any) => isUUID(item) || isEmail(item));
    const newSearchResultOptions = searchResultOptions.filter((option: any) => value.includes(option.key));
    let newOptions = options.filter((option: any) => newValues.includes(option.key));
    newOptions = [...newOptions, ...newSearchResultOptions];
    setIsChange(true);
    onMembersChanged(newValues);
    setOptions(newOptions);
    loadUsers(newOptions);
    setSearchQueryInput('');
    setSearchResultOptions([]);
  };

  const onAddItem = (event: KeyboardEvent<HTMLElement>, data: any) => {
    if (!optionExists(options, data.value) && isEmail(data.value)) {
      setOptions([
        ...options,
        {
          key: data.value,
          text: data.value,
          value: data.value,
        },
      ]);
    }
  };

  const searchFilter = () => searchResultOptions.filter((option: any) => !members?.includes(option.key));

  return (
    <Dropdown
      fluid
      selection
      closeOnChange
      multiple={multiple}
      search={allowSelections ? searchFilter : false}
      onChange={onChange}
      defaultValue={multiple ? members : isChange ? undefined : members.shift()}
      options={[...options, ...searchResultOptions]}
      placeholder="Search users"
      allowAdditions={allowAdditions}
      searchQuery={searchQueryInput}
      onSearchChange={onSearchChange}
      loading={loading}
      onAddItem={onAddItem}
    />
  );
}

MemberSelector.defaultProps = {
  originalMembers: [],
  pendingUsers: [],
  multiple: false,
  allowAdditions: false,
  allowSelections: false,
};
