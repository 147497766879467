import React from 'react';

import Loading from 'components/Loading';
import { useGlobalContext } from 'contexts';

export default function Logout() {
  const [, request] = useGlobalContext();
  const { logout } = request;
  logout();
  return <Loading />;
}
