import _ from 'lodash';

const paginationValues = [10, 15, 30, 50, 80, 100, 150, 200]
    .map((value) => ({ key: value, text: `${value}`, value }));

function getLessOptions() {
  return paginationValues.slice(0, 4);
}

function getGreaterOptions(totalRows: number) {
  const options = [];
  let optionPosition = 0;
  let isTotalRowsEgualsLimit = false;
  _.forEach(paginationValues, function(option, index) {
    if (totalRows >= option.value) {
      options.push(option);
      if(totalRows === option.value) {
        isTotalRowsEgualsLimit = true;
      } 
      optionPosition = index;
    }
  });

  if(!isTotalRowsEgualsLimit && !!paginationValues[optionPosition + 1]) {
    options.push(paginationValues[optionPosition + 1]);
  }
  return options;
}

export function getOptionsLimit(totalRows: number) {
  let optionsLimit = [];
  if(totalRows <= 50) {
    optionsLimit = getLessOptions();
  } else {
    optionsLimit = getGreaterOptions(totalRows);
  }
  return optionsLimit;
};
