import React from 'react';
import { noop } from 'lodash';
import { MdPerson } from 'react-icons/md';

import style from './GroupItem.module.scss';

interface Props {
  user?: any;
  isSelected?: boolean;
  onClick?: any;
}

function PersonalGroup({ user, isSelected, onClick }: Props) {
  const selected = isSelected ? `${style.groupItemCmpt} ${style.activeGroup}` : style.groupItemCmpt;
  const attrs = { className: selected, 'aria-hidden': true, onClick };

  return (
    <div {...attrs}>
      <div className={`autoDots ${style.groupText}`}>
        <MdPerson className={style.groupsIcon} size={19} />
        <span className={style.groupTextName}>{user.preferredUsername}</span>
      </div>
    </div>
  );
}

PersonalGroup.defaultProps = {
  user: {},
  isSelected: false,
  onClick: noop,
};

export default PersonalGroup;
