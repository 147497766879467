/* eslint-disable no-underscore-dangle */
import { GroupRequest } from '../types';

import { BaseService } from './BaseService';
import { RequestOptions } from './RequestOptions';

export class GroupService extends BaseService {
  constructor() {
    super('/api/v1/groups');
  }

  async getGroupsAndUsers(requestParameters: GroupRequest): Promise<RequestOptions> {
    const { queryOptions, selectedGroup, startWeek, search, type } = requestParameters;
    const requestPath = selectedGroup?._id
      ? `${selectedGroup._id}/users/${startWeek?.toISOString()}/pagination?search=${search}&`
      : `/pagination?search=${search}&type=${type}&`;
    return this.options({ url: this.urlFor(queryOptions, this.path, requestPath) });
  }

  async getFavoriteGroups(requestParameters: GroupRequest): Promise<RequestOptions> {
    const { queryOptions, search } = requestParameters;
    const requestPath = `/favorites?search=${search}&`;
    return this.options({ url: this.urlFor(queryOptions, this.path, requestPath) });
  }

  async getById(groupId: string, queryOptions = {}): Promise<RequestOptions> {
    return this.options({ url: this.urlFor(queryOptions, this.path, groupId) });
  }

  async createGroup(groupData: any, queryOptions = {}): Promise<RequestOptions> {
    return this.options({ method: 'post', url: this.urlFor(queryOptions, this.path), data: groupData });
  }

  async updateGroup(groupId: string, groupData: any, queryOptions = {}): Promise<RequestOptions> {
    return this.options({ method: 'put', url: this.urlFor(queryOptions, this.path, groupId), data: groupData });
  }

  async cloneGroup(groupData: any, queryOptions = {}): Promise<RequestOptions> {
    const requestPath = `/clone`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, this.path, requestPath), data: groupData });
  }
  
  async removeGroup(groupId: string, queryOptions = {}) : Promise<RequestOptions> {
    return this.options({ method: 'delete', url: this.urlFor(queryOptions, this.path, groupId) });
  }
}
