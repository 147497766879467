import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CURRENT_DATE, LAST_ACTIVITY_LABEL, HHMM, MONTH, WEEKDAY } from 'utils/constants';

dayjs.extend(relativeTime);

const findYesterday = (secs) => {
  let currentDateInSec = CURRENT_DATE.getHours();
  let parameterHour = new Date(secs);
  let remaingSec = parameterHour.getHours() - currentDateInSec;
  return remaingSec <= 24;
};

const isJustNow = (userDate) => {
  return (
    userDate.getMonth() === CURRENT_DATE.getMonth() &&
    userDate.getDate() === CURRENT_DATE.getDate() &&
    userDate.getHours() === CURRENT_DATE.getHours() &&
    userDate.getMinutes() === CURRENT_DATE.getMinutes()
  );
};

const isSameDay = (userDate, difference) => {
  return userDate.getDate() === CURRENT_DATE.getDate() - difference;
};

const isSameMonth = (userDate, difference) => {
  return userDate.getMonth() === CURRENT_DATE.getMonth() - difference;
};

const isYesterday = (userDate) => {
  const secs = userDate.getSeconds();
  return isSameDay(userDate, 1) && (isSameMonth(userDate, 0) || (isSameMonth(userDate, -1) && findYesterday(secs)));
};

const isToday = (userDate) => isSameDay(userDate, 0) && isSameMonth(userDate, 0);

const isInTheWeek = (userDate) =>
  (isSameDay(userDate, 1) ||
    isSameDay(userDate, 2) ||
    isSameDay(userDate, 3) ||
    isSameDay(userDate, 4) ||
    isSameDay(userDate, 5) ||
    isSameDay(userDate, 6)) &&
  isSameMonth(userDate, 0);

const getLastConnectionText = (userDate, withLastActivityLabel, useDateFormat = true) => {
  const userDateMonth = userDate.getMonth();
  const userDateDay = userDate.getDate();
  const userDateLocaleTime = userDate.toLocaleTimeString('en-US', HHMM);
  const lastActivityLabel = withLastActivityLabel ? LAST_ACTIVITY_LABEL : '';
  if (useDateFormat) {
    return `${lastActivityLabel} ${dayjs(userDate).fromNow()}`;
  }
  if (isJustNow(userDate)) {
    return `${lastActivityLabel} just now`;
  } else if (isToday(userDate)) {
    return `${lastActivityLabel} today at ${userDateLocaleTime}`;
  } else if (isYesterday(userDate)) {
    return `${lastActivityLabel} yesterday at ${userDateLocaleTime}`;
  } else if (isInTheWeek(userDate)) {
    return `${lastActivityLabel} on ${WEEKDAY[userDate.getDay()]} at ${userDateLocaleTime}`;
  } else {
    if (userDateDay.toString().length > 1) {
      return `${lastActivityLabel} ${userDateDay}-${MONTH[userDateMonth]}-${userDate.getFullYear()}`;
    } else {
      return `${lastActivityLabel} ${0}${userDateDay}-${MONTH[userDateMonth]}-${userDate.getFullYear()}`;
    }
  }
};

const getLastActivity = (stringDate, withLastActivityLabel = true) => {
  const userDate = new Date(stringDate);
  return getLastConnectionText(userDate, withLastActivityLabel);
};

export { getLastActivity };
