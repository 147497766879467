import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { styles } from './styles';

import { convertMinToHours } from 'utils/convertMinToHours';

type TimesheetReportFooterType = {
  totalHours: number,
}

function TimesheetReportFooter({ totalHours }: TimesheetReportFooterType): JSX.Element {
  return (
    <View style={styles.footer}>
      <Text style={styles.summary}>Hours Summary</Text>
      <View style={styles.totalHours}>
        <Text>Total Hours</Text>
        <Text>{convertMinToHours(totalHours)}</Text>
      </View>
    </View>
  );
}

export default TimesheetReportFooter;
