import dayjs from 'dayjs';

import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE, DEFAULT_TOTAL_PAGES } from 'utils/constants';

let startDate;
let endDate;
if (dayjs().day() === 0) {
  startDate = dayjs().startOf('w').day(-6);
  endDate = dayjs().endOf('d');
} else {
  startDate = dayjs().startOf('w').day(1);
  endDate = dayjs().endOf('w').day(7);
}

const initialState = {
  lastUsersEvents: [],
  selectedGroup: undefined,
  selectedGroupUser: [],
  error: null,
  attendancesList: [],
  summaries: [],
  project: null,
  meta: {
    paginationSetup: [{ key: 10, text: '10', value: 10 }],
    totalPages: DEFAULT_TOTAL_PAGES,
  },
  pagination: {
    activePage: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  },
  date: {
    startDate,
    endDate,
  },
  userTimesheet: null,
  week: [],
};

export default initialState;
