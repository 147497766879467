import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import dayjs from 'dayjs';

import { styles } from './styles';

import { CATEGORIES } from 'utils/constants';
import { convertMinToHours } from 'utils/convertMinToHours';

type ActivitiesType = {
  activities:Array<any>
  hasBackground: boolean
}

function Activities({ activities, hasBackground }:ActivitiesType):JSX.Element {
  if (activities.length === 0) {
    return <EmptyActivities activities={activities} hasBackground={hasBackground} />;
  }
  return (
    <Table data={activities}>
      <TableBody
        fontSize={8}
        includeLeftBorder={false}
        includeBottomBorder={false}
        includeRightBorder={false}
      >
        <DataTableCell
          style={[styles.cell, styles.activitiesTable]}
          weighting={0.2}
          getContent={(r) =>
            <ActivityCellView wrap value={convertMinToHours(r.time)} hasBackground={hasBackground} />}
        />
        <DataTableCell
          style={[styles.cell, styles.activitiesTable]}
          weighting={0.2}
          getContent={(r) => (
            <ActivityCellView value={r.project.name} hasBackground={hasBackground} textAlignCell="left" />
          )}
        />
        <DataTableCell
          weighting={0.25}
          style={[styles.cell, styles.activitiesTable]}
          getContent={(r) => (
            <ActivityCellView
              wrap
              value={CATEGORIES[r.category]}
              hasBackground={hasBackground}
              textAlignCell="left"
            />
          )}
        />
        <DataTableCell
          weighting={0.35}
          style={[styles.cell, styles.borderRightNone, styles.activitiesTable]}
          getContent={(r) => (
            <ActivityCellView wrap value={r.comment} hasBackground={hasBackground} textAlignCell="left" />
          )}
        />
      </TableBody>
    </Table>
  );
}

function EmptyActivities({ activities, hasBackground }:ActivitiesType):JSX.Element {
  activities.push(
    {
      category: ' ',
      comment: ' ',
      project: {
        name: ' ',
      },
      time: ' ',
    },
  );
  return (
    <Table data={activities}>
      <TableBody
        fontSize={8}
        includeLeftBorder={false}
        includeBottomBorder={false}
        includeRightBorder={false}
      >
        <DataTableCell
          style={[styles.cell, styles.activitiesTable]}
          weighting={0.2}
          getContent={(r) => <ActivityEmptyCellView value={r.time} hasBackground={hasBackground} />}
        />
        <DataTableCell
          style={[styles.cell, styles.activitiesTable]}
          weighting={0.2}
          getContent={(r) => (
            <ActivityEmptyCellView value={r.project.name} hasBackground={hasBackground} textAlignCell="left" />
          )}
        />
        <DataTableCell
          weighting={0.25}
          style={[styles.cell, styles.activitiesTable]}
          getContent={(r) =>
            <ActivityEmptyCellView value={r.category} hasBackground={hasBackground} textAlignCell="left" />}
        />
        <DataTableCell
          weighting={0.35}
          style={[styles.cell, styles.borderRightNone, styles.activitiesTable]}
          getContent={(r) =>
            <ActivityEmptyCellView value={r.comment} hasBackground={hasBackground} textAlignCell="left" />}
        />
      </TableBody>
    </Table>
  );
}

function ActivityCellView({ value, hasBackground, textAlignCell, wrap = true }:any) {
  return (
    <View
      wrap={wrap}
      {...hasBackground ? { style: styles.viewCellBackgroundColor } : { style: styles.viewCellNormalBackground }}
    >
      <Text
        style={{
          textAlign: textAlignCell || 'right',
          fontWeight: 502, fontFamily: 'HelveticaNeueLight',
          color: '#000000',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        {value}
      </Text>
    </View>
  );
}

function ActivityEmptyCellView({ value, hasBackground, textAlignCell }:any) {
  return (
    <View {...hasBackground ? { style: styles.viewCellBackgroundColor } : { style: styles.viewCellNormalBackground }}>
      <Text
        style={{
          textAlign: textAlignCell || 'right', fontWeight: 502,
          fontFamily: 'HelveticaNeueLight',
          color: hasBackground ? '#f1f3f3' : '#ffffff',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        {value}
      </Text>
    </View>
  );
}

function TimesheetReportContent({ value }: any): JSX.Element {
  return (
    <View style={styles.content}>
      <Table data={value}>
        <TableHeader
          fontSize={9}
          includeLeftBorder={false}
          includeBottomBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          <TableCell weighting={0.1} style={[styles.firstCellHeader]}>
            Date
          </TableCell>
          <TableCell weighting={0.15} style={[styles.header, styles.paddingLeftCell]}>
            Daily Hours
          </TableCell>
          <TableCell weighting={0.15} style={[styles.header, styles.paddingLeftCell]}>
            Total Hours
          </TableCell>
          <TableCell weighting={0.15} style={[styles.header, styles.paddingLeftCell]}>
            Project
          </TableCell>
          <TableCell weighting={0.2} style={[styles.header, styles.paddingLeftCell]}>
            Category
          </TableCell>
          <TableCell weighting={0.25} style={[styles.header, styles.lastCellHeader]}>
            Notes
          </TableCell>
        </TableHeader>
        <TableBody
          fontSize={8}
          includeLeftBorder={false}
          includeBottomBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          <DataTableCell
            weighting={0.1}
            style={[styles.cell, styles.activitiesTable]}
            getContent={(r) =>
              (
                /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
                <ActivityCellView
                  value={dayjs(r._id.weekDay).format('ddd MM/DD')}
                  hasBackground={r.hasBackground}
                  textAlignCell="left"
                />
              )}
          />
          <DataTableCell
            weighting={0.15}
            style={[styles.cell, styles.activitiesTable]}
            getContent={(r) =>
              (r.activities.length === 0 ? <ActivityEmptyCellView value=" " hasBackground={r.hasBackground} />
                : <ActivityCellView value={convertMinToHours(r.workedTimeDay)} hasBackground={r.hasBackground} />)}
          />
          <DataTableCell
            weighting={0.75}
            style={styles.borderRightNone}
            getContent={(r) => <Activities activities={r.activities} hasBackground={r.hasBackground} />}
          />
        </TableBody>
      </Table>
    </View>
  );
}

export default TimesheetReportContent;
