import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

import { useUser } from 'hooks/useUser';

interface Props {
  actions: Array<any>,
  direction?: any,
  icon?: any,
  className?: any,
}

export default function UserActions({ direction, icon, actions, className }: Props) {
  if (!actions.length) {
    return <></>;
  }

  return (
    <Dropdown
      className={className}
      direction={direction}
      icon={<Icon name={icon} />}
    >
      <Dropdown.Menu>
        {actions.map((action) => (
          <Dropdown.Item key={action.key} {...action} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

UserActions.defaultProps = {
  icon: 'ellipsis vertical',
  direction: 'left',
  className: '',
};
