import React from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import CardDetails from './components/CardDetails';

import activeStatus from 'assets/images/activeStatus.svg';
import inactiveStatus from 'assets/images/inactiveStatus.svg';
import activeMarker from 'assets/images/activeMarker.svg';
import inactiveMarker from 'assets/images/inactiveMarker.svg';
import { BERMUDA_TRIANGULE_COORDINATES, EVENT_TYPE } from 'utils/constants';

import { UsersEventWithAction } from 'types';

import './MarkerPoint.scss';

const active = L.icon({
  iconUrl: activeStatus,
  iconSize: [50, 50],
  iconAnchor: [30, 30],
});

const inactive = L.icon({
  iconUrl: inactiveStatus,
  iconSize: [50, 50],
  iconAnchor: [30, 30],
});

const activeAttendance = (label: number) =>
  new L.DivIcon({
    html: `<img class="activeAttendance" src="${activeMarker}"/><span class="activeAttendanceSpan">${label}</span>`,
  });

const inactiveAttendance = (label: number) =>
  new L.DivIcon({
    html: `<img class="inactiveAttendance" src="${inactiveMarker}"/>
    <span class="inactiveAttendanceSpan">${label}</span>`,
  });

const invertCoordinates = (coordinates: any): [number, number] => {
  const coordinatesClone: any = [...coordinates];
  return coordinatesClone.reverse();
};

const MarkerPoint = (props: UsersEventWithAction) => {
  const { location, type, onOpen, markerType = '', label = 0, createdAt } = props;
  const coordinates = location ? location.coordinates : BERMUDA_TRIANGULE_COORDINATES;
  const markerLabel = label + 1;

  const getIconType = () => {
    switch (markerType) {
      case 'attendance':
        return type === EVENT_TYPE.CHECK_IN ? activeAttendance(markerLabel) : inactiveAttendance(markerLabel);
      default:
        return type === EVENT_TYPE.CHECK_IN ? active : inactive;
    }
  };

  return (
    <Marker position={invertCoordinates(coordinates)} icon={getIconType()}>
      <Popup closeButton={false} className="cardDetail">
        {markerType ? (
          <CardDetails onOpen={onOpen} type={type} createdAt={createdAt} />
        ) : (
          <CardDetails onOpen={onOpen} type={type} />
        )}
      </Popup>
    </Marker>
  );
};

export default MarkerPoint;
