export type Action = {
  type: string,
  payload: any,
};

export type ActionCreator = (type: string, payload: any) => Action;

export default function ActionCreatorFactory(namespace: string): ActionCreator {
  return (type: string, payload: any) => ({
    type: `@${namespace}/${type}`,
    payload,
  });
}
