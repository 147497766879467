/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import _ from 'lodash';

import { UserAttendanceRequest } from '../types';

import { BaseService } from './BaseService';
import { RequestOptions } from './RequestOptions';

export class AttendancesService extends BaseService {
  constructor() {
    super('/api/v1/attendances');
  }

  async getAttendances(requestParameters: UserAttendanceRequest): Promise<RequestOptions> {
    let params = {};
    const {
      queryOptions, startDate, endDate, searchTerm, selectedGroup, selectedGroupUser, projectId,
    } = requestParameters;
    const tz = encodeURIComponent(dayjs().format('Z'));
    const queryProjectId = projectId !== 'all' ? projectId : '';
    if (_.isEmpty(selectedGroupUser)) {
      params = (searchTerm) ? { search: searchTerm } : {};
    }
    params = (selectedGroup?._id) ? { ...params, groupId: selectedGroup._id } : { ...params };
    const numParams = Object.values(params).length;
    const selectedUsersIds = selectedGroupUser.map((user: any) => user._id);
    const querySelectedUsers = (selectedGroupUser && selectedGroupUser.length > 0)
      ? `filters[]=_id $in ${selectedUsersIds}&` : '';
    const allParamsQryString = (numParams) ? `queryParams=${JSON.stringify(params)}&${querySelectedUsers}` : '';
    // eslint-disable-next-line max-len
    const urlString = `${startDate.toISOString()}/${endDate.toISOString()}?${allParamsQryString}&tz=${tz}&projectId=${queryProjectId}&`;

    return this.options({ url: this.urlFor(queryOptions, this.path, urlString) });
  }
}
