import React, { useState } from 'react';
import { noop } from 'lodash';
import { Button } from 'semantic-ui-react';

import UserItem from '../UserItem';

import styles from './UserList.module.scss';

import EmptyContent from 'components/EmptyContent';
import { UserModal } from 'components/UserModal';
import Loader from 'components/Loader';
import useLoadItems from 'hooks/useLoadItems';

interface Props {
  users?: any[];
  selectedUser?: any;
  onSelect: any;
  loadMore: any;
  request: any;
  pagination: any;
  actions: any;
}

function UserList({ users, selectedUser, onSelect, loadMore, request, pagination, actions }: Props) {
  const [userModal, setUserModal] = useState({ open: false, user: {}, title: '', content: '', onComplete: noop });
  const { isLoading } = useLoadItems(request, pagination.page, pagination.query, pagination.filters);

  const emptyIndicator = isLoading ? 1 : users?.length;

  function defineActions(user: any) {
    return actions().map((action: any) => {
      const { modalTitle, modalContent, onComplete, ...rest } = action;
      return ({
        ...rest,
        onClick: () => setUserModal({ open: true, user, title: modalTitle, content: modalContent, onComplete })
      });
    });
  }

  return (
    <EmptyContent message="There were no users found." length={emptyIndicator}>
      <UserModal
        opened={userModal.open}
        user={userModal.user}
        title={userModal.title}
        content={userModal.content}
        onComplete={userModal.onComplete}
        onClose={() => setUserModal({ open: false, user: {}, title: '', content: '', onComplete: noop })}
      />
      <div className={styles.userListCmpt}>
        {users?.map((user: any) => (
          <UserItem
            {...user}
            onClick={() => onSelect(user)}
            selected={user._id === selectedUser._id}
            key={user._id}
            actions={defineActions(user)}
            sideBar
          />
        ))}
        {!isLoading && pagination.hasMore && (
          <div className="loadMore">
            <Button basic onClick={loadMore}>LOAD MORE</Button>
          </div>
        )}
        {isLoading && (
          <div className={users?.length ? styles.loader : styles.emptyLoader}>
            <Loader active inline="centered" size={users?.length ? 'small' : 'medium'} />
          </div>
        )}
      </div>
    </EmptyContent>
  );
}

UserList.defaultProps = {
  users: [],
  selectedUser: {},
  actions: noop,
};

export default UserList;
