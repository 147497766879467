import emitter from 'utils/emitter';

export default function listenEvents(state: any, dispatch: any, actions: any) {
  function joinGroup(group: any) {
    dispatch(actions.JOIN_GROUP(group));
  }

  emitter.on('sidebar:selectGroup', joinGroup);

  return () => {
    emitter.off('sidebar:selectGroup', joinGroup);
  };
}
