import { QueryOptions } from '../types';

import { BaseService } from './BaseService';
import { RequestOptions } from './RequestOptions';

export interface ActivityUpdate {
  category: string,
  comment: string,
  time: number,
  date: string
}

export class ActivityService extends BaseService {
  constructor() {
    super('/api/v1/activities');
  }

  async getByUser(userId:string, queryOptions: QueryOptions = {}): Promise<any> {
    return this.getAll(queryOptions, 'users', userId);
  }

  async getByUserAndProject(userId: string, projectId: string, queryOptions: QueryOptions = {}): Promise<any> {
    return this.getAll(queryOptions, 'users', userId, 'project', projectId);
  }

  async create(userId: string, body: ActivityUpdate, globalUserId: string, queryOptions: QueryOptions = {}): Promise<RequestOptions> {
    if (userId !== globalUserId) {
      const path = `${this.path}/users`;
      return this.options({ method: 'POST', url: this.urlFor(queryOptions, path, userId), data: body as any });
    } else {
      return this.options({ method: 'POST', url: this.urlFor(queryOptions, this.path), data: body as any });
    }
  }

  async updateAll(id: string, body: ActivityUpdate, userId: string, globalUserId: string, queryOptions: QueryOptions = {}) {
    if (userId !== globalUserId) {
      const path = `${this.path}/${id}/users`;
      return this.options({ method: 'PUT', url: this.urlFor(queryOptions, path, userId), data: body as any });
    } else {
      return this.options({ method: 'PUT', url: this.urlFor(queryOptions, this.path, id), data: body as any });
    }
  }

  async remove(id: string, userId: string, globalUserId: string, queryOptions: QueryOptions = {}): Promise<RequestOptions> {
    if (userId !== globalUserId) {
      return this.options({ url: this.urlFor(queryOptions, this.path, userId, id), method: 'DELETE' });
    } else {
      return this.options({ url: this.urlFor(queryOptions, this.path, id), method: 'DELETE' });
    }
  }
}
