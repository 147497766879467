import React, { useState } from 'react';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { Button, Dropdown } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

import style from './ExportCSV.module.scss';

import { convertMilSec } from 'utils/convertMilSec';
import { useWindowDimensions } from 'hooks/useDimensions';
import { ScreenHandler } from 'utils/screenHandler';
import { CATEGORIES, SUMMARY_HEADER } from 'utils/constants';

import { useAttendanceContext } from 'contexts';

const setHeaders = (daysPerWeek: any) => {
  const headers: any = [{ key: 'name', label: 'Name' }];
  daysPerWeek.map((day: any, index: number) =>
    headers.push({ key: `daysPerWeek[${index}]`, label: dayjs(day).format('ddd, MMMM Do') }),
  );
  headers.push({ key: 'total', label: 'TOTAL' });
  return headers;
};

const getUserWeekReports = (currentUsers: any) =>
  currentUsers.map(({ totalDays, name, total }: any) => ({
    daysPerWeek: totalDays.map((dayPerWeek: any) => convertMilSec(dayPerWeek)),
    name,
    total: convertMilSec(total),
  }));

const getUserActivityReports = (currentUsers: any) =>
  currentUsers.map(({ totalActDays, name, totalAct }: any) => ({
    daysPerWeek: totalActDays.map((dayPerWeek: any) => convertMilSec(dayPerWeek)),
    name,
    total: convertMilSec(totalAct),
  }));

type ExportCSVProps = {
  usersData: {
    currentUsers: any[],
    usersFound: any[],
    daysPerWeek: any,
    searchStatus: any,
    selectedGroup: any,
    summaries: any[],
  },
  text?: string,
  className?: string,
  as?: any,
  contentToShow: string,
};

export default function ExportCSV({ usersData, text, className, contentToShow }: ExportCSVProps) {
  const { currentUsers, usersFound, daysPerWeek, searchStatus, selectedGroup } = usersData;
  const [, attendanceRequest] = useAttendanceContext();
  const headers = setHeaders(daysPerWeek);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isView = ((tabId: string) => contentToShow === tabId);
  const [exportProps, setExportProps] = useState({
    filename: isView('attendance') ? 'reports.csv' : 'timesheet-reports.csv',
    className: style.text,
    headers,
    data: [],
  });
  const [summaryProps, setSummaryProps] = useState<any>({
    filename: 'timesheet-reports-with-comments.csv',
    className: style.text,
    headers: SUMMARY_HEADER,
    data: [],
  });
  const location = useLocation();
  const { width } = useWindowDimensions();
  const screenHandler = new ScreenHandler(width, location);
  const exportRef = React.useRef<any>(null);
  const exportWithSummaryRef = React.useRef<any>(null);

  async function loadData() {
    const { data: { attendancesList = [] } } = await attendanceRequest.exportAttendances();
    if (isView('timesheet')) {
      setOpenDropdown(false);
    }
    setExportProps({
      ...exportProps,
      headers,
      data: isView('attendance')
        ? getUserWeekReports(attendancesList)
        : getUserActivityReports(attendancesList),
    });
    exportRef.current.link.click();
  }

  const getReportWithSummary = (userSummaries: Array<any>) => {
    if (!userSummaries || !userSummaries?.length) {
      return [];
    }
    return userSummaries.map((summary: any) => ({
      name: summary.name,
      project: summary.project?.name ?? '',
      category: CATEGORIES[summary.category],
      notes: summary.comment.replace(/"/g, '""'),
      date: summary.weekDay,
      total: convertMilSec(summary.workedTimeDay),
      dailyHours: convertMilSec(summary.time),
    }));
  };

  async function loadSummaryData() {
    const { data: { summaries = [] } } = await attendanceRequest.exportAttendances();
    setOpenDropdown(false);
    setSummaryProps({
      ...summaryProps,
      data: getReportWithSummary(summaries),
    });
    exportWithSummaryRef.current.link.click();
  }

  const userWeekReports = isView('attendance')
    ? getUserWeekReports(searchStatus ? usersFound : currentUsers)
    : getUserActivityReports(searchStatus ? usersFound : currentUsers);

  const disabledButton = screenHandler.isResponsiveSize()
    ? !selectedGroup || userWeekReports.length === 0
    : userWeekReports.length === 0;

  function handleExportClick() {
    if (exportRef && exportRef.current) {
      loadData();
    }
  }

  function handleExportWithSummaryClick() {
    if (exportWithSummaryRef && exportWithSummaryRef.current) {
      loadSummaryData();
    }
  }

  return isView('attendance') ? (
    <Button className={`${className} ${style.button}`} onClick={handleExportClick} disabled={disabledButton}>
      <CSVLink ref={exportRef} {...exportProps} onClick={(event: any) => event.stopPropagation()}>
        <div
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            loadData();
          }}
          style={{ width: '100%', height: '100%' }}
          role="none"
        >
          {text}
        </div>
      </CSVLink>
    </Button>
  ) : (
    <Dropdown
      direction="left"
      text={text}
      className={`${className} ${style.button}`}
      disabled={disabledButton}
      open={openDropdown}
      onClick={() => setOpenDropdown(!openDropdown)}
    >
      <Dropdown.Menu className={style.options}>
        <Dropdown.Item onClick={handleExportClick}>
          <CSVLink
            ref={exportRef}
            {...exportProps}
            className="exportOptions"
            onClick={(event: any) => event.stopPropagation()}
          >
            <div
              onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
                loadData();
              }}
              style={{ width: '100%', height: '100%' }}
              role="none"
            >
              Summary
            </div>
          </CSVLink>
        </Dropdown.Item>
        <Dropdown.Item onClick={handleExportWithSummaryClick}>
          <CSVLink
            ref={exportWithSummaryRef}
            {...summaryProps}
            className="exportOptions"
            onClick={(event: any) => event.stopPropagation()}
          >
            <div
              onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
                loadSummaryData();
              }}
              style={{ width: '100%', height: '100%' }}
              role="none"
            >
              Summary with comments
            </div>
          </CSVLink>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ExportCSV.defaultProps = {
  text: 'Export to CSV',
  className: '',
  as: undefined,
};
