import React from 'react';

import style from './ErrorMessage.module.scss';

type ErrorMessageProps = {
  title: string,
  content: string,
};

export default function ErrorMessage({ title, content }: ErrorMessageProps): JSX.Element {
  return (
    <div className={style.container}>
      <div className="ui floating negative message">
        <div className="header">{title}</div>
        <p>{content}</p>
      </div>
    </div>
  );
}
