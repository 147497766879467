import _ from 'lodash';

import { UsersEvent } from 'types';
import { WORLD_COORDINATES, DEFAULT_EMPLOYEE_LOCATION } from 'utils/constants';

export function eventsWithOutPlace(events: Array<UsersEvent>): Array<UsersEvent> {
  if (!events) {
    return [];
  }
  const bermudaTriangleCoodinates: [number, number] = [
    DEFAULT_EMPLOYEE_LOCATION.LONGITUDE, DEFAULT_EMPLOYEE_LOCATION.LATITUDE,
  ];
  return events.map((event) => {
    if (event.place === 'Unknown') {
      if (_.isNull(event.location)) {
        event.place = 'Bermuda Triangle';
        event.location = { coordinates: bermudaTriangleCoodinates };
        return event;
      }
    }
    return event;
  });
}

export function filterEvents(events: Array<UsersEvent>, type: string): Array<UsersEvent> {
  return events.filter((event) => event.type === type);
}

export function getLocation(location: string): any {
  const coordinatesRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
  const locationMap: any = { currentCoordinates: WORLD_COORDINATES };
  if (!_.isEmpty(location) && location.match(coordinatesRegex)) {
    locationMap.currentCoordinates = [location.split(',')[0], location.split(',')[1]];
  }
  return locationMap;
}
