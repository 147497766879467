import React from 'react';
import { Page, Document, View } from '@react-pdf/renderer';

import TimesheetReportHeader from '../../molecules/TimesheetReportHeader';
import TimesheetDailyReportHeader from '../../molecules/TimesheetDailyReportHeader';
import TimesheetReportFooter from '../../molecules/TimesheetReportFooter';
import TimesheetReportContent from '../../molecules/TimesheetReportContent';
import TimesheetDailyReportContent from '../../molecules/TimesheetDailyReportContent';

import { styles } from './styles';

type TimeSheetReportProps = {
  name: string,
  email: string,
  startWeek: string,
  endWeek: string,
  weekActivities: [],
  totalActWeek: number,
}

type TimeSheetDailyReportProps = {
  name: string,
  email: string,
  date: string
  actitities: [],
  totalAct: number,
};

function addIndexActivity(weekActivities:Array<any>) {
  return weekActivities.map((activity, index) => {
    activity.index = index;
    activity.hasBackground = index % 2 !== 0;
    return activity;
  });
}

export const TimeSheetReport = (
  { name, email, startWeek, endWeek, totalActWeek, weekActivities }: TimeSheetReportProps) =>
  (
    <Document>
      <Page size="A4" style={styles.page}>
        <TimesheetReportHeader
          userName={name}
          userEmail={email}
          startWeek={startWeek}
          endWeek={endWeek}
        />
        <View style={styles.content}>
          <TimesheetReportContent value={addIndexActivity(weekActivities)} />
          <TimesheetReportFooter totalHours={totalActWeek} />
        </View>
      </Page>
    </Document>
  );

export const TimeSheetDailyReport = ({ name, email, date, totalAct, actitities }: TimeSheetDailyReportProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <TimesheetDailyReportHeader
        userName={name}
        userEmail={email}
        date={date}
      />
      <View style={styles.content}>
        <TimesheetDailyReportContent value={addIndexActivity(actitities)} />
        <TimesheetReportFooter totalHours={totalAct} />
      </View>
    </Page>
  </Document>
);
