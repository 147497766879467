import { noop } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md';

import style from './Modal.module.scss';

interface MouseEventHandler{
  (event?: React.MouseEvent): void
}

interface ModalComponent {
  title: string,
  children: any,
  opened: boolean,
  scrollable?: boolean,
  onClose?: MouseEventHandler,
  className?: string,
}

function onModalClick(event: React.MouseEvent) {
  event.stopPropagation();
}

export default function Modal({
  title, children, scrollable = true, opened = false, onClose = noop, className }: ModalComponent): JSX.Element {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef?.current) {
      modalRef.current.focus();
    }
  }, []);

  if (!opened) {
    return <></>;
  }

  function onKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      onClose();
    }
  }

  return (
    <div
      ref={modalRef}
      role="presentation"
      className={`${style.overlay} ${className}`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onMouseDown={onClose}
      onKeyDown={onKeyDown}
    >
      <div
        role="presentation"
        className={style.modal}
        style={!scrollable ? { height: 'auto' } : undefined}
        onClick={onModalClick}
        onMouseDown={onModalClick}
      >
        <button type="button" className={style.close} onClick={onClose}>
          <MdClose size={20} />
        </button>
        <div className={style.content}>
          <header className={style.header}>{title}</header>
          <div className={style.body}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  onClose: null,
  scrollable: true,
  className: '',
};
