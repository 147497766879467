import React from 'react';
import dayjs from "dayjs";
import { toast } from 'react-toastify';

import { getCurrentPosition } from "./location";
import style from '../layout/Header/Profile/Profile.module.scss';

import { SourceApp } from 'types';
import { EVENT_TYPE } from './constants';

function buildEventAction(location: any, isCheckIn: any) {
  return {
    type: isCheckIn ? EVENT_TYPE.CHECK_OUT : EVENT_TYPE.CHECK_IN,
    location,
    timezoneOffset: dayjs().format('Z'),
    sourceApp: SourceApp.WEB,
  };
}

function buildManualEventAction(actionType: any, date: any, time: any, userId: string) {
  return {
    type: actionType,
    location: null,
    dateTime: dayjs(`${date} ${time}`),
    timezoneOffset: dayjs(`${date} ${time}`).format('Z'),
    sourceApp: SourceApp.MANUAL,
    userId
  }
}

export async function buildCheckInOutEvent(isCheckIn: any) {
  const location = await getCurrentPosition();
  const event = buildEventAction(location, isCheckIn);

  return event
}

export function buildEventInformation(type: string, place: string, createdAt: string) {
  return {
    place,
    date: dayjs(createdAt).format('ddd, MMMM Do'),
    hour: dayjs(createdAt).format('h:mm'),
    format: dayjs(createdAt).format('A'),
    type: type === EVENT_TYPE.CHECK_IN ? 'IN' : 'OUT',
  };
}

function getEventMessage(event: any) {
  return () => (
    <div>
      <span className={style.headerMessage}>{event.date}</span>
      <p className={style.bodyMessage}>
        <span className={style.hourMessage}>{event.hour}</span>
        <span className={style.formatMessage}>{event.format}</span>
        <span className={`${event.type === 'IN' ? style.inMessage : style.outMessage}`}>{event.type}</span>
        <span className={style.placeMessage}>{event.place}</span>
      </p>
    </div>
  );
}

export async function checkInOut(
  isCheckIn: boolean,
  createUserEvent: any,
  socketDispatch: any,
  socketActions: any,
  globalDispatch: any,
  globalActions: any,
) {
  const event = await buildCheckInOutEvent(isCheckIn);
  const eventData = await createUserEvent(event);
  const { type, place, createdAt } = eventData;
  const eventInformation = buildEventInformation(type, place, createdAt);
  const Msg = getEventMessage(eventInformation);
  socketDispatch(socketActions.SEND_CHECK_IN_OUT(eventData));
  globalDispatch(globalActions.UPDATE_STATE({ lastUserEvent: eventData }));
  toast(<Msg />);
}

export async function checkInOutManually(
  actionType: boolean,
  date: string,
  time: string,
  userId: string,
  createManualUserEvent: any,
) {
  const event = await buildManualEventAction(actionType, date, time, userId);
  const eventData = await createManualUserEvent(event);
  const { type, place, createdAt } = eventData;
  const eventInformation = buildEventInformation(type, place, createdAt);
  const Msg = getEventMessage(eventInformation);
  toast(<Msg />);
}
