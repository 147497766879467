import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

import style from './Header.module.scss';
import Profile from './Profile';

import Icon from 'components/Icon';
import ExportCSV from 'components/ExportCSV';
import DateRange from 'components/DateRange';
import { ScreenHandler } from 'utils/screenHandler';
import { APP_CONTENT } from 'utils/constants';
import { useWindowDimensions } from 'hooks/useDimensions';

import { useAttendanceContext, useGlobalContext } from 'contexts';

export default function Header() {
  const location = useLocation();
  const [globalState, globalRequest] = useGlobalContext();
  const { contentToShow } = globalState;
  const { changeAppContent } = globalRequest;

  const [attendacenState, attendanceRequest] = useAttendanceContext();
  const {
    summaries,
    attendancesList,
    selectedGroup,
    week,
    date: { startDate, endDate },
  } = attendacenState;
  const { updateDate } = attendanceRequest;

  const { width } = useWindowDimensions();
  const screenHandler = new ScreenHandler(width, location);

  function sideBarTabProps(tabId: string) {
    const isActive = tabId === contentToShow;
    const selectedTabRadius = tabId === APP_CONTENT.HOME ? style.homeRadius : style.timesheetRaduis;
    const sizeTab = screenHandler.isResponsiveSize() ? style.tabItemSize : style.tabItem;
    const sizeSelectedTab = screenHandler.isResponsiveSize() ? style.SelectedSizeTab : style.selectedTab;
    if (tabId !== APP_CONTENT.ATTENDANCE) {
      return {
        className: isActive ? `${sizeSelectedTab} ${selectedTabRadius}` : sizeTab,
        active: !!isActive,
        onClick: () => changeAppContent(tabId),
      };
    }
    return {
      className: isActive ? sizeSelectedTab : sizeTab,
      active: !!isActive,
      onClick: () => changeAppContent(tabId),
    };
  }

  function handleChange(date: any) {
    updateDate(date);
  }

  const exportCSVProps = {
    currentUsers: attendancesList || [],
    usersFound: attendancesList || [],
    daysPerWeek: week,
    searchStatus: false,
    selectedGroup,
    summaries: summaries || [],
  };

  return (
    <div className={style.headerContent}>
      <div className={style.leftMenu}>
        <Menu className={style.tabPicker}>
          <Menu.Item {...sideBarTabProps(APP_CONTENT.HOME)}>
            <span className={style.contentTab}>
              <Icon.Map size={20} />
              <span className={screenHandler.isSmallResponsiveSize() ? style.hideMenuLabel : style.menuLabel}>Map</span>
            </span>
          </Menu.Item>
          <Menu.Item {...sideBarTabProps(APP_CONTENT.ATTENDANCE)}>
            <span className={style.contentTab}>
              <Icon.Clock size={20} />
              <span className={screenHandler.isSmallResponsiveSize() ? style.hideMenuLabel : style.menuLabel}>
                Attendance
              </span>
            </span>
          </Menu.Item>
          <Menu.Item {...sideBarTabProps(APP_CONTENT.TIMESHEET)}>
            <span className={style.contentTab}>
              <Icon.Report size={20} />
              <span className={screenHandler.isSmallResponsiveSize() ? style.hideMenuLabel : style.menuLabel}>
                Timesheet
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
      <Menu position="right" secondary className={style.rigthMenu}>
        {contentToShow !== APP_CONTENT.HOME && (
          <>
            <Menu.Item className={style.menuItem}>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                onChange={handleChange}
                Component={Menu.Item}
              />
            </Menu.Item>
            <Menu.Item className={style.menuItem}>
              {exportCSVProps && exportCSVProps.currentUsers && (
                <ExportCSV usersData={exportCSVProps} as={Menu.Item} contentToShow={contentToShow} />
              )}
            </Menu.Item>
          </>
        )}
        <Profile />
      </Menu>
    </div>
  );
}
