import { noop } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import style from './UserModal.module.scss';

import Modal from 'components/Modal';

type UserModalProps = {
  opened: boolean;
  user: any;
  title: string;
  content: string;
  onClose?: any;
  onComplete?: any;
};

export function UserModal({ opened, user, title, content, onClose = noop, onComplete = noop }: UserModalProps): JSX.Element {
  const [loading, setLoading] = useState(false);

  const handleOnConfirm = async () => {
    try {
      setLoading(true);
      await onComplete(user._id);
      setLoading(false);
      onClose();
    } catch (err) {
      toast.error('There was an issue executing the action');
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title={title} scrollable={false}>
      <div>{content}</div>
      <div className={style.buttonContainer}>
        <Button loading={loading} disabled={loading} as="div" className={style.button} onClick={handleOnConfirm}>
          Yes
        </Button>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

UserModal.defaultProps = {
  onClose: noop,
  onComplete: noop,
};
