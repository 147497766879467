import _ from 'lodash';

import { GROUP_TYPE } from 'utils/constants';
import { isId, isEmail } from 'utils/validations';

const mapMembersIds = (members?: Array<any>) => members?.map((member: any) => member._id) ?? [];

const mapDefaultValues = (members?: Array<any>, emails?: Array<string>) => mapMembersIds(members).concat(emails ?? []);

const mapUserIds = (users: Array<any>) => users.map((user: any) => user._id);

const mapProjectIds = (projects: Array<any>) => projects.map((project: any) => project._id);

function getGroupName(group: any) {
  return group?.name ?? '';
}

function getGroupOwners(group: any, user: any) {
  return mapUserIds(group?.owners ?? [user]);
}

function getGroupSharedWith(group: any) {
  return mapUserIds(group?.sharedWithUsers ?? []);
}

function getGroupMembers(group: any) {
  return mapDefaultValues(group?.members, group?.pendingUsers);
}

function getGroupMemberIds(group: any) {
  return mapMembersIds(group?.members);
}

function getGroupProjects(group: any) {
  return mapProjectIds(group?.projects ?? []);
}

function buildGroupData(group: any, user: any) {
  return {
    groupName: getGroupName(group),
    groupOwners: getGroupOwners(group, user),
    groupSharedWith: getGroupSharedWith(group),
    groupMembers: getGroupMembers(group),
    groupMembersIds: getGroupMemberIds(group),
    groupProjects: getGroupProjects(group),
  };
}

function buildGroupToSave(group: any, user: any, variableAttributes?: any): object {
  const { groupName, groupSharedWith, groupMembers, groupProjects, groupOwners } =
    variableAttributes || buildGroupData(group, user);
  const type = group?.type ?? GROUP_TYPE.CUSTOM;
  let newType = type;
  if (type !== GROUP_TYPE.PUBLIC) {
    newType = groupSharedWith && groupSharedWith.length ? GROUP_TYPE.CUSTOM : GROUP_TYPE.PRIVATE;
  }

  const pendingUsers = _.filter(groupMembers, (item: any) => isEmail(item));
  const onlyProjects = _.filter(groupProjects, (project: string) => isId(project));
  const addProjects = _.filter(groupProjects, (project: string) => !isId(project));

  return {
    type: newType,
    name: groupName,
    pendingUsers,
    sharedWith: groupSharedWith,
    projects: onlyProjects,
    addProjects,
    ownerIds: groupOwners,
  };
}

function buildSavedGroup(response: any): object {
  const { data } = response.data;

  if (!data) {
    return {};
  }

  return {
    name: data.name,
    ownerIds: data.ownerIds,
    projects: data.projects,
    type: data.type,
    sharedWith: data.sharedWith,
    _id: data._id,
    ownerNames: data.ownerNames,
  };
}

export { buildGroupData, buildGroupToSave, buildSavedGroup };
