import React, { ReactNode } from 'react';

export type IconProps = {
  size?: number,
  className?: string,
  children?: ReactNode,
  onClick?: () => void,
}

export default function Icon(props: IconProps): JSX.Element {
  const { size, className = '', children = null, onClick } = props;

  return (
    <svg
      {... onClick ? { onClick } : {}}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {children}
    </svg>
  );
}
