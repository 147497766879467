import React, { useCallback, useState, useEffect } from 'react';
import _, { debounce } from 'lodash';
import { Form, Icon, List, Button } from 'semantic-ui-react';

import UserItem from '../UserItem';

import { getUsers } from '../../helper';

import style from './style.module.scss';

import { User } from 'types';

interface Props {
  onActionClick: any,
  actionName: string,
  label: string,
  placeholder: string,
  filter: any,
}

const USER_LIMIT = 10;

const INITIAL_DATA = {
  hasMore: false,
  page: 0,
  users: [],
  totalRows: 0,
};

export default function UserItemList({
  onActionClick,
  actionName,
  label,
  placeholder,
  filter,
}: Props) {
  const [state, setState] = useState<any>(INITIAL_DATA);
  const [query, setQuery] = useState('');
  const hasLoadMore = state.hasMore;

  const loadUsers = useCallback(async (currentPage: number) => {
    const queryOptions = { query, limit: USER_LIMIT, skip: currentPage, filter };
    let result = await getUsers(queryOptions);
    if (result.users.length === 0 && currentPage > 1) {
      const _queryOptions = { query, limit: USER_LIMIT, skip: currentPage - 1, filter };
      result = await getUsers(_queryOptions);
    }
    const { users, next, page, totalRows } = result;
    setState({ hasMore: next, page, users, totalRows });
  }, [filter, query]);

  useEffect(() => {
    loadUsers(state.page);
  }, [filter, query]);

  const onNextPage = () => {
    loadUsers(state.page + 1);
  };

  const onPrevPage = () => {
    loadUsers(state.page - 1);
  };

  const debouncedSearch = debounce((criteria) => {
    setState({ ...state, page: 1 });
    setQuery(criteria);
  }, 800);

  return (
    <>
      <section className="menu-body">
        <div className="search">
          <Form.Input
            label={label}
            size="mini"
            iconPosition="left"
            icon="search"
            placeholder={placeholder}
            onChange={(event) => debouncedSearch(event.target.value)}
          />
        </div>
      </section>
      {state.users.length ? (
        <List divided verticalAlign="middle" className={style.userList}>
          {_.map(state.users, (user: User) => (
            <UserItem
              email={user.email}
              picture={user.picture}
              name={user.preferredUsername}
              key={user._id}
              onActionClick={() => onActionClick(user)}
              actionName={actionName}
            />
          ))}
        </List>
      ) : (
        <section className={style.emptyList}>
          {state.users.length ? (
            <section>
              <Icon name="search" />
              No matching users found
            </section>
          ) : (
            'Empty user list'
          )}
        </section>
      )}
      {state.totalRows > USER_LIMIT
      && (
        <section className={style.pagination}>
          <Button disabled={state.page === 1} basic onClick={onPrevPage}>PREV</Button>
          <Button disabled={!hasLoadMore} basic onClick={onNextPage}>NEXT</Button>
        </section>
      )}
    </>
  );
}
