import { useState, useEffect, useCallback } from 'react';

function useLoadItems(callbackRequest: any, skip: any, query: string, filters?: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const request = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(false);
      await callbackRequest();
      setIsLoading(false);
    } catch (e) {
      setError(true);
    }
    // eslint-disable-next-line
  }, [skip, query, filters?.status]);

  useEffect(() => {
    request();
  }, [request]);

  return { isLoading, error };
}

export default useLoadItems;
