import _ from 'lodash';

import { UserService, SkypeGroupService, WebhookService, WebhookGroupService } from '../../service';
import { SkypeGroup, WebhookGroup } from '../../types';

const userService = new UserService();
const skypeGroupService = new SkypeGroupService();
const webhookGroupService = new WebhookGroupService();
const webhooksService = new WebhookService();

async function getRequest(callback: any) {
  const request = await (_.isFunction(callback) ? callback() : callback);
  return await request.toAxios();
}

export async function getUsers(queryOptions: any) {
  const { limit = 300, query = '', skip = 1, filter = {} } = queryOptions;
  const { data: { data: users } } = await getRequest( userService.search(query, filter, { limit, skip }));
  return users;
}

export async function getSkypeGroups() {
  const { data: { data: skypeGroups } }= await getRequest(() => skypeGroupService.getSkypeGroups());
  return skypeGroups;
}

export async function handleSave({ _id, users, name }: SkypeGroup, skypeGroups: any) {
  const newGroup = { from: users, to: [_id], name};
  await getRequest(() => skypeGroupService.createSkypeGroup(newGroup));
  return [newGroup, ...skypeGroups];
}

export async function handleUpdate(groupId: string, { _id, users, name }: SkypeGroup, skypeGroups: any) {
  const updateGroup = { from: users, to: [_id], name};
  const filtered = _.filter(skypeGroups, (value) => value.to[0].toLowerCase() !== groupId.toLowerCase())
  await getRequest(() => skypeGroupService.updateSkypeGroup(groupId, updateGroup));
  return [updateGroup, ...filtered];
}

export async function handleRemove(skypeId: string, skypeGroups: any) {
  await getRequest(() => skypeGroupService.deleteSkypeGroup(skypeId));
   return _.filter(skypeGroups, (value) => value.to[0].toLowerCase() !== skypeId.toLowerCase());
}

export function formattGroup(skypeGroups: any) {
  let groups: any[] = [];
  if (!!skypeGroups && skypeGroups.length) {
    groups = _.map(skypeGroups, ({ name, to, type, from: users, settings }: any) => {
      const [defaultId] = to;
      return {
        name: name || 'Nameless',
        _id: defaultId,
        users,
        type,
        weeklyTimesheet: settings?.notifications?.weeklyTimesheet,
        weeklyCheckInOut: settings?.notifications?.weeklyCheckInOut,
        newGroup:false
      };
    });
  }
  return groups;
}

export async function getEnabledHooks() {
  const queryOptions = { filters:['enabled $eq true'] };
  const { data: { data: enabledHooks } }= await getRequest(() => webhooksService.getEnabledHooks(queryOptions));
  return enabledHooks;
}

export async function getGroupsByWebhook(webhook: string) {
  const { data: { data: webhookGroups } } = await getRequest(() => webhooksService.getGroupsByWebhook(webhook));
  return webhookGroups;
}

export function getNotifications(weeklyCheckInOut: any, weeklyTimesheet: any) {
  const notifications = { weeklyTimesheet, weeklyCheckInOut }
  if (weeklyTimesheet && (weeklyTimesheet.day === '' || weeklyTimesheet.time === '')) {
    delete notifications['weeklyTimesheet'];
  }
  if (weeklyCheckInOut && (weeklyCheckInOut.day === '' || weeklyCheckInOut.time === '')) {
    delete notifications['weeklyCheckInOut'];
  }
  return notifications;
}

export async function handleUpdateGroup(webHookType: string, groupId: string,
    { _id, users, name, type, weeklyTimesheet, weeklyCheckInOut }: WebhookGroup, skypeGroups: any) {

  const notifications = getNotifications(weeklyCheckInOut, weeklyTimesheet);
  const updateGroup = { from: users, to: [_id], name, settings: { notifications } };
  const filtered = _.filter(skypeGroups, (value) => value.to[0].toLowerCase() !== groupId.toLowerCase());
  await getRequest(() => webhookGroupService.updateWebhookGroupByType(webHookType, type, groupId, updateGroup));
  return [{ ...updateGroup, type }, ...filtered];
}

export async function handleSaveGroup(webHookType: string,
    { _id, users, name, type, weeklyTimesheet, weeklyCheckInOut }: WebhookGroup, groups: any) {

  const notifications = getNotifications(weeklyTimesheet, weeklyCheckInOut);
  const newGroup = { from: users, to: [_id], name, settings: { notifications } };
  await getRequest(() => webhookGroupService.createWebhookGroupByType(webHookType, type, newGroup));
  return [{ ...newGroup, type }, ...groups];
}

export async function handleRemoveGroup(webHookType: string, groupType: string, groupId: string, groups: any) {
  await getRequest(() => webhookGroupService.deleteWebhookGroupByType(webHookType, groupType, groupId));
   return _.filter(groups, (value) => value.to[0].toLowerCase() !== groupId.toLowerCase());
}
