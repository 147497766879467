export class ScreenHandler {
  width: number;
  location: any;

  constructor(width: number, location: any) {
    this.width = width;
    this.location = location;
  }

  isHome() {
    return this.location.pathname.match(/home/g);
  }

  isReport() {
    return this.location.pathname.match(/reports/g);
  }

  isTimeSheet() {
    return this.location.pathname.match(/timesheet/g);
  }

  isResponsiveSize() {
    return this.width <= 768;
  }

  isSmallResponsiveSize() {
    return this.width <= 1360;
  }

  isMediumResponsiveSize() {
    return this.width <= 1218;
  }

  isResponsiveReport() {
    return this.isReport() && this.isResponsiveSize();
  }

  isResponsiveHome() {
    return this.isHome() && this.isResponsiveSize();
  }

  isResponsiveTimesheet() {
    return this.isTimeSheet() && this.isResponsiveSize();
  }
}
