import ActionCreatorFactory from '../../utils/actionCreator';

const actionCreator = ActionCreatorFactory('SOCKET');

export default {
  UPDATE_STATE: (properties?: any) => actionCreator('UPDATE_STATE', properties),
  INIT_SOCKET: (socket?: any) => actionCreator('INIT_SOCKET', socket),
  JOIN_GROUP: (group?: any) => actionCreator('JOIN_GROUP', group),
  SET_ERROR: (error?: any) => actionCreator('SET_ERROR', error),
  SEND_CHECK_IN_OUT: (eventInfo?: any) => actionCreator('SEND_CHECK_IN_OUT', eventInfo),
};
