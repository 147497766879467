import React from 'react';

import MarkerPoint from './MarkerPoint';

import { UsersEventWithAction } from 'types';

export default function MarkersList({
  userEvents,
  onOpen,
  markerType = '',
}: {
  userEvents: Array<UsersEventWithAction>;
  onOpen: any;
  markerType?: string;
}): JSX.Element {
  const items = userEvents.map(({ _id, ...props }: UsersEventWithAction, index) => (
    <MarkerPoint
      key={_id}
      {...props}
      onOpen={() => onOpen(props.userId ? props.userId : _id)}
      markerType={markerType}
      label={index}
    />
  ));
  return <>{items}</>;
}

MarkersList.defaultProps = {
  markerType: '',
};
