import React from 'react';
import { Pagination, Icon, Grid, Dropdown } from 'semantic-ui-react';

import style from './Pagination.module.scss';

interface Props {
  activePage: number;
  onChangePage: any;
  totalPages: number;
  paginationSetup: Array<any>;
  setOptionValue: any;
  limit: number;
  boundaryRange?: number;
  siblingRange?: number;
  ellipsisItem?: any;
  className?: string;
}

function AttendancePagination({
  activePage,
  onChangePage,
  totalPages,
  limit,
  setOptionValue,
  paginationSetup,
  boundaryRange,
  siblingRange,
  ellipsisItem,
  className,
}: Props): JSX.Element {
  const dynamicPaginationProps = {
    className: `${style.dinamicResults}`,
    placeholder: limit.toString(),
    compact: true,
    selection: true,
    defaultValue: limit,
  };

  return (
    <div className={`${style.paginationCmpt} ${className}`}>
      <Grid.Column className={style.gridResults}>
        <Dropdown {...dynamicPaginationProps} onChange={setOptionValue} options={paginationSetup} />
      </Grid.Column>
      <Pagination
        activePage={activePage}
        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={totalPages}
        onPageChange={onChangePage}
        className={style.navigator}
        boundaryRange={boundaryRange}
        siblingRange={siblingRange}
        ellipsisItem={ellipsisItem === null ? null : {
          content: '...',
          icon: false,
          onClick: (event: any, pageInfo: any) => onChangePage(event, { ...pageInfo, activePage: pageInfo.value })
        }}
      />
    </div>
  );
}

AttendancePagination.defaultProps = {
  boundaryRange: 1,
  siblingRange: 1,
  className: '',
};

export default AttendancePagination;
