import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid } from 'semantic-ui-react';

import NotificationForm from '../NotificationForm';
import GroupMenu from '../../molecules/GroupMenu';
import GroupItemList from '../../molecules/GroupItemList';
import WebhookSelector from '../../molecules/WebhookSelector';

import { SkypeGroup } from 'types';

import './style.scss';

interface Props {
  onRemove: any,
  onSave: any,
  onUpdate: any,
  groups: any[],
  webhooks: any[],
  onSelectHook: any,
}

export default function GroupWorkspace({ onRemove, groups, onSave, onUpdate, webhooks, onSelectHook }: Props) {
  const initialState = { name: '', _id: '', users: undefined, newGroup: true };
  const [query, setQuery] = useState<string>('');
  const [selected, setSelected] = useState<any>(initialState);

  function getGroups() {
    let skypeGroups: SkypeGroup[] = [];
    if (Boolean(groups) && groups.length) {
      skypeGroups = _.filter(groups, (value) => value.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return skypeGroups;
  }

  async function handleRemove(groupId: string, groupType: string) {
    await onRemove(groupId, groupType);
    const [toSelect] = groups;
    let newSelected = initialState;
    if (toSelect) {
      newSelected = {
        name: toSelect.name,
        _id: toSelect._id,
        users: toSelect.users,
        newGroup: false,
      };
    }
    setSelected(newSelected);
  }

  useEffect(() => {
    if (!!groups && groups.length) {
      const [{ name, _id, users: newUsers, type, weeklyTimesheet, weeklyCheckInOut }] = groups;
      setSelected({ name, _id, users: newUsers, type, weeklyTimesheet, weeklyCheckInOut });
    }
    if (!groups.length) {
      setSelected(initialState);
    }
  }, [setSelected, groups]); // eslint-disable-line

  return (
    <Grid className="workspaceGrid">
      <Grid.Row>
        <Grid.Column width={4}>
          <GroupMenu
            onSearch={(newQuery: string) => setQuery(newQuery)}
            onCreate={(e: any, data: any) => setSelected({ ...initialState, type: data.value })}
            webhookSelector={<WebhookSelector hooks={webhooks} onChange={onSelectHook} />}
          >
            <GroupItemList
              groups={getGroups()}
              onSelect={(group: any) => setSelected(group)}
              selected={selected._id}
              onRemove={handleRemove}
              emptyMessage="No group records found."
            />
          </GroupMenu>
        </Grid.Column>
        <Grid.Column width={12}>
          <NotificationForm onSave={onSave} onUpdate={onUpdate} group={selected} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
