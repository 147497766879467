import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';

import style from './Map.module.scss';

import CustomMap from 'components/CustomMap';
import { useAttendanceContext } from 'contexts';
import { useDates } from 'hooks/useDates';
import MarkersError from 'components/Marker/MarkersError';
import { eventsWithOutPlace } from 'utils/eventHandler';

export default function Map(): JSX.Element {
  const [attendanceState, attendanceRequest] = useAttendanceContext();
  const {
    getLastUserEventById, getLastUserEvents,
  } = attendanceRequest;
  const {
    selectedGroup, selectedGroupUser, lastUsersEvents, error,
  } = attendanceState;

  const { location, zoom } = useParams<any>();
  const { startWeek } = useDates();

  async function onOpenMarker(userId: string) {
    return getLastUserEventById(userId);
  }

  const load = useCallback(async () => {
    const serviceArguments = {
      queryOptions: {
        fields: ['type', 'location', 'userId', 'place'],
      },
      selectedGroup,
      startWeek,
      selectedUsers: selectedGroupUser,
    };
    const result = await getLastUserEvents(serviceArguments);
    if (!result.length && selectedGroupUser.length) {
      toast(
        <span>
          <Icon name="map marker alternate" /> Last location unknown
        </span>,
      );
    }
  }, [getLastUserEvents, selectedGroup, selectedGroupUser, startWeek]);

  useEffect(() => {
    load();
  }, [selectedGroup, selectedGroupUser]); // eslint-disable-line

  return (
    <div className={style.container}>
      {error && <MarkersError />}
      {
        lastUsersEvents && (
          <CustomMap
            events={eventsWithOutPlace(lastUsersEvents)}
            location={location}
            zoomParam={zoom}
            onOpen={onOpenMarker}
          />
        )
      }
    </div>
  );
}
