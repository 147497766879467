export default {
  add(collection: Array<any>, item: any) {
    const newCollection = [...collection, item];
    return newCollection;
  },
  remove <T>(collection: Array<T>, comparer: any) {
    if (!collection.length) {
      return [null, collection];
    }
    const newCollection = [...collection];
    const index = newCollection.findIndex(comparer);
    if (index >= 0) {
      const itemDeleted = newCollection[index];
      newCollection.splice(index, 1);
      return [itemDeleted, newCollection];
    }
    return newCollection;
  },
  update <T>(collection: Array<T>, comparer: any, newProperties: any) {
    const newCollection: Array<T> = [...collection];
    const item = newCollection.find(comparer);
    if (!item) {
      return [null, collection];
    }
    Object.assign(item, { ...newProperties });
    return [item, newCollection];
  },
  chunkArray <T>(array: Array<T>, chunkSize: number) {
    const results = [];
    const copyWeek = [...array];
    while (copyWeek.length) {
      results.push(copyWeek.splice(0, chunkSize));
    }
    return results;
  },
  orderBy <T>(array: Array<T>, field: string) {
    const arrayCopied = JSON.parse(JSON.stringify(array));
    return arrayCopied.sort((item: any, toCompare: any) => {
      if (item[field] > toCompare[field]) {
        return 1;
      }
      if (item[field] < toCompare[field]) {
        return -1;
      }
      return 0;
    });
  },
  addIntoStart <T>(array: Array<T>, field = 'name', comparer: any = 'JALASOFT') {
    const jalasoftRow = array.find((item: any) => item[field]?.toUpperCase() === comparer && !item.isFavorite);
    let favorites = array.filter(((item: any) => item.isFavorite));
    let newGroups = array.filter(((item: any) => !item.isFavorite && item[field].toUpperCase() !== comparer));
    if (!jalasoftRow) {
      return [...favorites, ...newGroups];
    }
    return [...favorites, jalasoftRow, ...newGroups];
  },
};
