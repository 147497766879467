import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import _, { debounce } from 'lodash';
import { toast } from 'react-toastify';

import GroupList from './Group/GroupList';
import style from './sidebar.module.scss';
import UserList from './Users/UserList';
import Header from './Header';
import { filterUsers } from './helper';

import { useSidebarContext, useGlobalContext } from 'contexts';
import { useUser } from 'hooks/useUser';

import { FILTER_USERS_PICK, GROUP_TYPE, SIDEBAR_CONTENT, USER_ZOOM } from 'utils/constants';
import logoPT from 'assets/images/logoPT.png';
import { UserService, GroupService } from 'service';
import { buildGroupToSave, buildSavedGroup } from 'utils/groupHandler';

const userService = new UserService();
const groupService = new GroupService();

function Sidebar() {
  const history = useHistory();
  const [state, request, actions, dispatch] = useSidebarContext();
  const [globalState] = useGlobalContext();
  const { user } = globalState;
  const {
    getUserFavoriteGroups,
    getUserCustomGroups,
    getUserSystemGroups,
    getGroupUsers,
    selectPersonalGroup,
    selectGroup,
    selectUser,
    addGroup,
    cloneGroup,
    updateGroup,
    removeGroup,
    disableUser,
    updateCustomGroupPagination,
    updateSystemGroupPagination,
    updateGroupsToShow,
    updateUsersPagination,
    updateUsersFilter,
    updateSearchPaginationGroups,
    updateSearchPaginationUsers,
  } = request;
  const [sidebarState, setSidebarState] = useState({ content: SIDEBAR_CONTENT.GROUP, loading: true });
  const [isSearcing, setIsSearcing] = useState<boolean>(false);
  const componentMounted = useRef(true);

  const isGroupsContent = sidebarState.content === SIDEBAR_CONTENT.GROUP;
  const styleContent = isGroupsContent ? style.content : style.withoutLogo;
  const {
    favoriteGroups,
    customGroups,
    systemGroups,
    searchQuery,
    customGroupPagination,
    systemGroupPagination,
    usersPagination,
    usersByStatus,
    groupsToShow
  } = state;
  const { available, noAvailable, all } = usersByStatus;
  const { currentUserIsAdmin, isGroupOwner, currentUser } = useUser();

  async function loadFavoriteGroups() {
    await getUserFavoriteGroups();
  }

  async function loadSystemGroups() {
    await getUserSystemGroups();
  }

  async function loadCustomGroups() {
    await getUserCustomGroups();
  }

  async function loadGroupUsers() {
    if (state.selectedGroup._id && !isGroupsContent) {
      await getGroupUsers(state.selectedGroup);
    }
  }

  function loadMoreGroupUsers() {
    updateUsersPagination({ page: usersPagination.page + 1 });
  }

  function cleanGroupSearch() {
    if (state.searchQuery) {
      updateSearchPaginationGroups({ query: '' });
    }
  }

  function cleanUserSearch() {
    if (state.searchQuery) {
      updateSearchPaginationUsers({ query: '' });
    }
  }

  async function onSelectGroup(group: any) {
    if (_.isEmpty(group)) {
      selectPersonalGroup();
    } else {
      setSidebarState({ ...sidebarState, loading: true });
      selectGroup(group);
      handleOnCancelSearch();
      setIsSearcing(false);
      setSidebarState({ loading: false, content: SIDEBAR_CONTENT.USERS });
    }
  }

  function loadMoreSystemGroups() {
    updateSystemGroupPagination({ page: systemGroupPagination.page + 1 });
  }

  function loadMoreCustomGroups() {
    updateCustomGroupPagination({ page: customGroupPagination.page + 1 });
  }

  const debouncedSearch = debounce((criteria) => {
    if (isGroupsContent) {
      updateSearchPaginationGroups({ query: criteria });
    } else {
      updateSearchPaginationUsers({ query: criteria });
    }
  }, 800);

  async function handleOnCancelSearch() {
    if (isGroupsContent) {
      cleanGroupSearch();
    } else {
      cleanUserSearch();
    }
  }

  async function onSelectUser(selectedUser: any) {
    const selected = state.selectedGroupUser && state.selectedGroupUser._id === selectedUser._id
      ? undefined : selectedUser;

    await selectUser(selected);
    if (selectedUser.location?.coordinates) {
      const { coordinates } = selectedUser.location;
      history.replace(`/home/${[...coordinates].reverse().join(',')}/${USER_ZOOM}`);
    } else {
      history.replace('/home');
    }
  }

  async function onChangeFavorite(group: any) {
    const { isFavorite } = group;
    const newIsFavorite = !isFavorite;
    try {
      let userRequest;
      if (newIsFavorite) {
        userRequest = await userService.addFavoriteGroup(group._id, user._id);
      } else {
        userRequest = await userService.removeFavoriteGroup(group._id, user._id);
      }
      if (userRequest) {
        await userRequest.toAxios();
        dispatch(actions.CHANGE_FAVORITE(group));
        selectGroup({ ...group, isFavorite: newIsFavorite }, state.groupUsers);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  async function onShowGroups(groupsToShow: any) {
    if (groupsToShow) updateGroupsToShow(groupsToShow);
  }

  async function onBack() {
    await updateSearchPaginationGroups({ query: '' });
    await selectUser({});
    await updateUsersFilter({ status: FILTER_USERS_PICK.ALL });
    setSidebarState({ ...sidebarState, content: SIDEBAR_CONTENT.GROUP });
    history.replace('/home');
  }

  function handleFilterByStatus(userStatus: string) {
    if (userStatus !== state.usersFilters.status) {
      updateSearchPaginationUsers({
        query: state.searchQuery,
        filters: { status: userStatus },
      });
    }
  }

  function errorHandling(error: any) {
    toast.error(
      <p>
        Something went wrong: <br />
        {error.response?.data?.error?.message}
      </p>
    );
  }

  function systemGroupActions() {
    let actions: Array<any> = [];

    if (currentUserIsAdmin && state.selectedGroup.isRoot) {
      actions = [
        {
          text: 'Delete user',
          key: 'deleteUser',
          modalTitle: 'Delete User',
          modalContent: (
            <span>
              This action will delete the user from the <strong>application</strong>. Do you want to continue?
            </span>
          ),
          onComplete: (userId: any) => disableUser(userId),
        },
      ];
    }

    return actions;
  }

  function customGroupActions() {
    let actions: Array<any> = [];

    if (currentUserIsAdmin || isGroupOwner(state.selectedGroup.ownerIds)) {
      actions = [
        {
          text: 'Remove user',
          key: 'removeUser',
          modalTitle: 'Remove User',
          modalContent: (
            <span>This action will remove the user from the <strong>group</strong>. Do you want to continue?</span>
          ),
          onComplete: async (userId: any) => {
            const { selectedGroup } = state;
            const getGroupByIdRequest = await groupService.getById(selectedGroup._id);
            const groupResponse = await getGroupByIdRequest.toAxios();
            const currentGroup = groupResponse.data?.data;
            const groupToSave = buildGroupToSave(currentGroup, currentUser);
            const groupRequest = await groupService.updateGroup(selectedGroup._id, {
              ...groupToSave,
              removeMembers: [userId],
            });

            try {
              const response = await groupRequest.toAxios();
              const savedGroup = buildSavedGroup(response);

              updateGroup(savedGroup, user, !isGroupsContent);
              toast.success('User removed successfully');
            } catch (error) {
              errorHandling(error);
            }
          },
        },
      ];
    }

    return actions;
  }

  function getActions() {
    let actions: Array<any>;

    switch (state.selectedGroup.type) {
      case GROUP_TYPE.SYSTEM:
        actions = systemGroupActions();
        break;
      case GROUP_TYPE.PRIVATE:
      case GROUP_TYPE.CUSTOM:
        actions = customGroupActions();
        break;
      default:
        actions = [];
    }

    return actions;
  }

  useEffect( () => {
    (async () => {
        history.replace('/home');
        await updateSearchPaginationGroups({ query: '' });
        await selectUser({});
        await updateUsersFilter({ status: FILTER_USERS_PICK.ALL });
        if (componentMounted.current) {
          setSidebarState({ ...sidebarState, content: SIDEBAR_CONTENT.GROUP });
        }
        return () => {
            componentMounted.current = false;
        }
    })();
  }, []);

  const contents: any = {
    users: (
      <UserList
        actions={getActions}
        onSelect={onSelectUser}
        users={filterUsers(state.groupUsers, state.usersFilters.status)}
        selectedUser={state.selectedGroupUser}
        loadMore={loadMoreGroupUsers}
        request={loadGroupUsers}
        pagination={{ ...usersPagination, query: state.searchQuery, filters: state.usersFilters }}
      />
    ),
    group: (
      <GroupList
        user={user}
        onUpdate={updateGroup}
        onClone={cloneGroup}
        onChangeFavorite={onChangeFavorite}
        onRemove={removeGroup}
        onSelect={onSelectGroup}
        favoriteGroups={favoriteGroups}
        customGroups={customGroups}
        systemGroups={systemGroups}
        selectedGroup={state.selectedGroup}
        loadMoreSystemGroups={loadMoreSystemGroups}
        loadMoreCustomGroups={loadMoreCustomGroups}
        requestFavoriteGroups={loadFavoriteGroups}
        requestSystemGroups={loadSystemGroups}
        requestCustomGroups={loadCustomGroups}
        pagination={{ customGroups: customGroupPagination, systemGroups: systemGroupPagination, query: searchQuery }}
        groupsToShow={groupsToShow}
        onShowGroups={onShowGroups}
      />
    ),
  };

  return (
    <div className={style.sidebarWebCmpt}>
      <div className={!isGroupsContent ? style.usersHeader : style.header}>
        <Header
          onGoBack={onBack}
          title="Groups"
          isUsersContent={!isGroupsContent}
          onSearch={(newQuery: string) => debouncedSearch(newQuery)}
          onFilterPick={handleFilterByStatus}
          onCancelSearch={handleOnCancelSearch}
          filterPick={state.usersFilters.status}
          addGroup={addGroup}
          onChangeFavorite={onChangeFavorite}
          cloneGroup={cloneGroup}
          updateGroup={updateGroup}
          removeGroup={removeGroup}
          selectedGroup={state.selectedGroup}
          filterResults={{ checkIn: available, checkOut: noAvailable, all }}
          isSearcing={isSearcing}
          setIsSearcing={setIsSearcing}
        />
      </div>
      <div className={styleContent}>
        {contents[sidebarState.content]}
      </div>
      {isGroupsContent && (
        <div className={style.footer}>
          <Image className={style.ponctuelLogo} src={logoPT} alt="logo" />
        </div>
      )}
    </div>
  );
}

export default Sidebar;
