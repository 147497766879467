import dayjs from 'dayjs';

import { DAY_SECONDS } from './constants';

import array from 'utils/array';

function getDateLabelsByRange(date: any) {
  const { endDate, startDate } = date;
  const days = [] as any;
  const length = endDate.diff(startDate, 'd');
  for (let index = 0; index < length + 1; index += 1) {
    days.push(dayjs(startDate).add(index, 'days'));
  }
  return days;
}

function getUserStatusProps(user: any, week: any[], weekPage: number) {
  const customTotalDays = user.totalDays && array.chunkArray(user.totalDays, 7);
  const customTotalActDays = user.totalActDays && array.chunkArray(user.totalActDays, 7);
  user.customTotalDays = customTotalDays[weekPage];
  user.customTotalActDays = customTotalActDays[weekPage];
  return { week, user, selectedClass: '', onClick: null };
}

function getUserInformation(userId: string, date: any, project: any) {
  return {
    userId,
    tz: encodeURIComponent(dayjs().format('Z')),
    startWeek: date.startDate ? date.startDate.toISOString() : dayjs(date).toISOString(),
    endWeek: date.endDate ? date.endDate.toISOString() : dayjs(date)
      .add(DAY_SECONDS - 1, 'second')
      .toISOString(),
    projectId: project?.value ?? '',
  };
}

export { getDateLabelsByRange, getUserStatusProps, getUserInformation };
