import React from 'react';

import UserActions from '../UserActions';

import { getLastActivity } from './utils';

import Title from 'components/Title';
import { Avatar } from 'components/Avatar';

import style from './UserItem.module.scss';
import { EVENT_TYPE } from 'utils/constants';

interface Props {
  name: string;
  preferredUsername: string;
  email?: string;
  status: string;
  picture: any;
  updatedAt: string;
  actions: Array<any>;
  selected?: boolean;
  onClick?: any;
  className?: any;
  sideBar?: boolean;
  outerRef?: any;
}

export default function UserItem({
  name,
  preferredUsername,
  email,
  picture,
  status,
  updatedAt,
  actions,
  selected,
  onClick,
  className,
  sideBar,
  outerRef,
}: Props) {
  function getUserStatusProps(userStatus: string): any {
    const userStatusData: any = {
      checkIn: {
        isCheckIn: true,
        initialTextStyle: style.available,
        initialLabelText: 'AVAILABLE',
      },
      checkOut: {
        isCheckIn: false,
        initialTextStyle: `${style.available} ${style.notAvailable}`,
        initialLabelText: 'NOT AVAILABLE',
      },
    };
    const { isCheckIn, initialTextStyle, initialLabelText } = userStatusData[userStatus];
    return {
      avatarProps: { src: picture, name: preferredUsername, size: 50, isCheckIn },
      labelStatusProps: { className: initialTextStyle, text: initialLabelText },
    };
  }

  const { avatarProps, labelStatusProps } = getUserStatusProps(status || EVENT_TYPE.CHECK_OUT);

  const isSelected = selected ? `${style.userInfoContainer} ${style.userSelected}` : style.userInfoContainer;

  const getActivityText = () => (
    <>
      <span className={labelStatusProps.className}>{labelStatusProps.text}</span>
      {sideBar && (
        <div className={style.lastUserConnection}>
          <Title title={updatedAt ? getLastActivity(updatedAt) : 'No activity'} />
        </div>
      )}
    </>
  );

  return (
    <div
      className={className || isSelected}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
      ref={outerRef}
    >
      <div className={style.userImage}>
        <Avatar {...avatarProps} />
      </div>
      <div className={style.userInfo}>
        <div>
          <Title title={preferredUsername} />
        </div>
        <div>
          <Title title={email || ''} />
        </div>
        <div className={style.userStatus}>{getActivityText()}</div>
      </div>
      <div className={style.actions}>
        <UserActions className={style.userActions} actions={actions} />
      </div>
    </div>
  );
}

UserItem.defaultProps = {
  email: '',
  actions: [],
  selected: false,
  onClick: null,
  className: undefined,
  sideBar: false,
  outerRef: null,
};
