import ActionCreatorFactory from '../../utils/actionCreator';

const actionCreator = ActionCreatorFactory('SIDEBAR');
export default {
  UPDATE_STATE: (properties?: any) => actionCreator('UPDATE_STATE', properties),
  ADD_GROUP: (newGroup?: any) => actionCreator('ADD_GROUP', newGroup),
  UPDATE_GROUP: (updatedGroup?: any) => actionCreator('UPDATE_GROUP', updatedGroup),
  REMOVE_GROUP: (groupId?: string) => actionCreator('REMOVE_GROUP', groupId),
  DISABLE_USER: (userId?: string) => actionCreator('DISABLE_USER', userId),
  CHANGE_FAVORITE: (groupId?: any) => actionCreator('RESET_GROUPS', groupId),
  SET_USER_GROUPS: (properties?: any) => actionCreator('SET_USER_GROUPS', properties),
  UPDATE_USER_EVENT_STATE: (userEvent?: any) => actionCreator('UPDATE_USER_EVENT_STATE', userEvent),
};
