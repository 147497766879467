import dayjs from 'dayjs';
import _, { noop } from 'lodash';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useGlobalContext } from 'contexts';

import Strings from './Strings';
import style from './ActivitiesModal.module.scss';

import { QueryOptions } from 'types';
import { useApi } from 'hooks/useApi';
import { useUser } from 'hooks/useUser';
import ActivityList from 'components/ActivityList';
import ActivityForm from 'components/ActivityForm';
import Error from 'components/Error';
import Modal from 'components/Modal';
import Loader from 'components/Loader';

import { ActivityService, UserService } from 'service';
import ProjectFilter from '../ProjectFilter';

const title = 'Record Timesheet';
const activityService = new ActivityService();
const userService = new UserService();

const errorProps = {
  title: Strings.errorTitle,
  message: Strings.errorMessage,
};

const defaultActivity = {
  time: '',
  category: '',
  comment: '',
  project: {
    groupId: '',
    name: '',
  },
};

function getQueryOptions(date: string): QueryOptions {
  const start = dayjs(date).startOf('d').format('YYYY-MM-DDTHH:mm:ss');
  const end = dayjs(date).add(1, 'd').startOf('d').format('YYYY-MM-DDTHH:mm:ss');

  return {
    filters: [`date $gte ${start}Z`, `date $lt ${end}Z`],
    orders: ['ASC(createdAt)'],
  };
}

type ActivitiesModalProps = {
  userId: string,
  date: string,
  opened: boolean,
  globalUserId: string,
  project?: any,
  selectedUserId?: string;
  selectedGroupId?: string,
  onChangeProject?: (newProjectId: any) => void;
  onClose?: () => void,
};

export default function ActivitiesModal({
    userId,
    date,
    opened,
    globalUserId,
    project,
    selectedUserId,
    selectedGroupId,
    onChangeProject,
    onClose
  }: ActivitiesModalProps): JSX.Element {
  const [activity, setActivity] = useState<any>(null);
  const [canPerformOperations, setCanPerformOperations] = useState<boolean>(false);
  const [globalState, globalRequest] = useGlobalContext();
  const { currentUserIsAdmin } = useUser();

  const { user } = globalState;
  const { getUserManager } = globalRequest;

  const validateManager = async (currentUserId: string, activityUserId: string) => {
    if (currentUserId === activityUserId) {
      setCanPerformOperations(true);
    } else {
      const response = await getUserManager(activityUserId);
      if (response.user.manager?.id === currentUserId) {
        setCanPerformOperations(true);
      }
    }
  };
  useEffect(() => {
    validateManager(user._id, userId);
  }, [user]);

  useEffect(() => {
    if (currentUserIsAdmin) {
      setCanPerformOperations(true);
    }
  }, [currentUserIsAdmin]);

  let activitiesRequest;
  if (project && project.key !== 'all') {
    activitiesRequest = activityService.getByUserAndProject(userId, project.key, getQueryOptions(date));
  } else {
    activitiesRequest = activityService.getByUser(userId, getQueryOptions(date));
  }
  const [data, response, refresh, , loading] = useApi(activitiesRequest);

  const userProjectsRequest = userService.getUserProjects(userId, selectedGroupId);
  const [projectOptions, setProjectOptions] = useState<any>([]);
  const [userProjects] = useApi(userProjectsRequest);

  async function deleteActivity(id: string, user: string, callback: any) {
    try {
      const requestOptions = await activityService.remove(id, user, globalUserId);
      await requestOptions.toAxios();
    } catch (e) {
      toast.error(Strings.deleteErrorMessage);
    } finally {
      await refresh();
    }

    callback();
  }

  const displayFormView = () => {
    setActivity(defaultActivity);
  };

  function onCancel(): void {
    setActivity(null);
    if (data && !(data.length > 0)) {
      onClose?.();
    }
  }

  async function onSave(andCreateNew = false, hadError = false): Promise<void> {
    if (hadError) return;
    if (andCreateNew) {
      setActivity(defaultActivity);
    } else {
      setActivity(null);
    }
    await refresh();
  }

  const onChangeActivity = (property: string, value: any) => {
    if (property === 'project') {
      const projectOption = _.find(projectOptions, (option: any) => option.key === value);
      setActivity({ ...activity, project: {
        id: value,
        name: projectOption.text,
      } });
    } else {
      setActivity({ ...activity, [property]: value });
    }
  };

  function onEditActivity(activityToEdit: any): void {
    setActivity({
      ...activityToEdit,
      category: activityToEdit.category.toLowerCase(),
    });
  }

  useEffect(() => {
    if (data && !(data.length > 0) && canPerformOperations) {
      displayFormView();
    }
    
    if (userProjects) {
      setProjectOptions(userProjects.map((project: any) => ({
        key: project._id,
        text: project.name,
        value: project._id,
      })));
    }
  }, [userProjects]);

  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      <div className={style.container}>
        <div className={style.header}>
          <time>{dayjs(date).format('ddd, MMMM Do')}</time>
        </div>
        {loading && <Loader active inline="centered" size="medium" />}
        {!loading && response.error && <Error errorProps={errorProps} />}
        {!loading && !response.error && (
          <>
            {!activity ? (
              <>
                <ProjectFilter
                  project={project}
                  onProjectChange={onChangeProject ?? (() => {})}
                  selectedGroupId={selectedGroupId}
                  selectedUserId={selectedUserId ?? ''}
                />
                <ActivityList
                  onAddClick={displayFormView}
                  onEditClick={onEditActivity}
                  onDeleteClick={deleteActivity}
                  items={data}
                  canPerformOperations={canPerformOperations}
                />
              </>
            ) : (
              <ActivityForm
                date={date}
                userId={userId}
                projectOptions={projectOptions}
                autoFocus={!data?.length || !!activity}
                onSave={onSave}
                onCancel={onCancel}
                activity={activity}
                onChangeActivity={onChangeActivity}
                globalUserId={globalUserId}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

ActivitiesModal.defaultProps = {
  onClose: noop,
  selectedGroupId: '',
};
