import emitter from 'utils/emitter';

export default function listenEvents(state: any, dispatch: any, actions: any) {
  function selectGroup(properties: any) {
    dispatch(actions.SELECT_GROUP(properties));
  }

  function deselectGroup(properties: any) {
    dispatch(actions.DESELECT_GROUP(properties));
  }

  function selectUser(properties: any) {
    dispatch(actions.SELECT_USER(properties));
  }

  function disableUser(properties: any) {
    dispatch(actions.DISABLE_USER(properties));
  }

  function selectPersonalGroup(properties: any) {
    dispatch(actions.UPDATE_STATE(properties));
  }

  function onNewUserEvent(userEvent: any) {
    dispatch(actions.UPDATE_USER_EVENT_STATE(userEvent));
  }

  emitter.on('sidebar:selectGroup', selectGroup);
  emitter.on('sidebar:deselectGroup', deselectGroup);
  emitter.on('sidebar:selectUser', selectUser);
  emitter.on('sidebar:disableUser', disableUser);
  emitter.on('sidebar:selectPersonalGroup', selectPersonalGroup);
  emitter.on('socket:userEvent', onNewUserEvent);

  return () => {
    emitter.off('sidebar:selectGroup', selectGroup);
    emitter.off('sidebar:deselectGroup', deselectGroup);
    emitter.off('sidebar:selectUser', selectUser);
    emitter.off('sidebar:disableUser', disableUser);
    emitter.off('sidebar:selectPersonalGroup', selectPersonalGroup);
    emitter.off('socket:userEvent', onNewUserEvent);
  };
}
