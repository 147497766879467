import React from 'react';

import Icon, { IconProps } from './Icon';

export default function Clock(props: IconProps) {
  return (
    <Icon {...props}>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" />
      <polyline points="12 7 12 12 15 15" />
    </Icon>
  );
}
