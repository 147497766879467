import React from 'react';

import style from './EmptyContent.module.scss';

interface Props {
  children: React.ReactNode;
  message: string;
  length?: number;
}

export default function EmptyContent({ children, message, length }: Props): JSX.Element {
  return length ? (
    <>{children}</>
  ) : (
    <div className={style.emptyContent}>
      <span>{message}</span>
    </div>
  );
}

EmptyContent.defaultProps = {
  length: 0,
};
