import { GroupService } from '../../service';
import { requestWithPagination, request } from 'utils/requester';

const groupService = new GroupService();

export async function getUserGroups(selectedGroup: any, startWeek: Date, options: any) {
  const { limit = 200, orders = ['ASC(name)'], skip, search = '', type = '', filters } = options;
  const serviceArguments = {
    queryOptions: { limit, skip, orders, filters }, selectedGroup, startWeek, search, type,
  };
  const groupsAndUsersRequest = groupService.getGroupsAndUsers(serviceArguments);
  return await requestWithPagination(groupsAndUsersRequest);
}

export async function getUserFavoriteGroups(options: any) {
  const { orders = ['ASC(name)'], search = '' } = options;
  const serviceArguments = {
    queryOptions: { orders }, search,
  };
  const favoriteGroupsRequest = groupService.getFavoriteGroups(serviceArguments);
  return await request(favoriteGroupsRequest);
}
