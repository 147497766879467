import React, { useContext, createContext, useReducer, useEffect } from 'react';

import reducer from './reducer';
import useRequests from './requests';
import actions from './actions';
import initialState from './initialState';
import listenEvents from './events';

export const AttendanceContext = createContext<any | null>(null);

export function useAttendanceContext() {
  return useContext(AttendanceContext);
}

export default function AttendanceContextProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const Requests = useRequests(state, dispatch, actions);
  const requests = Requests();

  useEffect(() => {
    const unsubscribe = listenEvents(state, dispatch, actions);
    return () => unsubscribe();
  }, [state]);

  return (
    <AttendanceContext.Provider value={[state, requests, actions, dispatch]}>{children}</AttendanceContext.Provider>
  );
}
