import React from 'react';
import { Header, List } from 'semantic-ui-react';

import './Terms.scss';

export default function Terms() {
  return (
    <section className="termsAndConditions" style={{ height: '100vh' }}>
      <section className="content">
        <Header as="h2" className="titleContent">
          T&Eacute;RMINOS Y CONDICIONES
        </Header>
        <Header className="subtitleContent">Introducci&oacute;n</Header>
        <p className="textContent">
          Bienvenido al sitio Web Ponctuel (el &apos;Sitio Web&apos;). Por favor revise los siguientes t&eacute;rminos y
          condiciones relativos al uso del Sitio Web. Al ingresar, usar, o descargar cualquier material del Sitio Web,
          usted manifiesta su acuerdo a seguir estos t&eacute;rminos y condiciones (los &apos;T&eacute;rminos&apos;) y a
          regirse por ellos. Si usted no est&aacute; de acuerdo con estos T&eacute;rminos, por favor no utilice este
          Sitio Web. Jala se reserva el derecho de cambiar estos T&eacute;rminos sin previo aviso.
        </p>
        <Header className="subtitleContent">Informaci&oacute;n sobre derechos de autor y marca</Header>
        <p className="textContent">
          Jala provee todos los materiales existentes en este Sitio Web y el dise&ntilde;o de este Sitio Web, incluyendo
          pero sin limitarse a la informaci&oacute;n, los documentos, productos, logos, gr&aacute;ficos, sonidos,
          im&aacute;genes, software, y servicios (los &apos;Materiales&apos;). Los Materiales son de propiedad y con
          derechos de autor a nombre de Jala.
        </p>
        <p className="textContent">
          Todos los nombres de productos Jala, nombres de servicio, lemas y logos a los que se hace referencia en este
          Sitio Web son marcas comerciales de Jala. Cualquier otro nombre de compa&ntilde;&iacute;a, producto o servicio
          al que se hace referencia en este Sitio Web se usa exclusivamente para prop&oacute;sitos de
          identificaci&oacute;n, pudiendo ser marcas comerciales de sus respectivos propietarios.
        </p>
        <Header className="subtitleContent">Uso de la informaci&oacute;n del Web Site</Header>
        <p className="textContent">
          Salvo que se indique lo contrario, ninguno de los Materiales podr&aacute; ser copiado, reproducido,
          distribuido, vuelto a publicar, bajado, exhibido, divulgado ni transmitido en forma alguna o por cualquier
          medio, sin previo consentimiento expreso y por escrito de Jala. Cualquier uso no autorizado de Materiales
          contenidos en este Sitio Web puede constituir una violaci&oacute;n de las leyes de propiedad intelectual,
          leyes sobre marcas comerciales, leyes de privacidad y publicidad, o las regulaciones y estatutos sobre
          comunicaciones.
        </p>
        <p className="textContent">
          Jala autoriza ver, descargar, e imprimir los Materiales con sujeci&oacute;n a las siguientes condiciones:
        </p>
        <List bulleted>
          <List.Item className="textContent">
            El uso de dichos Materiales ser&aacute; de naturaleza personal y no para reventa o uso comercial.
          </List.Item>
          <List.Item className="textContent">
            Dichos Materiales no ser&aacute;n modificados ni alterados, ni distribuidos, publicados, exhibidos, o
            transmitidos sin el consentimiento escrito expreso de Jala.
          </List.Item>
          <List.Item className="textContent">
            No se eliminar&aacute; ninguna nota respecto a propiedad, incluyendo las notas que se refieren a la
            propiedad intelectual.
          </List.Item>
          <List.Item className="textContent">No existir&aacute; transferencia de t&iacute;tulo alguno.</List.Item>
          <List.Item className="textContent">
            En cualquier momento, Jala puede revocar el uso de los mencionados Materiales, por cualquier raz&oacute;n o
            sin raz&oacute;n expl&iacute;cita.
          </List.Item>
        </List>
        <Header className="subtitleContent">Enlaces hacia otros sitios Web</Header>
        <p className="textContent">
          Los enlaces hacia sitios Web de terceros se proveen en este Sitio Web exclusivamente por comodidad. Al usar
          estos enlaces, el usuario abandonar&aacute; este Sitio Web. Jala no ha revisado todos estos sitios de
          terceros, ni los controla, y no es responsable por ninguno de estos sitios ni su contenido. As&iacute;, Jala
          no avala ni emite criterio alguno respecto a ellos, ni a ninguna informaci&oacute;n, software u otros
          productos o materiales que all&iacute; se encuentren, ni respecto a cualquier resultado que pudiera obtenerse
          al usarlos. Cualquier acceso a sitios de terceros enlazados a trav&eacute;s de este Sitio Web es de entera
          responsabilidad del usuario.
        </p>
        <Header className="subtitleContent">Desistimientos y limitaci&oacute;n de responsabilidad</Header>
        <p className="textContent">
          Los Materiales contenidos en este Sitio Web podr&iacute;an no estar actualizados o incluir inexactitudes u
          otros errores. Todos los Materiales se proveen &apos;tal cual&apos; y sin garant&iacute;a de ning&uacute;n
          tipo, sea &eacute;sta expresa o impl&iacute;cita, incluyendo sin limitaci&oacute;n cualquier garant&iacute;a
          de posibilidad de comercializaci&oacute;n y adecuaci&oacute;n a un prop&oacute;sito espec&iacute;fico.
        </p>
        <p className="textContent">
          Jala no ser&aacute; responsable por ning&uacute;n da&ntilde;o que se sufra como resultado de usar, modificar,
          contribuir, copiar, distribuir, o descargar los Materiales. Bajo ninguna circunstancia Jala ser&aacute;
          responsable por ning&uacute;n da&ntilde;o o perjuicio indirecto, punitivo, especial, incidental, o consecuente
          (incluyendo, pero sin quedar limitado a, p&eacute;rdida de negocios, ingresos, beneficios, uso, datos u otra
          ventaja econ&oacute;mica, false), sin importar c&oacute;mo hubiera surgido &eacute;ste, sea por acci&oacute;n
          contractual, negligencia, u otra acci&oacute;n dolosa, emergente de o relacionada con el uso o la
          aplicaci&oacute;n de la informaci&oacute;n disponible en este Sitio Web, inclusive en el caso de que Jala
          hubiera sido previamente advertida respecto a la posibilidad de tal da&ntilde;o.
        </p>
        <p className="textContent">
          Usted es el &uacute;nico responsable por la adecuada protecci&oacute;n y respaldo de los datos o equipo que
          use en conexi&oacute;n con este Sitio Web y usted no podr&aacute; reclamar a Jala por p&eacute;rdida de datos,
          tiempo de reiterada ejecuci&oacute;n, inexactitud de resultados, demoras en su trabajo o p&eacute;rdida de
          beneficio presunto que resultara como consecuencia de usar los Materiales. Usted acuerda proteger a Jala de
          procesos legales, y se compromete a no procesar legalmente a Jala en base a reclamos relacionados con el uso
          de este Sitio Web.
        </p>
        <br />
        <br />
        <Header as="h2" className="titleContent">
          INFORMACION LEGAL Y POL&Iacute;TICA DE PRIVACIDAD
        </Header>
        <Header as="h3" className="subtitleContent">
          <u>Check-in / Check-out mobile app &ldquo;PONCTUEL&rdquo;</u>
        </Header>
        <p className="textContent">
          El Usuario deber&aacute; leer atentamente este documento, el cual contiene informaci&oacute;n importante sobre
          sus derechos y obligaciones, como tambi&eacute;n sobre el uso de la aplicaci&oacute;n Ponctuel, sus servicios,
          e informaci&oacute;n legal y de privacidad.
        </p>
        <p className="textContent">
          Esta aplicaci&oacute;n es de propiedad de Jalasoft S.R.L., cuyas oficinas, para fines de la correspondencia
          relacionada con esta aplicaci&oacute;n est&aacute;n situadas en: Edificio Jalasoft S.R.L, Av. Melchor
          P&eacute;rez de Olgu&iacute;n N 2643- Zona Sarco, Cochabamba- Bolivia.
        </p>
        <p className="textContent">
          Los t&eacute;rminos &ldquo;Ponctuel&rdquo;, &quot;nuestro&quot;, &quot;nosotros&quot; o &quot;nuestros&quot;
          se refieren a Jalasoft S.R.L.&nbsp;
        </p>
        <p className="textContent">
          Esta Pol&iacute;tica de privacidad (&quot;Pol&iacute;tica de privacidad&quot;) se aplica a todas nuestras
          aplicaciones, servicios, funciones, software, y sitio Web (en conjunto, &quot;Servicios&quot;), a menos que se
          indique lo contrario.
        </p>
        <p className="textContent">
          Ponctuel es una aplicaci&oacute;n m&oacute;vil nativa, que permite registrar la disponibilidad de las
          personas, y que esta pueda ser notificada a quien corresponda, mediante un canal de comunicaci&oacute;n
          previamente definido. Este registro incluye tanto la hora como la ubicaci&oacute;n geogr&aacute;fica.
        </p>
        <p className="textContent">
          Adicionalmente Ponctuel ofrece un portal de Administraci&oacute;n, donde se puede visualizar la disponibilidad
          y la ubicaci&oacute;n de las personas, as&iacute; como como la cantidad de horas de disponibilidad de cada
          persona por periodo de tiempo.
        </p>
        <p className="textContent">
          Ponctuel se compromete a garantizar que su privacidad est&eacute; protegida. Si alguna vez le solicitamos que
          brinde informaci&oacute;n con la que pueda ser identificado al utilizar nuestra aplicaci&oacute;n o sitio Web,
          usted tiene la garant&iacute;a de que dicha informaci&oacute;n s&oacute;lo se utilizar&aacute; de acuerdo con
          esta declaraci&oacute;n de privacidad.
        </p>
        <p className="textContent">
          Ponctuel se compromete a garantizar que su privacidad est&eacute; protegida. Si alguna vez le solicitamos que
          brinde informaci&oacute;n con la que pueda ser identificado al utilizar nuestra aplicaci&oacute;n o sitio Web,
          usted tiene la garant&iacute;a de que dicha informaci&oacute;n s&oacute;lo se utilizar&aacute; de acuerdo con
          esta declaraci&oacute;n de privacidad.
        </p>

        <Header as="h3" className="subtitleContent">
          <u>Consentimiento para el uso de datos</u>
        </Header>
        <p className="textContent">
          El Usuario acepta que podemos recopilar y usar datos t&eacute;cnicos e informaci&oacute;n relacionada,
          incluida, entre otras, informaci&oacute;n t&eacute;cnica sobre su dispositivo m&oacute;vil, sistema y software
          de aplicaci&oacute;n y perif&eacute;ricos, que se recopila peri&oacute;dicamente para facilitar la
          provisi&oacute;n de actualizaciones de software, soporte de productos y otros servicios de usuario (si los
          hubiera) relacionados con la aplicaci&oacute;n. Podremos utilizar esta informaci&oacute;n, siempre que
          est&eacute; en un formulario que no identifique personalmente al Usuario, para mejorar nuestros Servicios
          ofrecidos.
        </p>
        <Header as="h3" className="subtitleContent">
          <u> Informaci&oacute;n recopilada</u>
        </Header>
        <p className="textContent">
          Ponctuel recibe o recopila informaci&oacute;n de nuestros usuarios cuando operamos y proveemos nuestros
          Servicios, incluso durante la instalaci&oacute;n, el acceso o el uso de nuestros Servicios.
        </p>

        <Header as="h3" className="subtitleContent">
          <u>Informaci&oacute;n que el Usuario proporciona</u>
        </Header>
        <p className="textContent">
          <strong>La informaci&oacute;n de cuenta.</strong> El Usuario proporciona su direcci&oacute;n de correo
          electr&oacute;nico para crear una cuenta de acceso a Ponctuel, a trav&eacute;s del active directory de la
          compa&ntilde;&iacute;a o de cualquier otra fuente externa.&nbsp;
        </p>
        <p className="textContent">
          <strong>CHECK-IN/CHECK OUT.</strong> Aqu&iacute;, el usuario realizara el registro de inicio de su
          disponibilidad o no disponibilidad de su persona, para que este dato sea de conocimiento de los dem&aacute;s.
          El usuario puede deshabilitar el uso de su ubicaci&oacute;n en cualquier momento.&nbsp;
        </p>
        <p className="textContent">
          <strong>Servicio de atenci&oacute;n al cliente.&nbsp;</strong>El Usuario puede proporcionarnos
          informaci&oacute;n relacionada con el uso de nuestros Servicios. Por ejemplo, el Usuario puede enviarnos un
          mensaje de correo electr&oacute;nico con informaci&oacute;n relacionada con el rendimiento o cualquier
          problema que experimente en relaci&oacute;n con nuestra aplicaci&oacute;n.
        </p>

        <Header as="h3" className="subtitleContent">
          <u>Informaci&oacute;n recopilada autom&aacute;ticamente</u>
        </Header>

        <p className="textContent">
          <strong>Uso e informaci&oacute;n de registro.</strong> Recopilamos informaci&oacute;n relacionada con el
          rendimiento, diagn&oacute;stico y servicio. Esto incluye informaci&oacute;n sobre la actividad del Usuario
          (como la forma en que se usa nuestros Servicios, la forma en que se interact&uacute;a con otros mediante
          nuestros Servicios, y datos similares), archivos de registro, as&iacute; como informes y registros de
          rendimiento, sitio Web, fallos y diagn&oacute;stico.
        </p>
        <p className="textContent">
          <strong>Informaci&oacute;n sobre transacciones.</strong> Si el Usuario paga por nuestros Servicios, podemos
          recibir informaci&oacute;n y confirmaciones como ser recibos de pago, incluidos los de las tiendas de
          aplicaciones o terceros que procesen los pagos de Usuario.
        </p>
        <p className="textContent">
          <strong>Informaci&oacute;n sobre conexi&oacute;n y dispositivos.</strong> Recopilamos informaci&oacute;n
          espec&iacute;fica del dispositivo de Usuario al instalar, acceder, o usar nuestros Servicios. Esto incluye
          informaci&oacute;n tal como el modelo de hardware, la informaci&oacute;n del sistema operativo,
          informaci&oacute;n sobre el navegador, la direcci&oacute;n IP, e informaci&oacute;n de la red m&oacute;vil, y
          los identificadores del dispositivo. Si el Usuario emplea nuestras funciones de ubicaci&oacute;n, recopilamos
          informaci&oacute;n sobre la ubicaci&oacute;n del dispositivo. Esta informaci&oacute;n tambi&eacute;n se recoge
          cuando el Usuario elige compartir su ubicaci&oacute;n con sus contactos, ver lugares cercanos, o aquellos que
          otros hayan compartido con el Usuario, y similares, para fines de diagn&oacute;stico y soluci&oacute;n de
          problemas. Por ejemplo, en caso de dificultades con la funci&oacute;n de nuestra aplicaci&oacute;n para
          compartir la ubicaci&oacute;n.
        </p>
        <p className="textContent">
          <strong>Cookies.</strong> Usamos cookies para operar y proveer nuestros Servicios, lo que incluye proveer
          nuestros Servicios basados en Web, mejorar la experiencia de Usuario, entender c&oacute;mo se usan nuestros
          Servicios, y personalizar nuestros Servicios. Podemos usar cookies para entender cu&aacute;les de las
          preguntas frecuentes de nuestro servicio de ayuda son m&aacute;s populares, y para mostrar al Usuario
          contenido relevante relacionado con nuestros Servicios. Adem&aacute;s, podemos usar cookies para recordar las
          elecciones del usuario, tales como preferencias de idioma, y tambi&eacute;n para personalizar nuestros
          Servicios para cada Usuario en particular.
        </p>
        <Header as="h3" className="subtitleContent">
          <u>Informaci&oacute;n de terceros</u>
        </Header>
        <p className="textContent">
          <strong>Informaci&oacute;n que otros proporcionan sobre el Usuario.</strong> Recibimos informaci&oacute;n que
          otras personas nos proporcionan, que puede incluir informaci&oacute;n sobre determinado Usuario en
          particular.&nbsp;
        </p>
        <p className="textContent">
          <strong>Proveedores externos.</strong> Trabajamos con proveedores externos que nos ayudan a operar, proveer,
          mejorar, entender, personalizar y comercializar nuestros Servicios, as&iacute; como ofrecer servicios de ayuda
          para nuestros Servicios. Por ejemplo, trabajamos con empresas para distribuir nuestras aplicaciones, ofrecer
          nuestros sistemas de infraestructura, de entrega, y otros, as&iacute; como para proporcionar
          informaci&oacute;n de mapas y lugares, procesar pagos, y ayudarnos a entender c&oacute;mo la gente usa
          nuestros Servicios, y comercializar &eacute;stos. Bajo ciertas circunstancias, dichos proveedores externos
          pueden proporcionarnos informaci&oacute;n sobre un usuario en particular; por ejemplo, las tiendas de
          aplicaciones pueden enviarnos informes que nos ayudan a diagnosticar y solucionar problemas con el servicio.
        </p>
        <p className="textContent">
          <strong>Servicios de terceros.</strong> Permitimos al Usuario emplear nuestros Servicios en relaci&oacute;n
          con servicios de terceros. Si se usan nuestros Servicios con dichos servicios de terceros, podemos recibir por
          parte de ellos informaci&oacute;n sobre un Usuario en particular. El Usuario debe tener en cuenta que cuando
          usa servicios de terceros, sus propias condiciones y pol&iacute;ticas de privacidad regir&aacute;n el uso de
          dichos servicios.
        </p>
        <Header as="h3" className="subtitleContent">
          <u>C&oacute;mo usamos la informaci&oacute;n</u>
        </Header>
        <p className="textContent">
          Usamos toda la informaci&oacute;n que tenemos para ayudarnos a operar, proveer, mejorar, entender,
          personalizar y comercializar nuestros Servicios, as&iacute; como ofrecer servicios de ayuda para nuestros
          Servicios.
        </p>
        <p className="textContent">
          <strong>Nuestros Servicios.</strong> Operamos y proveemos nuestros Servicios, lo que incluye ofrecer un
          servicio de atenci&oacute;n al cliente, as&iacute; como mejorar, solucionar, y personalizar nuestros
          Servicios. Entendemos c&oacute;mo se usan nuestros Servicios, y analizamos y usamos la informaci&oacute;n que
          tenemos para evaluar y mejorar nuestros Servicios, investigar, desarrollar y probar servicios nuevos y
          funciones nuevas, as&iacute; como llevar a cabo actividades para solucionar problemas con nuestros Servicios.
          Tambi&eacute;n usamos la informaci&oacute;n de Usuario para responder cuando un Usuario se pone en contacto
          con nosotros. Usamos cookies para operar, proveer, mejorar, entender, y personalizar nuestros Servicios.
        </p>
        <p className="textContent">
          <strong>Seguridad y protecci&oacute;n.</strong> Verificamos cuentas y actividades, y promovemos la seguridad
          dentro y fuera de nuestros Servicios, a trav&eacute;s de la investigaci&oacute;n de actividades sospechosas o
          infracciones a nuestras condiciones, as&iacute; como para asegurarnos de que nuestros Servicios se usen
          legalmente.
        </p>
        <p className="textContent">
          <strong>Informaci&oacute;n que el Usuario comparte con nosotros</strong> El Usuario comparte su
          informaci&oacute;n mientras usa nuestros Servicios y se comunica a trav&eacute;s de ellos. Nosotros
          compartimos la informaci&oacute;n de Usuario para ayudarnos a operar, mejorar, entender, personalizar, dar
          soporte y comercializar nuestros Servicios.
        </p>
        <p className="textContent">
          <strong>Informaci&oacute;n de cuenta de Usuario.</strong> El n&uacute;mero de tel&eacute;fono del Usuario, su
          nombre y foto de perfil, su estado de conexi&oacute;n, estado de &uacute;ltima conexi&oacute;n, pueden estar
          disponibles para cualquier persona que use nuestros Servicios, aunque el Usuario puede reconfigurar
          personalmente nuestros Servicios, para determinar qu&eacute; informaci&oacute;n quiere que est&eacute;
          disponible para los dem&aacute;s usuarios.
        </p>
        <p className="textContent">
          <strong>Proveedores externos.</strong> Trabajamos con proveedores externos que nos ayudan a operar, proveer,
          mejorar, entender, personalizar y comercializar nuestros Servicios, as&iacute; como ofrecer servicios de ayuda
          para nuestros Servicios. Cuando compartimos informaci&oacute;n con proveedores externos, les exigimos que usen
          la informaci&oacute;n de Usuario en conformidad con nuestras instrucciones y condiciones o con el permiso
          expreso del Usuario en cuesti&oacute;n.
        </p>
        <p className="textContent">
          <strong>Servicios de terceros.</strong> Cuando un Usuario emplea servicios de terceros que est&aacute;n
          integrados con nuestros Servicios, estos terceros pueden recibir informaci&oacute;n sobre lo que dicho Usuario
          comparte con ellos. Por ejemplo, si el Usuario emplea un servicio de copia de seguridad de datos integrado con
          nuestros Servicios (como iCloud o Google Drive), los proveedores correspondientes recibir&aacute;n
          informaci&oacute;n sobre lo que se comparte con ellos. Si el Usuario interact&uacute;a con un servicio de un
          tercero conectado a trav&eacute;s de nuestros Servicios, es posible que est&eacute; proporcionando
          informaci&oacute;n directamente a dicho tercero. El Usuario debe tener en cuenta que cuando usa servicios de
          terceros, sus propias condiciones y pol&iacute;ticas de privacidad regir&aacute;n el uso de dichos servicios.
        </p>
        <Header as="h3" className="subtitleContent">
          <u>Asignaci&oacute;n, cambio de control y transferencia</u>
        </Header>
        <p className="textContent">
          Todos los derechos y obligaciones establecidos en nuestra Pol&iacute;tica de privacidad se asignan libremente
          a cualquiera de nuestras afiliadas, en relaci&oacute;n con una fusi&oacute;n, adquisici&oacute;n,
          restructuraci&oacute;n o venta de activos, o por operaci&oacute;n de ley o de otro modo, y podemos transferir
          la informaci&oacute;n de Usuario a cualquiera de nuestras afiliadas, entidades sucesoras o propietarios
          nuevos.
        </p>
        <Header as="h3" className="subtitleContent">
          <u>Administraci&oacute;n de la informaci&oacute;n de Usuario</u>
        </Header>
        <p className="textContent">
          La administraci&oacute;n de la informaci&oacute;n del usuario est&aacute; a cargo de la compa&ntilde;&iacute;a
          a la que pertenece, dentro el marco de lo establecido en la NDA correspondiente.
        </p>
        <p className="textContent">
          El Usuario debe tener en cuenta que, al eliminar nuestros Servicios de su dispositivo, podemos almacenar su
          informaci&oacute;n durante un periodo m&aacute;s prolongado, por ejemplo, todos los registros de
          check-in/check-out que hizo.
        </p>
        <Header as="h3" className="subtitleContent">
          <u>Legislaci&oacute;n y protecci&oacute;n</u>
        </Header>
        <p className="textContent">
          Podemos recopilar, usar, conservar y compartir la informaci&oacute;n de Usuario, si consideramos de buena fe
          que esto es razonablemente necesario para: (a) responder de acuerdo con las leyes o normas, procesos legales o
          solicitudes gubernamentales aplicables; (b) exigir el cumplimiento de nuestras Condiciones, o cualquier otra
          condici&oacute;n y pol&iacute;tica aplicable, incluso para investigaciones de infracciones potenciales; (c)
          detectar, investigar, impedir y abordar fraudes y otras actividades ilegales o problemas t&eacute;cnicos o de
          seguridad; o (d) proteger los derechos, la propiedad y la seguridad de nuestros usuarios Ponctuel
        </p>
        <Header as="h3" className="subtitleContent">
          <u>Nuestras operaciones globales</u>
        </Header>
        <p className="textContent">
          El Usuario acepta nuestras pr&aacute;cticas de informaci&oacute;n, que incluyen la recopilaci&oacute;n, el
          uso, el procesamiento y el uso compartido de su informaci&oacute;n, tal como se describe en esta
          Pol&iacute;tica de privacidad, as&iacute; como la transferencia y el procesamiento de su informaci&oacute;n en
          Bolivia y, de manera global, en otros pa&iacute;ses donde tenemos o usamos instalaciones, proveedores de
          servicios o socios, independientemente de d&oacute;nde se use nuestros Servicios. El Usuario reconoce que las
          leyes, disposiciones y normas del pa&iacute;s en el que se almacena o procesa su informaci&oacute;n pueden ser
          diferentes de aquellas que rigen en su propio pa&iacute;s.
        </p>
        <Header as="h3" className="subtitleContent">
          Actualizaciones a nuestra pol&iacute;tica
        </Header>
        <p className="textContent">
          Podemos modificar o actualizar nuestra Pol&iacute;tica de privacidad. Avisaremos al Usuario las modificaciones
          a esta Pol&iacute;tica de privacidad, seg&uacute;n sea apropiado, y actualizaremos la fecha de
          &quot;&Uacute;ltima modificaci&oacute;n&quot; en la parte superior de esta Pol&iacute;tica de privacidad. Al
          continuar el empleo de nuestros Servicios, el Usuario confirma su aceptaci&oacute;n de nuestra Pol&iacute;tica
          de privacidad, con cualquier modificaci&oacute;n. Si el Usuario no est&aacute; de acuerdo con nuestra
          Pol&iacute;tica de privacidad y con sus modificaciones, debe dejar de emplear nuestros Servicios.
        </p>
        <p className="textContent">Por favor, revise nuestra Pol&iacute;tica de privacidad de vez en cuando.</p>
        <Header as="h3" className="subtitleContent">
          Cont&aacute;ctenos
        </Header>
        <p className="textContent">
          Si tiene preguntas sobre nuestra Pol&iacute;tica de privacidad, por favor p&oacute;ngase en contacto con
          nosotros:
        </p>
        <p className="textContent">JALASOFT S.R.L.</p>
        <p className="textContent">
          Edificio JALASOFT, Av. Melchor P&eacute;rez de Olgu&iacute;n N 2643 - Zona Sarco, Cochabamba - Bolivia
        </p>
        <p className="textContent">Telf: +591 4 4308282</p>
        <p className="textContent">
          <a href="mailto:Ponctuel@jalasoft.com">Ponctuel@jalasoft.com</a>
        </p>
      </section>
    </section>
  );
}
