import _ from 'lodash';

import actions from './actions';

import array from 'utils/array';
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE, GROUP_TYPE } from 'utils/constants';
import { Action } from 'utils/actionCreator';
import { dateTimeIndex } from 'utils/datetime';

function updateState(state: any, action: any) {
  return { ...state, ...action.payload };
}

function selectGroup(state: any, action: any) {
  const { selectedGroup } = action.payload;
  let project = null;
  if (selectedGroup?.type === GROUP_TYPE.SYSTEM) {
    const [defaultProject] = selectedGroup.projects;
    project = {
      key: defaultProject?._id,
      text: defaultProject?.customer ? `${defaultProject?.name} - ${defaultProject?.customer}` : defaultProject?.name,
      value: defaultProject?._id,
    };
  }
  const pagination = {
    activePage: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  };
  return { ...state, selectedGroup, project, pagination };
}

function deselectGroup(state: any) {
  return { ...state, selectedGroup: undefined };
}

function selectUser(state: any, action: any) {
  let { selectedGroupUser } = action.payload;
  selectedGroupUser = _.isEmpty(selectedGroupUser) ? [] : [selectedGroupUser];
  const pagination = {
    activePage: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  };
  return { ...state, selectedGroupUser, pagination };
}

function disableUser(state: any, action: any) {
  const [, attendancesList] = array.remove(state.attendancesList, (user: any) => user.id === action.payload);
  return { ...state, attendancesList };
}

function updateUserEventState(state: any, action: Action) {
  const userEvent = action.payload;

  const { userId, location, type, updatedAt, place, timezoneOffset } = userEvent;
  const { lastUsersEvents, attendancesList, week } = state;
  // Update lastUsersEvents
  const [, newLastUserEvents] = array.update(
    lastUsersEvents ?? [],
    // TODO: Review this condition
    (lastUserEvent: any) => lastUserEvent._id === userId || lastUserEvent.userId === userId,
    {
      location, type, updatedAt, place,
    },
  );
  // Check if the event date is in the attendance list and date range
  const userAttendance = (attendancesList ?? []).find((attendance: any) => attendance.id === userId);
  let newTotalDays = [];
  if (userAttendance) {
    const dateIndex = dateTimeIndex(week, updatedAt, timezoneOffset);
    const { totalDays } = userAttendance;
    newTotalDays = totalDays;
    if (dateIndex && userEvent.span) {
      newTotalDays[dateIndex] += userEvent.span;
    }
  }

  // Update attendanceList
  const [, newAttendancesList] = array.update(
    attendancesList ?? [],
    (attendance: any) => attendance.id === userId,
    {
      status: type,
      totalDays: newTotalDays,
    },
  );
  return {
    ...state,
    lastUsersEvents: newLastUserEvents,
    attendancesList: newAttendancesList,
  };
}

export default function eventReducer(state: any, action: any) {
  switch (action.type) {
    case actions.UPDATE_STATE().type:
      return updateState(state, action);
    case actions.SELECT_GROUP().type:
      return selectGroup(state, action);
    case actions.DESELECT_GROUP().type:
      return deselectGroup(state);
    case actions.SELECT_USER().type:
      return selectUser(state, action);
    case actions.DISABLE_USER().type:
      return disableUser(state, action);
    case actions.UPDATE_USER_EVENT_STATE().type:
      return updateUserEventState(state, action);
    default:
      return state;
  }
}
