import { QueryOptions } from '../types';

import { BaseService } from './BaseService';

export class WebhookGroupService extends BaseService {
  constructor() {
    super('/api/v1/webhookGroups');
  }

  async getWebhookGroups(queryOptions: QueryOptions = {}): Promise<any> {
    return this.getAll(queryOptions);
  }

  async createWebhookGroupByType(webHookType: string, groupType: string,
      payload: any, userId?: string, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/${webHookType}/${groupType}?`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, path, userId), data: payload as any });
  }

  async deleteWebhookGroupByType(webHookType: string, groupType: string,
      groupId: string, userId?: string, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/${groupId}/hooks/${webHookType}/${groupType}?`;
    return this.options({ method: 'delete', url: this.urlFor(queryOptions, path, userId) });
  }

  async updateWebhookGroupByType(webHookType: string, groupType: string, groupId: string,
      payload: any, userId?: string, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/${groupId}/hooks/${webHookType}/${groupType}?`;
    return this.options({ method: 'patch', url: this.urlFor(queryOptions, path, userId), data: payload as any });
  }
}
