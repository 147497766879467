import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItemProps, DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react';

import { ProjectService } from 'service';
import { optionExists, isId } from 'utils/validations';

const mapProjectsToOptions = (project: any): DropdownItemProps => ({
  key: project._id,
  text: project.customer ? `${project.name} - ${project.customer}` : project.name,
  value: project._id,
});

type Props = {
  projects?: string[];
  originalProjects?: any[];
  onProjectsChanged: (newProjects: string[]) => void;
  allowAdditions?: boolean;
  allowSelections?: boolean;
};

const projectService = new ProjectService();

export default function ProjectSelector({
  projects,
  originalProjects,
  onProjectsChanged,
  allowAdditions,
  allowSelections,
}: Props): JSX.Element {
  const [options, setOptions] = useState<any>((originalProjects || []).map(mapProjectsToOptions));
  const [searchInputQuery, setSearchInputQuery] = useState('');
  const [searchResultOptions, setSearchResultOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState<any>([]);

  const getAllProjects = async (projectssNotSelected: Array<string> = []) => {
    const allProjectsRequestOptions = await projectService.getProjectsNotSelected(projectssNotSelected, { limit: 10 });
    const result = await allProjectsRequestOptions.toAxios();
    return result.data?.data ?? [];
  };

  const loadProjects = useCallback(async (currentOptions: Array<any> = []) => {
    if (allowSelections) {
      const result = await getAllProjects(currentOptions.map((option) => option.key));
      setProjectList(result.map(mapProjectsToOptions));
    }
  }, [allowSelections]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  const onSearchChange = async (
    event: SyntheticEvent<HTMLElement, Event>,
    { searchQuery }: DropdownOnSearchChangeData,
  ) => {
    setSearchInputQuery(searchQuery);
    setLoading(true);
    try {
      const projectSearchRequest = await projectService.search(searchQuery, {
        fields: ['name'],
        limit: 10,
        orders: ['ASC(name)'],
      });
      let result = await projectSearchRequest.toAxios();
      result = result.data?.data ?? [];
      setSearchResultOptions(result.map(mapProjectsToOptions));
    } catch (err) {
      toast.error('Error searching projects');
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const newValues = data.value as string[];
    const newSearchResultOptions = searchResultOptions.filter((option: any) => newValues.includes(option.key));
    const newProjectList = projectList.filter((option: any) => newValues.includes(option.key));
    let newOptions = options.filter((option: any) => newValues.includes(option.key));
    newOptions = [...newOptions, ...newSearchResultOptions, ...newProjectList];
    onProjectsChanged(newValues);
    setOptions(newOptions);
    loadProjects(newOptions);
    setSearchInputQuery('');
    setSearchResultOptions([]);
  };

  const onAddItem = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as string;
    if (!optionExists(options, value) && !isId(value)) {
      setOptions([
        ...options,
        {
          key: data.value,
          text: data.value,
          value: data.value,
        },
      ]);
    }
  };

  const searchFilter = () => searchResultOptions.filter((option: any) => !projects?.includes(option.key));

  return (
    <Dropdown
      fluid
      selection
      multiple
      search={allowSelections ? searchFilter : false}
      onChange={onChange}
      value={projects}
      options={[...options, ...projectList]}
      placeholder="Search projects"
      allowAdditions={allowAdditions}
      searchQuery={searchInputQuery}
      onSearchChange={onSearchChange}
      loading={loading}
      onAddItem={onAddItem}
    />
  );
}

ProjectSelector.defaultProps = {
  projects: [],
  originalProjects: [],
};
