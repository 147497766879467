import React, { useState, useEffect } from 'react';
import { Button, Form, Header, Container, Divider, Grid } from 'semantic-ui-react';
import _ from 'lodash';

import UsersSection from '../../molecules/UsersSection';
import GroupSection from '../../molecules/GroupSection';
import NotificationSection from '../../molecules/NotificationSection';

import { SkypeGroup } from 'types';
import { useWindowDimensions } from 'hooks/useDimensions';

import './style.scss';

interface Props {
  group: SkypeGroup;
  onSave: any;
  onUpdate: any;
}

export default function NotificationForm({ onSave, group, onUpdate }: Props) {
  const initialState = { name: '', _id: '', users: undefined, newGroup: true, error: false };
  const [state, setState] = useState<any>(initialState);
  const { height } = useWindowDimensions();

  async function handlerSave() {
    if (!group.newGroup) {
      await onUpdate(group._id, state);
    } else {
      await onSave(state);
    }
  }

  function handleDisable() {
    let disable;
    if (!state.newGroup) {
      disable = _.isEqual(
        { name: state.name, _id: state._id, users: state.users,
          weeklyCheckInOut: state.weeklyCheckInOut, weeklyTimesheet: state.weeklyTimesheet },
        { name: group.name, _id: group._id, users: group.users,
          weeklyCheckInOut: group.weeklyCheckInOut, weeklyTimesheet: group.weeklyTimesheet },
      );
    }
    disable = disable || state.name.length <= 0 || state._id.length <= 0 || state.users.length <= 0 || state.error;
    return disable;
  }

  useEffect(() => {
    if (group !== null) {
      setState({ ...group, error: false });
    }
  }, [group]);

  return (
    <section style={{ height: `calc(${height}px - 28px)` }}>
      <Container className="skypeForm">
        <Header className="header" as="h2">{(state.type || '').toUpperCase()}</Header>
        <Form success>
          <Form.Field>
            <Grid divided="vertically">
              <Grid.Row columns="two">
                <Grid.Column>
                  <Divider horizontal>General</Divider>
                  <GroupSection
                    error={state.error}
                    onChange={(newState: any) => setState({ ...state, ...newState })}
                    group={state}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Divider horizontal>notifications</Divider>
                  <NotificationSection
                    onChange={(newState: any) => setState({ ...state, ...newState })}
                    group={state}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
          <Form.Field>
            <Divider horizontal>Users</Divider>
            <UsersSection
              selectedUserEmails={state.users}
              onChange={(newState: any) => setState({ ...state, ...newState })}
            />
          </Form.Field>
          {group.newGroup ? (
            <Button disabled={handleDisable()} className="actionButton" primary onClick={handlerSave}>
              Create
            </Button>
          ) : (
            <Button disabled={handleDisable()} className="actionButton" primary onClick={handlerSave}>
              Update
            </Button>
          )}
        </Form>
      </Container>
    </section>
  );
}
