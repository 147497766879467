import React from 'react';
import { BiAtom } from 'react-icons/bi';
import { MdGroup, MdPerson, MdPublic } from 'react-icons/md';
import { Icon } from 'semantic-ui-react';

import style from './Group.module.scss';

import { GROUP_TYPE } from 'utils/constants';
import { SingleGroupActions } from 'layout/Sidebar/Group/GroupActions';

type GroupIconProps = {
  groupType: string,
}

function GroupIcon({ groupType }: GroupIconProps): JSX.Element {
  switch (groupType) {
    case GROUP_TYPE.PRIVATE: return <MdPerson className={style.groupsIcon} size={19} />;
    case GROUP_TYPE.CUSTOM: return <MdGroup className={style.groupsIcon} size={19} />;
    case GROUP_TYPE.SYSTEM: return <BiAtom className={style.groupsIcon} size={19} />;
    case GROUP_TYPE.PUBLIC: return <MdPublic className={style.groupsIcon} size={19} />;
    default: return <></>;
  }
}

type PersonalGroupProps = {
  user: any,
  selected: boolean,
  onClick: () => void,
};

export function PersonalGroup({ user, selected, onClick }: PersonalGroupProps): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={selected ? `${style.container} ${style.selectedGroup}` : style.container}
      onClick={() => onClick()}
      onKeyDown={() => ({})}
    >
      <div className={`autoDots ${style.groupText}`}>
        <MdPerson className={style.groupsIcon} size={19} />
        <span className={style.groupTextName}>{user.name}</span>
      </div>
    </div>
  );
}

type GroupProps = {
  group: {
    _id: string,
    name: string,
    type: string,
    ownerIds: string[],
    projects?: any[],
    isFavorite?: boolean;
    location?: {
      coordinates: number[]
    }
  },
  selected: boolean,
  onClick: (group: any) => void,
  onEditGroup: (group: any) => void,
  onCloneGroup: (group: any) => void,
  onRemoveGroup: (group: any) => void,
  onChangeFavorite: (group: any) => void,
};

export function Group({
  group,
  selected,
  onClick,
  onEditGroup,
  onCloneGroup,
  onRemoveGroup,
  onChangeFavorite,
} : GroupProps) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={selected ? `${style.container} ${style.selectedGroup}` : style.container}
      onClick={() => onClick(group)}
      onKeyDown={() => ({})}
    >
      <div className={`autoDots ${style.groupText}`}>
        <GroupIcon groupType={group.type} />
        <span className={style.groupTextName}>{group.name}</span>
      </div>
      {group.isFavorite ? <Icon name="star" /> : ''}
      <SingleGroupActions
        onChangeFavorite={() => onChangeFavorite(group)}
        favoriteButtonMessage={group?.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        icon={<Icon name="ellipsis vertical" />}
        groupOwners={group.ownerIds}
        groupType={group.type}
        className={style.singleGroupActions}
        direction="left"
        onEdit={() => onEditGroup(group)}
        onClone={() => onCloneGroup(group)}
        onRemove={() => onRemoveGroup(group)}
      />
    </div>
  );
}
