import React from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Icon, Popup, Table } from 'semantic-ui-react';

import ProjectFilter from '../ProjectFilter';

import style from './HeaderTable.module.scss';

import { useGlobalContext } from 'contexts';
import { useWindowDimensions } from 'hooks/useDimensions';
import { ScreenHandler } from 'utils/screenHandler';

type Props = {
  week: any[];
  onChange: any;
  currentPage: any;
  limit: number;
  selectedGroupId: string;
  selectedUserId: string;
  project: any;
  onChangeProject: (newProjectId: any) => void;
};

export default function HeaderTable({
  week,
  onChange,
  currentPage,
  limit,
  project,
  onChangeProject,
  selectedGroupId,
  selectedUserId,
}: Props): JSX.Element {
  const [state] = useGlobalContext();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const screenHandler = new ScreenHandler(width, location);
  const { contentToShow } = state;
  const minPageNumber = 0;
  function isView(tabId: string) {
    return tabId === contentToShow;
  }
  const isToday = (date: any): boolean =>
    dayjs().format('MM-DD-YYYY') === date.format('MM-DD-YYYY');

  return (
    <Table.Header className={style.tableHeader}>
      <Table.Row>
        <Table.HeaderCell colSpan={1} width="one" className={style.emptyHeader}>
          <div className={style.projectContainer}>
            {
              isView('timesheet') && (
                <ProjectFilter project={project} onProjectChange={onChangeProject} selectedGroupId={selectedGroupId} selectedUserId={selectedUserId} />
              )
            }
            {currentPage <= minPageNumber ? (
              <></>
            ) : (
              <div
                className={style.changeWeekButton}
                onClick={() => onChange(currentPage - 1)}
                onKeyDown={() => onChange(currentPage - 1)}
                tabIndex={0}
                role="button"
              >
                <Icon name="angle left" disabled={currentPage === 0} />
              </div>
            )}
          </div>
        </Table.HeaderCell>
        {week && _.map(week, (date) => {
          const isSmallResponsiveSize = screenHandler.isSmallResponsiveSize();
          const shortDate = date.format('MMMM Do');
          const longDate = date.format('ddd, MMMM Do');
          return (
            <Table.HeaderCell
              textAlign="center"
              key={longDate}
              className={isToday(date) ? style.headerMarked : style.header}
            >
              {
                !isSmallResponsiveSize
                  ? <span>{longDate}</span>
                  : <Popup content={longDate} trigger={<span>{shortDate}</span>} />
              }
            </Table.HeaderCell>
          );
        })}
        <Table.HeaderCell
          {...(!isView('timesheet') ? { textAlign: 'center' } : { textAlign: 'left' })}
          className={style.header}
        >
          TOTAL
        </Table.HeaderCell>
        {isView('timesheet') && <Table.HeaderCell className={style.header}/>}
        {currentPage + 1 >= limit ? (
          <></>
        ) : (
          <Table.HeaderCell className={style.emptyHeader}>
            <div
              className={style.changeNextWeek}
              onClick={() => onChange(currentPage + 1)}
              onKeyDown={() => onChange(currentPage + 1)}
              tabIndex={0}
              role="button"
            >
              <Icon name="angle right" />
            </div>
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
  );
}

HeaderTable.defaultProps = {
  selectedGroupId: '',
};
