import { noop } from 'lodash';
import React from 'react';

import TimeInputPolyfill from '../TimeInputPolyfill/ReactTimeInputPolyfill';
import * as ReactTimeInputPolyfill from '../TimeInputPolyfill/ReactTimeInputPolyfill';

import style from './TimeInput.module.scss';

type TimeInputProps = {
  currentValue: string,
  onInputChange: any,
  className?: string,
  format?: ReactTimeInputPolyfill.TimeFormat,
  onBlur?: any,
  onClick?: any,
  disabled?: boolean,
};

export default function TimeInput({
  currentValue,
  onInputChange,
  className,
  format,
  onBlur,
  onClick,
  disabled,
}: TimeInputProps): JSX.Element {
  return (
    <div className={style.label}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        {/* For some reason this span is required for the library */}
        <span className={style.hide}>-</span>
        <TimeInputPolyfill
          value={currentValue}
          forcePolyfill
          format={format}
          className={`${className} ${style.input}`}
          onChange={({ value }) => onInputChange(value)}
          onBlur={onBlur}
          onClick={onClick}
          disabled={disabled}
        />
      </label>
    </div>
  );
}

TimeInput.defaultProps = {
  className: '',
  format: '24',
  onBlur: noop,
  onClick: noop,
  disabled: false,
};
