import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { TimeSheetDailyReport, TimeSheetReport } from './organisms/TimesheetReport';

import { useAttendanceContext } from 'contexts';

import Attendance from 'components/Attendance';
import { DAY_SECONDS } from 'utils/constants';

function getPDFName({ name, startWeek, endWeek }: any) {
  return `${name}_${dayjs(startWeek).format('MM-DD-YYYY')} ${dayjs(endWeek).format('MM-DD-YYYY')}`;
}

function getPDFDailyName({ name, startWeek }: any) {
  return `${name}_${dayjs(startWeek).format('MM-DD-YYYY')}`;
}

function checkIsDailyReport(startWeek: string, endWeek: string) {
  const diff = dayjs(endWeek).diff(dayjs(startWeek));
  return diff / 1000 === DAY_SECONDS - 1;
}

function Timesheet() {
  const [state] = useAttendanceContext();
  const { userTimesheet } = state;

  useEffect(() => {
    if (userTimesheet) {
      const isDailyReport = checkIsDailyReport(userTimesheet.startWeek, userTimesheet.endWeek);
      let reportDocument = null;
      let pdfName = '';
      if (isDailyReport) {
        reportDocument = TimeSheetDailyReport({
          name: userTimesheet.name,
          actitities: userTimesheet.weekActivities,
          date: userTimesheet.startWeek,
          email: userTimesheet.email,
          totalAct: userTimesheet.totalActWeek,
        });
        pdfName = getPDFDailyName(userTimesheet);
      } else {
        reportDocument = TimeSheetReport(userTimesheet);
        pdfName = getPDFName(userTimesheet);
      }
      pdf(reportDocument)
        .toBlob()
        .then((pdfDoc) => {
          saveAs(pdfDoc, pdfName);
        });
    }
  }, [userTimesheet]);
  return <Attendance />;
}

export default Timesheet;
