import { useEffect, useState } from 'react';

import { useGlobalContext } from 'contexts';

export function useUser() {
  const [, globalRequest] = useGlobalContext();
  const { getUser, isAdmin, isManager } = globalRequest;
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState<any>(false);
  const [currentUserIsManager, setCurrentUserIsManager] = useState<any>(false);

  const isGroupOwner = (groupOwnerIds?: Array<string>) =>
    currentUser && groupOwnerIds && groupOwnerIds.includes(currentUser.profile?.sub);

  useEffect(() => {
    const fetchUserData = async () => {
      const userResult = await getUser();
      const isAdminResult = await isAdmin();
      const isManagerResult = await isManager();
      return {
        userResult,
        isAdminResult,
        isManagerResult
      };
    };
    let isMounted = true;
    fetchUserData().then(({ userResult, isAdminResult, isManagerResult }) => {
      if (isMounted) {
        setCurrentUser(userResult);
        setCurrentUserIsAdmin(isAdminResult);
        setCurrentUserIsManager(isManagerResult);
      }
    });
    return () => { isMounted = false; };
  }, [getUser, isAdmin, isManager]);

  return {
    currentUser,
    currentUserIsAdmin,
    isGroupOwner,
    currentUserIsManager,
  };
}
