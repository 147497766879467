import { useState } from 'react';

import dayjs from 'dayjs';

export function useDates(): any {
  const [startWeek, setStartWeek] = useState(dayjs().startOf('w').day(1));
  const [endWeek, setEndWeek] = useState(dayjs().endOf('w').day(7));

  return { startWeek, endWeek, setStartWeek, setEndWeek };
}
