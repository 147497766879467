import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import _ from 'lodash';

dayjs.extend(duration);

export function convertMilSec(milliseconds: number): string {
  const paddedMinutes = _.padStart(dayjs.duration(milliseconds).minutes().toString(), 2, '0');
  const hours = Math.trunc(dayjs.duration(milliseconds).asHours());
  return `${hours}:${paddedMinutes}`;
}
