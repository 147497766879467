
import { AttendancesService } from 'service';
import { requestWithPagination, request } from 'utils/requester';

const attendancesService = new AttendancesService();

export async function getAttendances(serviceArguments: any) {
  const groupsAndUsersRequest = attendancesService.getAttendances(serviceArguments);
  const data: any = await requestWithPagination(groupsAndUsersRequest);
  return data;
}

export async function getAllAttendances(serviceArguments: any) {
  const groupsAndUsersRequest = attendancesService.getAttendances(serviceArguments);
  const data: any = await request(groupsAndUsersRequest);
  return data;
}
