import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

import dayjs from 'dayjs';

import { styles } from './styles';

import logoJala from 'assets/images/logoJala.png';

type TimesheetReportHeaderType = {
  userName: string,
  userEmail: string,
  startWeek: string,
  endWeek: string,
}

function TimesheetReportHeader({ userName, userEmail, startWeek, endWeek }: TimesheetReportHeaderType): JSX.Element {
  return (
    <View style={styles.header}>
      <View style={styles.userInfo}>
        <Text style={styles.userName}>{userName}</Text>
        <Text style={styles.userEmail}>{userEmail}</Text>
        <View style={styles.week}>
          <Text>{dayjs(startWeek).format('MM/DD/YYYY')}</Text>
          <Text style={styles.weekSeparator}>-</Text>
          <Text>{dayjs(endWeek).format('MM/DD/YYYY')}</Text>
        </View>
      </View>
      <View style={styles.logo}>
        <Image style={styles.image} src={logoJala} />
      </View>
    </View>
  );
}

export default TimesheetReportHeader;
