import React from 'react';

import UserItemList from '../UserItemList';

import style from './style.module.scss';

import { User } from 'types';

interface Props {
  selectedUserEmails: string[],
  onChange: any,
}

export default function UserSection({ onChange, selectedUserEmails }: Props) {
  function onRemove(user: User) {
    const newUsers = selectedUserEmails.filter((email: string) => email !== user.email);
    onChange({ users: newUsers });
  }

  function onAdd(user: User) {
    const newUsers = [...selectedUserEmails, user.email];
    onChange({ users: newUsers });
  }

  return (
    <section className={style.container}>
      {selectedUserEmails
        && (
          <>
            <section className={style.containerList}>
              <UserItemList
                key="add-users"
                onActionClick={onAdd}
                actionName="ADD"
                label="Available users"
                placeholder="Search for user by email or full name"
                filter={{ email: { $nin: selectedUserEmails } }}
              />
            </section>
            <section className={style.containerList}>
              <UserItemList
                key="remove-users"
                onActionClick={onRemove}
                actionName="REMOVE"
                label="Selected users"
                placeholder="Search for user by email or full name"
                filter={{ email: { $in: selectedUserEmails } }}
              />
            </section>
          </>
        )}
    </section>
  );
}
