import React from 'react';

import style from './MemberItem.module.scss';

import { AvatarItem } from 'components/Avatar';

type MemberItemProps = {
  user: any;
};

export default function MemberItem({ user }: MemberItemProps): JSX.Element {
  return (
    <div className={style.item}>
      <AvatarItem src={user.picture} name={user.preferredUsername} />
      <div className={style.content}>
        <div>{user.preferredUsername}</div>
        <div className={style.email}>{user.email}</div>
      </div>
    </div>
  );
}
