import React from 'react';

import Loader from '../Loader';

import style from './Loading.module.scss';

export default function Loading(props: any): JSX.Element {
  return (
    <div className={style.loaderPosition}>
      <Loader active size="medium" inline="centered" {...props} />
    </div>
  );
}
