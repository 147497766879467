const REDIRECT_URL_KEY = "redirect_url";

export function setRedirectPath(path: string) {
  sessionStorage.setItem(REDIRECT_URL_KEY, path);
}

export function cleanRedirectPath() {
  sessionStorage.removeItem(REDIRECT_URL_KEY);
}

export function getRedirectPath(defaultPath: string) {
  return sessionStorage.getItem(REDIRECT_URL_KEY) || defaultPath;
}
