import React, { useState } from 'react';
import { Button, Dropdown, Popup } from 'semantic-ui-react';

import style from './GroupActions.module.scss';

import { GROUP_TYPE } from 'utils/constants';
import { useUser } from 'hooks/useUser';

type SingleGroupActionsProps = {
  onEdit: any;
  onRemove: any;
  onClone: any;
  onChangeFavorite?: any;
  favoriteButtonMessage?: string;
  groupType?: string;
  groupOwners?: Array<string>;
  className: any;
  icon: any;
  direction?: any;
};

export function SingleGroupActions({
  onEdit,
  onChangeFavorite,
  groupType,
  favoriteButtonMessage,
  groupOwners,
  onRemove,
  onClone,
  icon,
  ...props
}: SingleGroupActionsProps): JSX.Element {
  const { currentUserIsAdmin, currentUserIsManager, isGroupOwner } = useUser();
  const [open, setOpen] = useState(false);

  function handleClick(callback: any) {
    callback();
    setOpen(false);
  }

  function isAdminOrOwner() {
    return currentUserIsAdmin || isGroupOwner(groupOwners);
  }

  function isAdminOrManager() {
    return currentUserIsAdmin || currentUserIsManager;
  }

  function isNotASystemGroupType() {
    return groupType !== GROUP_TYPE.SYSTEM;
  }

  return (
    <div
      onKeyDown={(event: any) => event.stopPropagation()}
      tabIndex={0}
      role="button"
      className={props.className}
      onClick={(event: any) => event.stopPropagation()}
      onMouseLeave={() => setOpen(false)}
    >
      <Popup
        trigger={icon}
        on="click"
        pinned={false}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        position="bottom right"
        offset={[12, 0]}
        {...props}
        className={style.popupActions}
      >
        <Button.Group vertical>
          <Button className={style.singleButton} onClick={() => handleClick(onChangeFavorite)}>
            {favoriteButtonMessage}
          </Button>
          { isAdminOrOwner() && isNotASystemGroupType()
            && <Button className={style.singleButton} onClick={() => handleClick(onEdit)}>Edit Group</Button> }
          { isAdminOrManager() && isNotASystemGroupType()
            && <Button className={style.singleButton} onClick={() => handleClick(onClone)}>Clone Group</Button> }
          { isAdminOrOwner() && isNotASystemGroupType()
            && <Button className={style.singleButton} onClick={() => handleClick(onRemove)}>Delete Group</Button> }
        </Button.Group>
      </Popup>
    </div>
  );
}

SingleGroupActions.defaultProps = {
  groupType: GROUP_TYPE.CUSTOM,
  groupOwners: null,
  direction: 'right',
  onChangeFavorite: null,
  favoriteButtonMessage: '',
};

type GroupActionsProps = {
  onAdd: any;
  className: string;
  icon: any;
};

export function GroupActions({
  onAdd, ...props }: GroupActionsProps): JSX.Element {
  const { currentUserIsAdmin, currentUserIsManager } = useUser();

  return (
    (currentUserIsAdmin || currentUserIsManager) && <Dropdown {...props}>
      <Dropdown.Menu>
        <Dropdown.Item text="New Group" onClick={onAdd} />
      </Dropdown.Menu>
    </Dropdown>
  );
}
