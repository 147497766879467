import React, { useState } from 'react';
import _, { noop } from 'lodash';
import { FiSearch } from 'react-icons/fi';
import { Icon, Input, Popup } from 'semantic-ui-react';
import { MdChevronLeft } from 'react-icons/md';

import { GroupActions, SingleGroupActions } from '../Group/GroupActions';

import style from './Header.module.scss';

import { MODAL_MODE, FILTER_USERS_PICK } from 'utils/constants';
import { GroupModal } from 'components/GroupModal';

import { Group } from 'types';

interface Props {
  title: string;
  selectedGroup: Group;
  isUsersContent: boolean;
  filterPick: string;
  filterResults?: any;
  addGroup: any;
  onChangeFavorite: any;
  cloneGroup: any;
  updateGroup: any;
  removeGroup: any;
  onSearch?: any;
  onGoBack?: any;
  onCancelSearch?: any;
  onFilterPick?: any;
  isSearcing: boolean;
  setIsSearcing: any;
}

function SidebarHeader({
  title,
  onSearch,
  selectedGroup,
  onGoBack,
  isUsersContent,
  onCancelSearch,
  addGroup,
  onChangeFavorite,
  cloneGroup,
  updateGroup,
  removeGroup,
  onFilterPick,
  filterPick,
  filterResults,
  isSearcing,
  setIsSearcing,
}: Props) {
  const initialModalState = { mode: '', groupId: '', isOpen: false };
  const [modalState, setModalState] = useState(initialModalState);

  const createModalState = { mode: MODAL_MODE.CREATE, groupId: '', isOpen: true };
  const updateModalState = { mode: MODAL_MODE.UPDATE, groupId: selectedGroup._id, isOpen: true };
  const removeModalState = { mode: MODAL_MODE.REMOVE, groupId: selectedGroup._id, isOpen: true };

  function handleComplete(mode: string, data: any, currentUser: any) {
    const callbacks: any = {
      create: () => addGroup(data),
      update: () => updateGroup(data, currentUser, isUsersContent),
      remove: () => {
        removeGroup(data);
        onGoBack();
      },
    };
    callbacks[mode]();
    setModalState(initialModalState);
  }

  function onCloseSearch() {
    setIsSearcing(false);
    onCancelSearch();
  }

  const WithUsers = (): JSX.Element => (
    <>
      <div className={style.groupTitle}>
        <MdChevronLeft onClick={onGoBack} className={style.goBack} size={35} />
        <span title={selectedGroup.name} className={`autoDots ${style.titleHeader}`}>
          {selectedGroup.name}
        </span>
        <SingleGroupActions
          onChangeFavorite={() => onChangeFavorite(selectedGroup)}
          favoriteButtonMessage={selectedGroup?.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          icon={<Icon name="ellipsis vertical" />}
          className={style.groupActionIcon}
          groupType={selectedGroup.type}
          groupOwners={selectedGroup.ownerIds}
          onEdit={() => setModalState(updateModalState)}
          onClone={() => cloneGroup(selectedGroup)}
          onRemove={() => setModalState(removeModalState)}
        />
      </div>
      <Popup
        content='Search'
        trigger={
          <FiSearch className={style.searchIcon} onClick={() => setIsSearcing(true)} size={18} />
        }
      />
    </>
  );

  const WithGroups = (): JSX.Element => (
    <>
      <div className={style.groupTitle}>
        <span title={title} className={`autoDots ${style.titleHeader}`}>
          {title}
        </span>
        <GroupActions
          icon={<Icon name="ellipsis vertical" />}
          className={style.groupActionIcon}
          onAdd={() => setModalState(createModalState)}
        />
      </div>
      <Popup content='Search' trigger={
        <FiSearch className={style.searchIcon} onClick={() => setIsSearcing(true)} size={18} />} 
      />
    </>
  );

  const CategoryBarItem = ({ name, id }: { name: string; id: string }): JSX.Element => (
    <span
      aria-hidden
      onClick={() => onFilterPick(id)}
      className={filterPick === id ? `${style.categoryItem} ${style.catergorySelected} ` : style.categoryItem}
    >
      {name}
    </span>
  );

  return (
    <div className={`${style.groupHeaderCmpt} ${isUsersContent && !isSearcing && style.removePaddingLeft}`}>
      <GroupModal
        opened={modalState.isOpen}
        mode={modalState.mode}
        groupId={selectedGroup?._id}
        onComplete={handleComplete}
        onClose={() => setModalState(initialModalState)}
      />
      {isSearcing ? (
        <Input
          autoFocus
          className={style.searchInput}
          placeholder="Search..."
          onInput={(e: any) => onSearch(e.target.value)}
          icon={<Icon className={style.closeSearchIcon} name="close" link onClick={onCloseSearch} />}
          open={false}
        />
      ) : isUsersContent ? (
        <WithUsers />
      ) : (
        <WithGroups/>
      )}
      {isUsersContent && (
        <div className={isSearcing ? `${style.usersFilter} ${style.searchActive}` : style.usersFilter}>
          <CategoryBarItem name={`All (${filterResults.all})`} id={FILTER_USERS_PICK.ALL} />
          <CategoryBarItem name={`Available (${filterResults.checkIn})`} id={FILTER_USERS_PICK.CHECKIN} />
          <CategoryBarItem name={`Not available (${filterResults.checkOut})`} id={FILTER_USERS_PICK.CHECKOUT} />
        </div>
      )}
    </div>
  );
}

SidebarHeader.defaultProps = {
  onSearch: noop,
  onGoBack: noop,
  onCancelSearch: noop,
  onFilterPick: noop,
  onFilterVisibility: noop,
  filterResults: {
    all: 0,
    checkIn: 0,
    checkOut: 0,
  },
};

export default SidebarHeader;
