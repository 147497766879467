import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { Dropdown, DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import style from './ProjectFilter.module.scss';

import { ProjectService } from 'service';

const mapProjectsToOptions = (originalProjects: any[], selectedProject: any) => {
  let addSelectedProjectToOptions = false;
  if (
    selectedProject
    && selectedProject.key !== 'all'
    && !originalProjects.some((project: any) => (project._id === selectedProject.value))
  ) {
    addSelectedProjectToOptions = true;
  }
  return [
    { key: 'all', text: 'All Projects', value: 'all' },
    ...originalProjects.map((project: any) => ({
      key: project._id,
      text: project.customer ? `${project.name} - ${project.customer}` : project.name,
      value: project._id,
    })),
    ...addSelectedProjectToOptions ? [selectedProject] : [],
  ];
};

type ProjectFilterProps = {
  project: any,
  selectedGroupId: string,
  selectedUserId: string,
  onProjectChange: (newProject: any) => void;
  className?: string,
};

const projectService = new ProjectService();

export default function ProjectFilter({
  project, onProjectChange, selectedGroupId, selectedUserId, className }: ProjectFilterProps): JSX.Element {
  const [options, setOptions] = useState<any>([]);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);

  const onSearchChange = useCallback(async (
    event: SyntheticEvent<HTMLElement, Event> | undefined, { searchQuery }: DropdownOnSearchChangeData) => {
    setSearchInput(searchQuery);
    setLoading(true);
    try {
      const searchProjectsRequest = await projectService.searchByGroup(
        searchQuery, { fields: ['name'], limit: 10, orders: ['ASC(name)'] }, selectedGroupId, selectedUserId);
      const result = await searchProjectsRequest.toAxios();
      setOptions(mapProjectsToOptions(result.data?.data ?? [], project));
    } catch (err) {
      toast.error('Error searching projects');
    } finally {
      setLoading(false);
    }
  }, [project, selectedGroupId]);

  useEffect(() => {
    onSearchChange(undefined, { searchQuery: '' });
  }, [onSearchChange, selectedGroupId]);

  const onChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const newProject = options.find((option: any) => option.value === data.value);
    onProjectChange(newProject);
  };

  return (
    <Dropdown
      selectOnBlur={false}
      selectOnNavigation={false}
      selection
      fluid
      search
      loading={loading}
      searchQuery={searchInput}
      onSearchChange={onSearchChange}
      value={project?.value ?? 'all'}
      options={options}
      onChange={onChange}
      className={`${style.dropdown} ${className}`}
    />
  );
}

ProjectFilter.defaultProps = {
  selectedGroupId: '',
  className: '',
};
