import React, { useEffect, useRef, useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react';
import { noop } from 'lodash';

import ManualRecordForm from 'components/Attendance/ManualRecordForm';

import style from './ManualRecordAccordion.module.scss';

type Props = {
  currentDate: any;
  onSubmit?: any;
};

export default function ManualRecordAccordion({ currentDate, onSubmit }: Props): JSX.Element {
  const [visible, setVisible] = useState(false);
  const formRef = useRef<any>(null);

  useEffect(() => {
    setVisible(false);

    if (formRef.current) {
      formRef.current.clearForm();
    }
  }, [currentDate]);

  function handleClick() {
    setVisible(!visible);
  }

  return (
    <>
      <Accordion className={style.accordion}>
        <AccordionTitle className={style.accordionTitle} onClick={handleClick}>
          <FiPlusCircle size={20} className={`${style.plusIcon} ${visible ? style.rotate : ''}`} />
          <span>Add Record Manually</span>
        </AccordionTitle>

        <AccordionContent active={visible} className={style.accordionContent}>
          <ManualRecordForm date={currentDate} onSubmit={onSubmit} ref={formRef} />
        </AccordionContent>
      </Accordion>
    </>
  );
}

ManualRecordAccordion.defaultProps = {
  onSubmit: noop,
};
