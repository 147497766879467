import React from 'react';
import { Button, Accordion, AccordionTitle, AccordionContent, Icon } from 'semantic-ui-react';

import GroupItem from '../GroupItem';
import PersonalGroup from '../GroupItem/PersonalGroup';

import styles from './GroupList.module.scss';

import Loader from 'components/Loader';
import useLoadItems from 'hooks/useLoadItems';
import { GROUP_TYPE } from 'utils/constants';

interface Props {
  favoriteGroups: any[];
  systemGroups: any[];
  customGroups?: any[];
  selectedGroup?: any;
  user: any;
  onSelect: any;
  onRemove: any;
  onUpdate: any;
  onClone: any;
  onChangeFavorite: any;
  onShowGroups: any;
  loadMoreSystemGroups: any;
  loadMoreCustomGroups: any;
  requestFavoriteGroups: any;
  requestSystemGroups: any;
  requestCustomGroups: any;
  pagination: any;
  groupsToShow: any;
}

function GroupList({
  favoriteGroups,
  systemGroups,
  customGroups,
  selectedGroup,
  onSelect,
  onRemove,
  onUpdate,
  onClone,
  onChangeFavorite,
  onShowGroups,
  user,
  loadMoreSystemGroups,
  loadMoreCustomGroups,
  requestFavoriteGroups,
  requestSystemGroups,
  requestCustomGroups,
  pagination,
  groupsToShow,
}: Props): JSX.Element {
  const { isLoading: isLoadingFavorites } = useLoadItems(requestFavoriteGroups, null, pagination.query);
  const { isLoading: isLoadingSystem } = useLoadItems(requestSystemGroups, pagination.systemGroups.page, pagination.query);
  const { isLoading: isLoadingCustom } = useLoadItems(requestCustomGroups, pagination.customGroups.page, pagination.query);
  const hasMoreSystemGroups = !isLoadingSystem && pagination.systemGroups.hasMore;
  const hasMoreCustomGroups = !isLoadingCustom && pagination.customGroups.hasMore;

  function handleClick(e: any, titleProps: any) {
    const { index } = titleProps;
    const newGroupsToShow: any = {};
    newGroupsToShow[index] = !groupsToShow[index];
    onShowGroups(newGroupsToShow);
  }

  function getGroupItem(group: any) {
    return (
      <GroupItem
        onUpdate={onUpdate}
        onClone={onClone}
        onRemove={onRemove}
        onChangeFavorite={onChangeFavorite}
        onClick={onSelect}
        key={group._id}
        group={group}
        isSelected={selectedGroup._id === group._id}
      />
    );
  }

  return (
    <div className={styles.groupListCmpt}>
      <PersonalGroup user={user} isSelected={!selectedGroup._id} onClick={() => onSelect({})} />
      {favoriteGroups.length > 0 &&
        <Accordion className={styles.accordion} >
          <AccordionTitle
            active={groupsToShow.favorites}
            index={'favorites'}
            onClick={handleClick}
          >
            <Icon name='dropdown' className={styles.accordionIcon} />
            <span className={styles.accordionText} >Favorite Groups</span>
          </AccordionTitle>
          <AccordionContent active={groupsToShow.favorites}>
            {favoriteGroups.map((group: any) => getGroupItem(group))}
            {isLoadingFavorites && (
              <div className={favoriteGroups.length ? styles.loader : styles.emptyLoader}>
                <Loader active inline="centered" size={favoriteGroups.length ? 'small' : 'medium'} />
              </div>
            )}
          </AccordionContent>
        </Accordion>
      }

      <Accordion className={styles.accordion} >
        <AccordionTitle
          active={groupsToShow.system}
          index={GROUP_TYPE.SYSTEM}
          onClick={handleClick}
        >
          <Icon name='dropdown' className={styles.accordionIcon} />
          <span className={styles.accordionText} >System Groups</span>
        </AccordionTitle>
        <AccordionContent active={groupsToShow.system}>
          {systemGroups.map((group: any) => getGroupItem(group))}
          {hasMoreSystemGroups && (
            <div className="loadMore">
              <Button basic onClick={loadMoreSystemGroups}>LOAD MORE</Button>
            </div>
          )}
          {isLoadingSystem && (
            <div className={systemGroups.length ? styles.loader : styles.emptyLoader}>
              <Loader active inline="centered" size={systemGroups.length ? 'small' : 'medium'} />
            </div>
          )}
        </AccordionContent>

        <AccordionTitle
          active={groupsToShow.custom}
          index={GROUP_TYPE.CUSTOM}
          onClick={handleClick}
        >
          <Icon name='dropdown' className={styles.accordionIcon} />
          <span className={styles.accordionText} >Custom Groups</span>
        </AccordionTitle>
        <AccordionContent active={groupsToShow.custom}>
          {customGroups?.map((group: any) => getGroupItem(group))}
          {hasMoreCustomGroups && (
            <div className="loadMore">
              <Button basic onClick={loadMoreCustomGroups}>LOAD MORE</Button>
            </div>
          )}
          {isLoadingCustom && (
            <div className={customGroups?.length ? styles.loader : styles.emptyLoader}>
              <Loader active inline="centered" size={customGroups?.length ? 'small' : 'medium'} />
            </div>
          )}
        </AccordionContent>
      </Accordion>
    </div>
  );
}

GroupList.defaultProps = {
  groups: [],
  selectedGroup: {},
};

export default GroupList;
