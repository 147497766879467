import React from 'react';
import { Button, Accordion, AccordionTitle, AccordionContent, Icon, Image } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { Group, PersonalGroup } from '../Group';
import ErrorMessage from '../ErrorMessage';

import style from './GroupList.module.scss';

import logoPT from 'assets/images/logoPT.png';
import Loader from 'components/Loader';
import { isPersonalGroupSelected } from 'mobile/utils';
import useLoadItems from 'hooks/useLoadItems';
import { GROUP_TYPE } from 'utils/constants';

type GroupListProps = {
  user: any,
  selectedGroup: any,
  favoriteGroups: any[];
  systemGroups: any[];
  customGroups?: any[];
  onClickPersonalGroup: () => void,
  onClick: (newSelectedGroup: any) => void,
  onEditGroup: (editGroup: any) => void,
  onCloneGroup: (editGroup: any) => void,
  onRemoveGroup: (removeGroup: any) => void,
  onChangeFavorite: (group: any) => void,
  onShowGroups: any;
  loadMoreSystemGroups: any;
  loadMoreCustomGroups: any;
  requestFavoriteGroups: any;
  requestSystemGroups: any;
  requestCustomGroups: any;
  pagination: any;
  groupsToShow: any;
};

export default function GroupList({
  user,
  selectedGroup,
  onClickPersonalGroup,
  favoriteGroups,
  systemGroups,
  customGroups,
  onClick,
  onEditGroup,
  onCloneGroup,
  onRemoveGroup,
  onChangeFavorite,
  onShowGroups,
  loadMoreSystemGroups,
  loadMoreCustomGroups,
  requestFavoriteGroups,
  requestSystemGroups,
  requestCustomGroups,
  pagination,
  groupsToShow,
}: GroupListProps): JSX.Element {
  const { isLoading: isLoadingFavorites, error: isErrorFavorites } = useLoadItems(requestFavoriteGroups, null, pagination.query);
  const { isLoading: isLoadingSystem, error: isErrorSystem } = useLoadItems(requestSystemGroups, pagination.systemGroups.page, pagination.query);
  const { isLoading: isLoadingCustom, error: isErrorCustom } = useLoadItems(requestCustomGroups, pagination.customGroups.page, pagination.query);
  const hasMoreSystemGroups = !isLoadingSystem && pagination.systemGroups.hasMore;
  const hasMoreCustomGroups = !isLoadingCustom && pagination.customGroups.hasMore;

  function handleClick(e: any, titleProps: any) {
    const { index } = titleProps;
    const newGroupsToShow: any = {};
    newGroupsToShow[index] = !groupsToShow[index];
    onShowGroups(newGroupsToShow);
  }

  function getGroupItem(group: any) {
    return (
      <Group
        key={group._id}
        selected={group._id === selectedGroup?._id}
        group={group}
        onClick={onClick}
        onCloneGroup={onCloneGroup}
        onEditGroup={onEditGroup}
        onRemoveGroup={onRemoveGroup}
        onChangeFavorite={onChangeFavorite}
      />
    );
  }

  return (
    <div className={style.container}>
      <div className={style.listContainer}>
        {!isEmpty(user) && (
          <PersonalGroup
            user={user}
            onClick={onClickPersonalGroup}
            selected={isPersonalGroupSelected(selectedGroup)}
          />
        )}
        {favoriteGroups.length > 0 &&
          <Accordion className={style.accordion} >
            <AccordionTitle
              active={groupsToShow.favorites}
              index={'favorites'}
              onClick={handleClick}
            >
              <Icon name='dropdown' className={style.accordionIcon} />
              <span className={style.accordionText} >Favorite Groups</span>
            </AccordionTitle>
            <AccordionContent active={groupsToShow.favorites}>
              {favoriteGroups.map((group: any) => getGroupItem(group))}
              {isLoadingFavorites && (
                <div className={style.loader}><Loader active inline="centered" size="medium" /></div>
              )}
              {isErrorFavorites && (
                <ErrorMessage title="There is a problem loading the groups." content="Please call system administrator" />
              )}
            </AccordionContent>
          </Accordion>
        }

        <Accordion className={style.accordion} >
          <AccordionTitle
            active={groupsToShow.system}
            index={GROUP_TYPE.SYSTEM}
            onClick={handleClick}
          >
            <Icon name='dropdown' className={style.accordionIcon} />
            <span className={style.accordionText} >System Groups</span>
          </AccordionTitle>
          <AccordionContent active={groupsToShow.system}>
            {systemGroups.map((group: any) => getGroupItem(group))}
            {hasMoreSystemGroups && (
              <div className="loadMore">
                <Button basic onClick={loadMoreSystemGroups}>LOAD MORE</Button>
              </div>
            )}
            {isLoadingSystem && (
              <div className={style.loader}><Loader active inline="centered" size="medium" /></div>
            )}
            {isErrorSystem && (
              <ErrorMessage title="There is a problem loading the groups." content="Please call system administrator" />
            )}
          </AccordionContent>

          <AccordionTitle
            active={groupsToShow.custom}
            index={GROUP_TYPE.CUSTOM}
            onClick={handleClick}
          >
            <Icon name='dropdown' className={style.accordionIcon} />
            <span className={style.accordionText} >Custom Groups</span>
          </AccordionTitle>
          <AccordionContent active={groupsToShow.custom}>
            {customGroups?.map((group: any) => getGroupItem(group))}
            {hasMoreCustomGroups && (
              <div className="loadMore">
                <Button basic onClick={loadMoreCustomGroups}>LOAD MORE</Button>
              </div>
            )}
            {isLoadingCustom && (
              <div className={style.loader}><Loader active inline="centered" size="medium" /></div>
            )}
            {isErrorCustom && (
              <ErrorMessage title="There is a problem loading the groups." content="Please call system administrator" />
            )}
          </AccordionContent>
        </Accordion>
      </div>
      <div className={style.logo}>
        <Image src={logoPT} alt="logo" />
      </div>
    </div>
  );
}
