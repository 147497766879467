import React, { useCallback, useState } from 'react';
import { CgLoadbar } from 'react-icons/cg';
import { FiSearch } from 'react-icons/fi';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import { Icon, Search } from 'semantic-ui-react';
import { debounce } from 'lodash';

import style from './Header.module.scss';

import { GroupActions, SingleGroupActions } from 'layout/Sidebar/Group/GroupActions';
import { SIDEBAR_CONTENT } from 'utils/constants';
import { isPersonalGroupSelected } from 'mobile/utils';

type HeaderTitleProps = {
  user: any,
  content: string,
  selectedGroup: any,
  onCreateGroup: () => void,
  onChangeFavorite: () => void,
  onCloneGroup: () => void,
  onEditGroup: () => void,
  onRemoveGroup: () => void,
  onOpenSearchBar: () => void,
  onBackButtonClick: () => void,
};

function HeaderTitle({
  user, content, selectedGroup,
  onCreateGroup, onEditGroup, onCloneGroup, onRemoveGroup,
  onChangeFavorite, onOpenSearchBar, onBackButtonClick,
}: HeaderTitleProps): JSX.Element {
  return (
    <>
      {
        content === SIDEBAR_CONTENT.USERS && (
          <MdChevronLeft className={style.goBackIcon} size={35} onClick={onBackButtonClick} />
        )
      }
      {
        content === SIDEBAR_CONTENT.USERS ? (
          isPersonalGroupSelected(selectedGroup) ? (
            <span title="Groups" className={`autoDots ${style.groupTitle}`}>{user.name}</span>
          ) : (
            <span title={selectedGroup?.name} className={`autoDots ${style.groupNameTitle}`}>
              {selectedGroup?.name}
            </span>
          )
        ) : (
          <span title="Groups" className={`autoDots ${style.groupTitle}`}>Groups</span>
        )
      }
      {
        content === SIDEBAR_CONTENT.USERS && (
          <SingleGroupActions
            onChangeFavorite={onChangeFavorite}
            favoriteButtonMessage={selectedGroup?.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
            icon={<Icon name="ellipsis vertical" className={style.groupActionIcon} />}
            className={style.groupAction}
            groupType={selectedGroup?.type}
            groupOwners={selectedGroup?.ownerIds}
            onEdit={onEditGroup}
            onClone={onCloneGroup}
            onRemove={onRemoveGroup}
            direction="left"
          />
        )
      }
      {
        content === SIDEBAR_CONTENT.GROUP && !isPersonalGroupSelected(selectedGroup) && (
          <GroupActions
            icon={<Icon name="ellipsis vertical" className={style.groupActionIcon} />}
            className={style.groupAction}
            onAdd={onCreateGroup}
          />
        )
      }
      <FiSearch className={style.searchIcon} onClick={onOpenSearchBar} size={18} />
    </>
  );
}

type SearchBarProps = {
  onChangeSearchInput: (newSearchInput: string) => void,
  onCloseSearchBar: () => void,
};

function SearchBar({ onChangeSearchInput, onCloseSearchBar } : SearchBarProps): JSX.Element {
  const [value, setValue] = useState('');

  const debouncedUpdate = useCallback(
    debounce((val) => onChangeSearchInput(val), 600), [onChangeSearchInput]);

  function onChangeSearch({ target }: any) {
    setValue(target.value);
    debouncedUpdate(target.value);
  }

  return (
    <Search
      open={false}
      value={value}
      autoFocus
      className={style.searchText}
      placeholder="Search ..."
      icon={<MdClose className={style.closeIcon} size={20} onClick={onCloseSearchBar} />}
      onInput={onChangeSearch}
    />
  );
}

type HeaderProps = {
  content: string,
  user: any,
  showToggleIcon: boolean,
  selectedGroup?: any,
  onCreateGroup: () => void,
  onEditGroup: (group: any) => void,
  onCloneGroup: (group: any) => void,
  onChangeFavorite: (group: any) => void,
  onRemoveGroup: (group: any) => void,
  onToggleContent?: () => void,
  onBackButtonClick: () => void,
  onSearchQueryChanged: (newSearchQuery: string) => void,
  onSearchQueryCanceled: () => void,
  searchBarIsActive: boolean,
  setSearchBarIsActive: any,
}

export default function Header({
  content, user, showToggleIcon, selectedGroup,
  onToggleContent, onBackButtonClick, onCreateGroup, onEditGroup,
  onChangeFavorite, onCloneGroup, onRemoveGroup,
  onSearchQueryChanged, onSearchQueryCanceled, searchBarIsActive, setSearchBarIsActive
}: HeaderProps) {
  function onCloseSearchBar() {
    setSearchBarIsActive(false);
    onSearchQueryCanceled();
  }

  return (
    <>
      <div className={style.draggableElm}>
        {
          showToggleIcon && (
            <CgLoadbar size={20} onClick={() => onToggleContent && onToggleContent()} />
          )
        }
      </div>
      <div className={style.headerTitle}>
        {
          searchBarIsActive ? (
            <SearchBar
              onChangeSearchInput={onSearchQueryChanged}
              onCloseSearchBar={onCloseSearchBar}
            />
          ) : (
            <HeaderTitle
              user={user}
              content={content}
              selectedGroup={selectedGroup}
              onCreateGroup={() => onCreateGroup()}
              onEditGroup={() => onEditGroup(selectedGroup)}
              onChangeFavorite={() => onChangeFavorite(selectedGroup)}
              onCloneGroup={() => onCloneGroup(selectedGroup)}
              onRemoveGroup={() => onRemoveGroup(selectedGroup)}
              onOpenSearchBar={() => setSearchBarIsActive(true)}
              onBackButtonClick={onBackButtonClick}
            />
          )
        }
      </div>
    </>
  );
}

Header.defaultProps = {
  selectedGroup: null,
  onToggleContent: null,
};
