import { useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import dayjs from 'dayjs';

import { useAttendanceContext } from 'contexts';
import { DAY_SECONDS } from 'utils/constants';
import { TimeSheetDailyReport, TimeSheetReport } from 'pages/Timesheet/organisms/TimesheetReport';

function getPDFName({ name, startWeek, endWeek }: any) {
  return `${name}_${dayjs(startWeek).format('MM-DD-YYYY')} ${dayjs(endWeek).format('MM-DD-YYYY')}`;
}

function getPDFDailyName({ name, startWeek }: any) {
  return `${name}_${dayjs(startWeek).format('MM-DD-YYYY')}`;
}

function checkIsDailyReport(startWeek: string, endWeek: string) {
  const diff = dayjs(endWeek).diff(dayjs(startWeek));
  return diff / 1000 === DAY_SECONDS - 1;
}

export function usePDF(onComplete?: () => void) {
  const [state] = useAttendanceContext();
  const { userTimesheet } = state;

  useEffect(() => {
    if (userTimesheet) {
      const isDailyReport = checkIsDailyReport(userTimesheet.startWeek, userTimesheet.endWeek);
      let reportDocument = null;
      let pdfName = '';
      if (isDailyReport) {
        reportDocument = TimeSheetDailyReport({
          name: userTimesheet.name,
          actitities: userTimesheet.weekActivities,
          date: userTimesheet.startWeek,
          email: userTimesheet.email,
          totalAct: userTimesheet.totalActWeek,
        });
        pdfName = getPDFDailyName(userTimesheet);
      } else {
        reportDocument = TimeSheetReport(userTimesheet);
        pdfName = getPDFName(userTimesheet);
      }
      pdf(reportDocument)
        .toBlob()
        .then((pdfDoc) => {
          saveAs(pdfDoc, pdfName);
          if (onComplete) {
            onComplete();
          }
        });
    }
  }, [userTimesheet, onComplete]);
}
