import React from 'react';
import { Message } from 'semantic-ui-react';

import style from './Error.module.css';

import { ErrorProps } from 'types';

type Props = {
  errorProps: ErrorProps
}

export default function Error(props: Props): JSX.Element {
  const { errorProps: { title, message } } = props;
  return (
    <Message negative className={style.contentError}>
      <Message.Header>{title}</Message.Header>
      <p>{message}</p>
    </Message>
  );
}
