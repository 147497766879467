import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Button } from 'semantic-ui-react';

import ActivityCard from '../ActivityCard';

import Strings from './Strings';
import style from './ActivityList.module.scss';

import { CATEGORIES } from 'utils/constants';

interface ActivityListComponent {
  items: Array<any>,
  onAddClick: any,
  onEditClick: (activity: any) => void,
  onDeleteClick: (activity: string, userid: string, callback: any) => void,
  canPerformOperations: boolean,
}

function formatActivity({
  _id,
  userId,
  category,
  comment,
  project,
  updatedAt,
  createdAt,
  time,
  authorName,
  authorPicture,
}: any) {
  const edited = createdAt !== updatedAt;
  const formattedDate = dayjs(updatedAt).format('MMM D - hh:mm a');

  return {
    id: _id,
    userId,
    authorName,
    category: CATEGORIES[category] || category,
    comment,
    project,
    date: edited ? Strings.edited(formattedDate) : Strings.created(formattedDate),
    time,
    authorPicture: authorPicture || null,
  };
}

export default function ActivityList({
  onAddClick,
  onEditClick,
  onDeleteClick,
  items,
  canPerformOperations,
}: ActivityListComponent): JSX.Element {
  const [inProgress, setInProgress] = useState<any>([]);

  function initDelete(id: string, userId: string) {
    setInProgress([...inProgress, id]);
    onDeleteClick(id, userId, () => setInProgress(inProgress.filter((item: string) => item !== id)));
  }

  return (
    <div className={style.container}>
      <div className={style.body}>
        <ul className={style['activities-list']}>
          {items && items.length > 0 ? items.map(formatActivity).map((item: any) => (
            <li key={item.id}>
              <ActivityCard
                {...item}
                inProgress={inProgress.includes(item.id)}
                onEdit={onEditClick}
                onDelete={initDelete}
                isUserManager={canPerformOperations}
              />
            </li>
          )) : <li className={style['activities-list-empty-item']}>No records have been recorded</li>}
        </ul>
        {canPerformOperations && <Button className={style.addRecordButton} onClick={onAddClick}>
          {!items?.length ? 'ADD RECORD' : 'NEW RECORD'}
        </Button>}
      </div>
    </div>
  );
}
