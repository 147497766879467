import React from 'react';

import style from './NotFound.module.scss';

export default function NotFound() : JSX.Element {
  return (
    <div className={style.pageNotFoundcontainer}>
      <i className="massive frown icon" />
      <div className={style.message}><h3>Page Not Found</h3></div>
    </div>
  );
}
