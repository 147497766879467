const getPosition = () => {
  if (navigator.geolocation) {
    return new Promise(
      (resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)
    );
  }
  return new Promise(
    resolve => resolve({})
  );
}

export const getCurrentPosition = async () => {
  try {
    const { coords: { latitude, longitude } }: any = await getPosition();
    return latitude && longitude ? {
      type: 'Point',
      coordinates: [longitude, latitude],
    } : null;
  } catch (error) {
    console.log('Error location:', error);
    return null;
  }
};
