import { noop } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import GroupForm from '../GroupForm';
import Modal from '../Modal';
import Loader from '../Loader';

import style from './GroupModal.module.scss';

import { useGlobalContext } from 'contexts';
import { GroupService } from 'service';
import { MODAL_MODE } from 'utils/constants';
import { buildSavedGroup } from 'utils/groupHandler';

const groupService = new GroupService();

const MODAL_TITLE: any = {
  [MODAL_MODE.CREATE]: 'Create a Group',
  [MODAL_MODE.UPDATE]: 'Edit Group',
  [MODAL_MODE.REMOVE]: 'Remove Group',
};

type Props = {
  opened: boolean;
  groupId: string;
  onComplete: (mode: string, response?: any, currentUser?: any) => void;
  mode: string;
  onClose?: any;
  className?: string,
};

export function GroupModal({ opened, groupId, onComplete, mode, onClose, className }: Props): JSX.Element {
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [globalState] = useGlobalContext();
  const { user } = globalState;

  const fetchGroupData = useCallback(async () => {
    setLoading(true);
    try {
      const getByIdRequestOptions = await groupService.getById(groupId);
      const response = await getByIdRequestOptions.toAxios();
      setGroup(response.data?.data);
    } catch (err) {
      toast.error('error');
    } finally {
      setLoading(false);
    }
  }, [groupId]);

  useEffect(() => {
    if (groupId && mode === MODAL_MODE.UPDATE) {
      fetchGroupData();
    }
  }, [groupId, mode, fetchGroupData]);

  const errorHandling = (error: any) => {
    if (error.response && error.response.data && error.response.data.error) {
      const { message, details } = error.response.data.error;
      if (details) {
        setErrorMessage({ header: message, content: details.map((detail: any) => detail.message).join('\n') });
      } else {
        setErrorMessage({ header: 'Server Error', content: message });
      }
    } else {
      setErrorMessage({ header: error.message });
    }
  };

  const handleOnSave = async (newGroupData: any, addMembers: Array<any>, removeMembers: Array<any>) => {
    setErrorMessage(null);
    try {
      let groupRequest = null;
      let message = '';
      if (mode === MODAL_MODE.CREATE) {
        groupRequest = await groupService.createGroup({ ...newGroupData, addMembers });
        message = 'Group was created successfully';
      } else if (mode === MODAL_MODE.UPDATE) {
        groupRequest = await groupService.updateGroup(groupId, { ...newGroupData, addMembers, removeMembers });
        message = 'Group was updated successfully';
      }
      if (groupRequest) {
        const response = await groupRequest.toAxios();
        const groupSaved = buildSavedGroup(response);
        message && toast.success(message)
        onComplete(mode, groupSaved, user);
      }
    } catch (err) {
      errorHandling(err);
    }
  };

  const handleOnClose = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    setErrorMessage(null);
    onClose();
  };

  const handleOnRemove = async () => {
    try {
      const groupRemoveRequest = await groupService.removeGroup(groupId);
      const response = await groupRemoveRequest.toAxios();
      toast.success("Group was removed successfully")
      onComplete(mode, response.data?.data?._id);
    } catch (err) {
      toast.error('Error to remove group');
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={handleOnClose}
      title={MODAL_TITLE[mode]}
      scrollable={false}
      className={className}
    >
      {loading ? (
        <div className={style.modalLoading}>
          <Loader active inline="centered" size="medium" />
        </div>
      ) : mode === MODAL_MODE.CREATE ? (
        <GroupForm onSave={handleOnSave} errorMessage={errorMessage} />
      ) : mode === MODAL_MODE.UPDATE ? (
        <GroupForm group={group} onSave={handleOnSave} errorMessage={errorMessage} />
      ) : mode === MODAL_MODE.REMOVE ? (
        <div>
          <div>
            <span>This action will delete the group from the application. Do you want to continue ?</span>
          </div>
          <div className={style.buttonContainer}>
            <Button as="div" className={style.button} onClick={handleOnRemove}>
              Yes
            </Button>
            <Button as="div" secondary onClick={handleOnClose}>
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}

GroupModal.defaultProps = {
  onClose: noop,
  className: '',
};
