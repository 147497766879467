import React from 'react';
import { Image } from 'semantic-ui-react';

import style from './Avatar.module.scss';

type AvatarProps = {
  src: string,
  name: string,
  size: number,
  isCheckIn: boolean
};

export function Avatar ({
  src,
  name = '?',
  size = 40,
  isCheckIn = false
}: AvatarProps): JSX.Element {
  if (src) {
    const imageProps = {
      src,
      className: `${isCheckIn ? style.checkInAvatar : style.checkOutAvatar}`,
      style: { width: `${size}px`, height: `${size}px` }
    };
    return <Image {...imageProps} />;
  }
  const radius = (size - 3) / 2;
  const fontSize = size * 0.4;
  return (
    <svg height={size} width={size}>
      <circle cx='50%' cy='50%' r={radius} className={isCheckIn ? style.checkInCircleAvatar : style.checkOutCircleAvatar} />
      <text
        x='50%'
        y='50%'
        fontSize={fontSize}
        className={style.textAvatar}
      >
        {getInitials(name)}
      </text>
    </svg>
  );
};

type AvatarItemProps = {
  src: string,
  name: string
};

export function AvatarItem ({
  src,
  name = '?'
}: AvatarItemProps): JSX.Element {
  if (src) {
    return <Image src={src} className={style.avatarItem} />;
  }
  return (
    <svg className={style.avatarItem} >
      <circle cx='50%' cy='50%' className={style.circleAvatarItem} />
      <text
        x='50%'
        y='50%'
        className={style.textAvatar}
      >
        {getInitials(name)}
      </text>
    </svg>
  );
};

function getInitials(name: string): string {
  return name.split(/\s+/).slice(0, 2).reduce((initials, str) => {
    return initials + str.slice(0, 1);
  }, '').toUpperCase();
}
