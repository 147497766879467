const idRE = /^[0-9a-fA-F]{24}$/;
export const isId = (id: string) => idRE.test(id);

// eslint-disable-next-line max-len
const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmail = (email: string) => emailRE.test(email);

const uuidRE = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
export const isUUID = (uuid: string) => uuidRE.test(uuid);

export const optionExists = (options: Array<any>, key: string) => (options.some((option: any) => option.key === key));
