import React, { useState } from 'react';
import { noop } from 'lodash';
import { Icon, Popup } from 'semantic-ui-react';
import { MdGroup, MdPerson, MdPublic } from 'react-icons/md';
import { BiAtom } from 'react-icons/bi';

import { SingleGroupActions } from '../GroupActions';

import style from './GroupItem.module.scss';

import { GROUP_TYPE, MODAL_MODE } from 'utils/constants';
import { GroupModal } from 'components/GroupModal';

interface Props {
  group?: any;
  isSelected?: boolean;
  onClick?: any;
  onRemove: any;
  onUpdate: any;
  onClone: any;
  outerRef?: any;
  onChangeFavorite: any;
}

function GroupItem({ group, isSelected, onChangeFavorite, onClick, onRemove, onUpdate, onClone, outerRef }: Props) {
  const initialModalState = { mode: '', groupId: '', isOpen: false };
  const [modalState, setModalState] = useState(initialModalState);

  const updateModalState = { mode: MODAL_MODE.UPDATE, groupId: group._id, isOpen: true };
  const removeModalState = { mode: MODAL_MODE.REMOVE, groupId: group._id, isOpen: true };
  const selected = isSelected ? `${style.groupItemCmpt} ${style.activeGroup}` : style.groupItemCmpt;
  const attrs = {
    className: selected,
    'aria-hidden': true,
    onClick: () => onClick(group),
  };

  function handleComplete(mode: string, data: any, owner: any) {
    const callbacks: any = {
      update: () => onUpdate(data, owner),
      remove: () => onRemove(data),
    };
    callbacks[mode]();
    setModalState(initialModalState);
  }

  function getIcon(type: string) {
    const props = { className: style.groupsIcon, size: 19 };
    const groupType: any = {
      private: <MdPerson {...props} />,
      custom: <MdGroup {...props} />,
      system: <BiAtom {...props} />,
      public: <MdPublic {...props} />,
    };

    return groupType[type] || groupType.public;
  }
  function getFavoriteIcon(isFavorite: string) {
    return isFavorite ? <Icon name="star" className={style.favoriteGroupIcon} /> : '';
  }
  function getOwnerNames() {
    const size = 40;
    const ownerNames = group?.ownerNames?.join(', ') || '';
    return ownerNames.length > size ? ownerNames.slice(0, size - 1) + " …" : ownerNames;
  }
  return (
    <div {...attrs} ref={outerRef}>
      <GroupModal
        opened={modalState.isOpen}
        mode={modalState.mode}
        groupId={group?._id}
        onComplete={handleComplete}
        onClose={() => setModalState(initialModalState)}
      />
      <div className={`autoDots ${style.groupText}`}>
        {getIcon(group.type)}
        <Popup
          content={
            <div className={style.hoverGroup}>
              <p>{group.name}</p>
              { group.type === GROUP_TYPE.CUSTOM && <p className={style.hoverGroupName}>Owned by {getOwnerNames()}</p> }
            </div>
          }
          trigger={<span className={style.groupTextName}>{group.name}</span>}
        />
      </div>
      {getFavoriteIcon(group?.isFavorite)}
      <SingleGroupActions
        onChangeFavorite={() => onChangeFavorite(group)}
        favoriteButtonMessage={group?.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        icon={<Icon name="ellipsis vertical" className={style.singleGroupActions} />}
        groupOwners={group?.ownerIds || []}
        groupType={group?.type}
        className={style.singleGroupActions}
        direction="left"
        onEdit={() => setModalState(updateModalState)}
        onClone={() => onClone(group)}
        onRemove={() => setModalState(removeModalState)}
      />
    </div>
  );
}

GroupItem.defaultProps = {
  group: {},
  isSelected: false,
  onClick: noop,
  outerRef: null,
};

export default GroupItem;
