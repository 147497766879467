function filterUsers(groupUsers: Array<any>, filterPick: string) {
  return !filterPick.length ? groupUsers : groupUsers.filter((user: any) => user.status === filterPick);
}

function filterGroups(groups: Array<any>, query: string, groupTypesToShow: Array<string>) {
  return groups.filter((group: any) => (
    groupTypesToShow.some((type: string) => type === group.type) || group.isFavorite
  ));
}

function getUsersLengthByStatus(groupUsers: Array<any>, query: string) {
  const userSizes = groupUsers.filter((user: any) => {
    const criteria = user.name || user.email || '';
    return criteria.toLowerCase().includes(query.toLowerCase());
  });

  const { checkIn, checkOut } = userSizes.reduce((result: any, user: any) => {
    const { status } = user;
    result[status] = typeof result[status] !== 'number' ? 0 : result[status];
    result[status] = result[status] + 1;
    return result;
  }, {});

  const newChecIn = checkIn || 0;
  const newcheckOut = checkOut || 0;

  return { checkIn: newChecIn, checkOut: newcheckOut, all: newChecIn + newcheckOut };
}

export { filterUsers, filterGroups, getUsersLengthByStatus };
