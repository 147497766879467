import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  content: {
    flexDirection: 'column',
    paddingBottom: '40px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
});
