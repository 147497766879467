import { UserManager, Log } from 'oidc-client';
import _ from 'lodash';
import { BroadcastChannel } from 'broadcast-channel';

import { settings } from '../config/settings';
import { getUserToken } from 'utils/userToken';

const logoutChannel = new BroadcastChannel('logout');

const parseJWT = (token: string) => {
  try {
    const b64payload = token.split('.')[1];
    // Ref: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    const payload = decodeURIComponent(escape(window.atob(b64payload)));
    return JSON.parse(payload);
  } catch (err) {
    return null;
  }
};

export default class AuthService {
  userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(settings());
    // Logger
    Log.logger = console;
    Log.level = Log.WARN;
    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(async () => {
      console.log('token expired');
      await this.signinSilent();
    });
  }

  signinRedirectCallback = async () => {
    try {
      await this.userManager.signinRedirectCallback();
    } catch (err) {
      console.log(err);
    }
  };

  getUser = async () => {
    const user = await this.userManager.getUser();
    if (!user) {
      return this.userManager.signinRedirectCallback();
    }
    return user;
  };

  signinRedirect = async () => {
    try {
      await this.userManager.signinRedirect({});
    } catch (err) {
      console.log(err);
    }
  };

  isAuthenticated = (): boolean => {
    const oidcStorage = getUserToken();
    return (!!oidcStorage && !!oidcStorage.access_token);
  };

  signinSilent = async () => {
    try {
      await this.userManager.signinSilent();
    } catch (err) {
      console.log(err);
    }
  };

  logout = async () => {
    try {
      await this.userManager.signoutRedirect();
      logoutChannel.postMessage("Logout");
    } catch (err) {
      console.log(err);
    }
  };

  logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      setTimeout(async () => {
        await this.userManager.signoutRedirect();
      }, 5000);
      logoutChannel.close();
    };
  }

  isAdmin = async () => {
    // TODO: Fix the issue ralted to this.userManager.getUser()
    // does not return resource_access property but exists in the token
    const oidcStorage = getUserToken();
    const profile = parseJWT(oidcStorage.access_token);
    // eslint-disable-next-line camelcase
    let roles = profile?.resource_access?.ponctuel?.roles;
    roles = (roles && _.isArray(roles)) ? roles : [];
    return _.includes(roles, 'administrator');
  };
}
