import React, { useState } from 'react';
import dayjs from 'dayjs';
import { noop } from 'lodash';
import { MdSchedule } from 'react-icons/md';
import { Button, Icon } from 'semantic-ui-react';

import Modal from '../Modal';
import Loader from '../Loader';

import style from './ActivityCard.module.scss';

import { AvatarItem } from 'components/Avatar';

interface ActivityCardComponent {
  id: string,
  userId: string,
  comment: string,
  category: string,
  project: any,
  date: string,
  time: number,
  authorName: string,
  authorPicture: string,
  onEdit?: () => void,
  onDelete?: () => void,
  inProgress?: () => void,
  isUserManager: boolean,
}

const categoryLabel = 'Category';
const projectLabel = 'Project';

export default function ActivityCard(props: ActivityCardComponent): JSX.Element {
  const {
    id,
    userId,
    comment,
    category,
    project,
    date,
    time,
    authorName,
    authorPicture,
    onEdit = noop,
    onDelete = noop,
    inProgress = false,
    isUserManager,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  function deleteItemClick(event: any) {
    if (event) {
      event.stopPropagation();
    }
    setShowDeleteModal(false);
    onDelete(id, userId);
  }
  const handleOnClose = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeleteModal(false);
  };

  const getUserManagerModal = () => {
    if (isUserManager) {
      return (
        <>
          <Modal
            opened={showDeleteModal}
            onClose={handleOnClose}
            title='Remove activity'
            scrollable={false}
          >
            <div>
              <div>
                <span>This action will delete the activity permanently from the application. Do you want to continue?</span>
              </div>
              <div className={style.buttonContainer}>
                <Button as="div" className={style.button} onClick={deleteItemClick}>
                  Yes
                </Button>
                <Button as="div" secondary onClick={handleOnClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          <Button
            title="Edit"
            onClick={() => onEdit({ id, userId, comment, category, date, time, project })}
            icon
            size="mini"
          >
            <Icon name="pencil" />
          </Button>
          <Button 
            title="Delete"
            onClick={() => {
              setShowDeleteModal(true)
            }}
            icon
            size="mini"
          >
            <Icon name="trash" />
          </Button>
        </>
      );
    }
    return null;
  }

  return (
    <article>
      <header>
        <div className={style.avatar}>
          <AvatarItem src={authorPicture} name={authorName} />
          <div className={style['avatar-info']}>
            <h3>{authorName}</h3>
            <time>{date}</time>
          </div>
        </div>
        <div>
          {inProgress ? (
            <Loader size="small" active inline="centered" />
          ) : getUserManagerModal()}
        </div>
      </header>
      <div className={style.info}>
        {project && (
          <div className={style.project}>
            <span className={style.icon}>{`${projectLabel}:`}</span>
            <span>{project?.name}</span>
          </div>
        )}
        <div className={style.categoryDuration}>
          <div className={style.category}>
            <span className={style.icon}>{`${categoryLabel}:`}</span>
            <span>{category}</span>
          </div>
          <div className={style.duration}>
            <MdSchedule size={16} className={style.icon} />
            <span>{ time === 1440 ? '24:00' : dayjs.duration(time, 'minutes').format('H:mm')}</span>
          </div>
        </div>
      </div>
      {comment && <p className={style.description}>{comment}</p>}
    </article>
  );
}

ActivityCard.defaultProps = {
  authorPicture: null,
  onEdit: null,
  onDelete: null,
  inProgress: false,
};
