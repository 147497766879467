function createAttendanceArgument(state: any) {
  const { pagination, date, selectedGroup, selectedGroupUser, project } = state;
  const { activePage, limit } = pagination;
  const { startDate, endDate } = date;
  const attendanceArguments = {
    queryOptions: { skip: activePage, limit, orders: ['ASC(name)'] },
    startDate,
    endDate,
    selectedGroup,
    selectedGroupUser,
    projectId: project?.value ?? '',
  };
  return attendanceArguments;
}

export { createAttendanceArgument };
