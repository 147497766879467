import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function dateTimeIndex(dateRange: any[], dateTime: string, tz: string): number {
  // TODO: Add tz before converte dateTime to String
  const dateTimeString = dayjs(dateTime).format('DD/MM/YYYY');
  const dateRangeString = dateRange.map((date: any) => dayjs(date).format('DD/MM/YYYY'));
  const index = dateRangeString.indexOf(dateTimeString);
  return index;
}

export function isSameDayOrBefore(date: any): boolean {
  return dayjs(date).isSameOrBefore(dayjs(), 'day');
}

export function isSameDateOrFutureThan(firstDate: string, secondDate: string) {
  const startDate = dayjs(firstDate);
  const endDate = dayjs(secondDate)

  return startDate.isSameOrAfter(endDate, 'minute');
}
