import React from 'react';
import { List, Button } from 'semantic-ui-react';

import style from './style.module.scss';

import { AvatarItem } from 'components/Avatar';

interface Props {
  email: string;
  picture: string;
  name: string;
  onActionClick: any;
  actionName: string;
}

export default function UserItem({ email, picture, name, onActionClick, actionName }: Props) {
  return (
    <List.Item>
      <List.Content floated='right'>
        <Button size="tiny" onClick={onActionClick}>
          {actionName}
        </Button>
      </List.Content>
      <List.Content floated='left'>
        <AvatarItem src={picture} name={name} />
      </List.Content>
      <List.Content className={style.contentItem}>
        {email}
      </List.Content>
    </List.Item>
  );
}
