import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import style from './Home.module.scss';

import MarkersError from 'components/Marker/MarkersError';
import CustomMap from 'components/CustomMap';
import { eventsWithOutPlace } from 'utils/eventHandler';
import { useDates } from 'hooks/useDates';

import { UsersEvent } from 'types';
import { useAttendanceContext, useGlobalContext } from 'contexts';

export default function Home(): JSX.Element {
  const [attendanceState, attendanceRequest] = useAttendanceContext();
  const { getLastUserEventById, getLastUserEvents } = attendanceRequest;
  const { selectedGroup, selectedGroupUser, lastUsersEvents, error } = attendanceState;

  const { location, zoom } = useParams<any>();
  const { startWeek } = useDates();
  let userEvents: Array<UsersEvent> = [];
  const serviceArguments = {
    queryOptions: {
      fields: ['type', 'location', 'userId', 'place'],
    },
    selectedGroup,
    startWeek,
    selectedUsers: selectedGroupUser,
  };

  if (lastUsersEvents) userEvents = eventsWithOutPlace(lastUsersEvents);

  async function handleOpenMarker(userId: string) {
    return getLastUserEventById(userId);
  }

  const load = useCallback(async () => {
    const result = await getLastUserEvents(serviceArguments);
    if (!result?.length && selectedGroupUser?.length) {
      toast(<span><Icon name="map marker alternate" /> Last location unknown</span>);
    }
  }, [getLastUserEvents, serviceArguments, selectedGroupUser]);

  useEffect(() => {
    load();
  }, [selectedGroup, selectedGroupUser]); // eslint-disable-line

  const propsMap = {
    events: userEvents,
    location,
    zoomParam: zoom,
    onOpen: handleOpenMarker,
  };

  return (
    <div className={style.container}>
      {error && <MarkersError />}
      {lastUsersEvents && <CustomMap {...propsMap} />}
    </div>
  );
}
