import React from 'react';

import Navigation from '../../mobile/layout/Navigation';
import Filter from '../../mobile/layout/Filter';
import Content from '../../mobile/layout/Content';

import style from './Mobile.module.scss';

import { APP_CONTENT } from 'utils/constants';
import Map from 'mobile/layout/Map';

export default function MobilePage({ mode }: any): JSX.Element {
  return (
    <div className={style.container}>
      { mode === APP_CONTENT.HOME && <Map /> }
      <Navigation />
      { mode !== APP_CONTENT.HOME && (<Filter showProjectFilter={mode === APP_CONTENT.TIMESHEET} mode={mode} />) }
      <Content mode={mode} />
    </div>
  );
}
