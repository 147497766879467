import React, { useState } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { DateRangePicker } from 'react-date-range';
import { Button, Popup, Icon as SemancticIcon } from 'semantic-ui-react';
import dayjs, { OpUnitType } from 'dayjs';

import Icon from '../Icon';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DateRange.scss';

export default function DateRange({
  startDate,
  endDate,
  onChange,
  position = 'bottom right',
  mobile = false,
  className = '',
  ...leftProps
}: any) {
  const [selectedRange, setSelectedRange] = useState('days');
  const week = {
    startDate: dayjs().startOf('w').day(1),
    endDate: dayjs().endOf('w').day(7),
  };

  const lastWeek = {
    startDate: dayjs().startOf('w').day(-6),
    endDate: dayjs().endOf('w').day(0),
  };

  const month = {
    startDate: dayjs().startOf('month'),
    endDate: dayjs().endOf('month'),
  };

  const lastMonth = {
    startDate: dayjs().subtract(1, 'month').startOf('month'),
    endDate: dayjs().subtract(1, 'month').endOf('month'),
  };

  const customRanges = [
    {
      label: 'This week',
      hasCustomRendering: false,
      range: () => ({ ...week }),
      isSelected() {
        return true;
      },
    },
    {
      label: 'Last week',
      hasCustomRendering: false,
      range: () => ({ ...lastWeek }),
      isSelected() {
        return true;
      },
    },
    {
      label: 'This month',
      hasCustomRendering: false,
      range: () => ({ ...month, selectedRange: 'month' }),
      isSelected() {
        return true;
      },
    },
    {
      label: 'Last month',
      hasCustomRendering: false,
      range: () => ({ ...lastMonth, selectedRange: 'month' }),
      isSelected() {
        return true;
      },
    },
  ];

  const tigger = mobile ? (
    <Button icon className={className}>
      <Icon.Calendar size={25} className="calendarTrigger" />
    </Button>
  ) : (
    <button type="button" className="dateRangePickerButton">
      {dayjs(startDate).format('ddd, MMMM Do')}
      <MdArrowForward />
      {dayjs(endDate).format('ddd, MMMM Do')}
    </button>
  );

  function handleChange(time: any) {
    const endOfDay = time.endDate instanceof Date ? dayjs(time.endDate).endOf('date') : time.endDate;
    setSelectedRange(time.selectedRange || 'days');
    onChange({ ...time, endDate: endOfDay });
  }

  return (
    <div className='dateRange'>
      <span onClick={() => {
        const diference = selectedRange === 'days' ? dayjs(endDate).diff(startDate, 'days') + 1 : 1;
        const range = selectedRange as OpUnitType;
        handleChange({
          endDate: dayjs(endDate).subtract(diference, range).endOf(range),
          startDate: dayjs(startDate).subtract(diference, range).startOf(range),
          selectedRange
        });
      }}>
        <SemancticIcon name="arrow left" />
      </span>
      <Popup
        on="click"
        flowing
        trigger={tigger}
        position={position}
      >
        <DateRangePicker
          className={mobile ? 'dateRangePickerContent mobile' : 'dateRangePickerContent'}
          onChange={(item: any) => handleChange(item.selection)}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          showMonthAndYearPickers={false}
          rangeColors={['#6DE48E']}
          ranges={[
            {
              startDate: new Date(startDate),
              endDate: new Date(endDate),
              key: 'selection',
            },
          ]}
          direction="vertical"
          weekStartsOn={1}
          staticRanges={customRanges}
          inputRanges={[]}
          {...leftProps}
        />
      </Popup>
      <span onClick={() => {
        const diference = selectedRange === 'days' ? dayjs(endDate).diff(startDate, 'days') + 1 : 1;
        const range = selectedRange as OpUnitType;
        handleChange({
          endDate: dayjs(endDate).add(diference, range).endOf(range),
          startDate: dayjs(startDate).add(diference, range).startOf(range),
          selectedRange
        });
      }}>
        <SemancticIcon name="arrow right" />
      </span>
    </div>
  );
}
