import Clock from './Clock';
import Map from './Map';
import Report from './Report';
import Calendar from './Calendar';

export default {
  Clock,
  Map,
  Report,
  Calendar,
};
