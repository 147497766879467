import React, { createRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import style from './Title.module.scss';

interface Props {
  title: string,
  position?: any,
  offset?: any,
}

export default function Title({ title, position, offset }: Props) {
  const [showPopup, setShowPopup] = useState(false);
  const titleRef = createRef<HTMLParagraphElement>();

  const isEllipsisActive = () => {
    if (titleRef && titleRef.current && titleRef.current.offsetWidth < titleRef.current.scrollWidth) {
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    if (titleRef && titleRef.current && titleRef.current.offsetWidth < titleRef.current.scrollWidth) {
      setShowPopup(false);
    }
  };

  return (
    <section>
      <Popup context={titleRef} open={showPopup} inverted content={title} position={position} offset={offset} />
      <p
        className={style.textWrap}
        ref={titleRef}
        onMouseOver={isEllipsisActive}
        onFocus={isEllipsisActive}
        onMouseOut={closePopup}
        onBlur={closePopup}
      >
        {title}
      </p>
    </section>
  );
}

Title.defaultProps = {
  offset: [15, 0],
  position: 'top center',
};
