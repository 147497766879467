import React from 'react';

import style from './Filter.module.scss';

import ProjectFilter from 'components/Attendance/ProjectFilter';
import DateRange from 'components/DateRange';
import { useAttendanceContext } from 'contexts';
import ExportCSV from 'components/ExportCSV';

type FilterProps = {
  showProjectFilter?: boolean,
  mode: string,
};

export default function Filter({ showProjectFilter, mode }: FilterProps) {
  const [attendanceState, attendanceRequest] = useAttendanceContext();
  const {
    selectedGroup,
    selectedGroupUser,
    project,
    date: { startDate, endDate },
    summaries,
    attendancesList,
    week,
  } = attendanceState;
  const { updateDate, updateProject } = attendanceRequest;

  function onDateRangeChange(date: any) {
    updateDate(date);
  }

  function onProjectChange(newProject: any) {
    updateProject(newProject);
  }

  const exportCSVProps = {
    currentUsers: attendancesList || [],
    usersFound: attendancesList || [],
    daysPerWeek: week,
    searchStatus: false,
    selectedGroup,
    summaries: summaries || [],
  };

  return (
    <div className={style.container}>
      {
        showProjectFilter && (
          <ProjectFilter
            project={project}
            selectedGroupId={selectedGroup?._id ?? ''}
            selectedUserId={selectedGroupUser[0]?._id ?? ''}
            onProjectChange={onProjectChange}
            className={style.projectFilter}
          />
        )
      }
      {
        exportCSVProps && exportCSVProps.currentUsers && (
          <ExportCSV usersData={exportCSVProps} text="Export" className={style.exportButton} contentToShow={mode} />
        )
      }
      <DateRange
        startDate={startDate}
        endDate={endDate}
        onChange={onDateRangeChange}
        mobile
        className={style.dateRange}
      />
    </div>
  );
}

Filter.defaultProps = {
  showProjectFilter: false,
};
