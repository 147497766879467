import { QueryOptions } from '../types';

import { BaseService } from './BaseService';
import { RequestOptions } from './RequestOptions';

export class ProjectService extends BaseService {
  constructor() {
    super('/api/v1/projects');
  }

  async search(query: string, queryOptions: QueryOptions, selectedGroupId?: string): Promise<RequestOptions> {
    return this.options({
      url: this.urlFor(queryOptions, this.path, `?query=${query}&groupId=${selectedGroupId ?? ''}&`),
    });
  }

  async getProjectsNotSelected(projects: any, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/notSelected?`;
    return this.options({ method: 'post', url: this.urlFor(queryOptions, path), data: projects });
  }

  async searchByGroup(query: string, queryOptions: QueryOptions, groupId: string = '', userId: string = ''): Promise<RequestOptions> {
    return this.options({
      url: this.urlFor(queryOptions, `${this.path}/search-by-group`, `?query=${query}&groupId=${groupId}&userId=${userId}&`),
    });
  }
}
