import actions from './actions';

import array from 'utils/array';
import { Action } from 'utils/actionCreator';
import { GROUP_TYPE } from 'utils/constants';

function updateState(state: any, action: any) {
  return { ...state, ...action.payload };
}

function setUserGroups(state: any, action: any) {
  const { groups, meta } = action.payload;
  return { ...state, groups, meta };
}

function changeFavorite(state: any, action: any) {
  const newgroup = { ...action.payload, isFavorite: !action.payload.isFavorite }
  let newFavoriteGroups: any = [];
  let groupsToShow: any = state.groupsToShow;
  if (newgroup.isFavorite) {
    newFavoriteGroups = array.orderBy([...state.favoriteGroups, { ...action.payload, isFavorite: !action.payload.isFavorite }], 'name');
    groupsToShow = { ...state.groupsToShow, favorites: true }
  } else {
    [, newFavoriteGroups ] = array.remove(state.favoriteGroups, (group: any) => group._id === action.payload._id);
  }

  if (newgroup.type === GROUP_TYPE.SYSTEM) {
    const [, newSystemGroups] = array.update(state.systemGroups, (group: any) => group._id === newgroup._id, { ...newgroup });
    return { ...state, favoriteGroups: newFavoriteGroups, systemGroups: newSystemGroups, groupsToShow };
  } else {
    const [, newCustomGroups] = array.update(state.customGroups, (group: any) => group._id === newgroup._id, { ...newgroup });
    return { ...state, favoriteGroups: newFavoriteGroups, customGroups: newCustomGroups, groupsToShow };
  }
}

function addGroup(state: any, action: any) {
  const newCustomGroups = array.add(state.customGroups, action.payload);
  const orderedGroups = array.orderBy(newCustomGroups, 'name');
  return { ...state, customGroups: orderedGroups };
}

function updateGroup(state: any, action: any) {
  const { selectedGroup: newgroup, groupUsers, usersByStatus } = action.payload;
  const [, newCustomGroups] = array.update(state.customGroups, (group: any) => group._id === newgroup._id, { ...newgroup });
  return { ...state, customGroups: newCustomGroups, groupUsers, usersByStatus };
}

function removeGroup(state: any, action: any) {
  const [removedGroup, newCustomGroups]: any = array.remove(state.customGroups, (group: any) => group._id === action.payload);
  if (removedGroup.isFavorite) {
    const [, newFavoriteGroups] = array.remove(state.favoriteGroups, (group: any) => group._id === action.payload);
    return { ...state, favoriteGroups: newFavoriteGroups, customGroups: newCustomGroups };
  }
  return { ...state, customGroups: newCustomGroups };
}

function disableUser(state: any, action: any) {
  const [, newGroups] = array.remove(state.groupUsers, (user: any) => user._id === action.payload);
  let toChange: any = { groupUsers: newGroups };
  if (action.payload === state.selectedGroupUser._id) {
    toChange = { ...toChange, selectedGroupUser: {} };
  }
  return { ...state, ...toChange };
}

function updateUserState(state: any, action: Action) {
  const userEvent = action.payload;
  const { userId, location, type, updatedAt } = userEvent;
  const { groupUsers } = state;
  const [, newGroupUsers] = array.update(
    groupUsers,
    (user: any) => user._id === userId,
    { status: type, location, updatedAt },
  );
  return { ...state, groupUsers: newGroupUsers };
}

export default function eventReducer(state: any, action: any) {
  switch (action.type) {
    case actions.UPDATE_STATE().type:
      return updateState(state, action);
    case actions.ADD_GROUP().type:
      return addGroup(state, action);
    case actions.UPDATE_GROUP().type:
      return updateGroup(state, action);
    case actions.REMOVE_GROUP().type:
      return removeGroup(state, action);
    case actions.DISABLE_USER().type:
      return disableUser(state, action);
    case actions.SET_USER_GROUPS().type:
      return setUserGroups(state, action);
    case actions.CHANGE_FAVORITE().type:
      return changeFavorite(state, action);
    case actions.UPDATE_USER_EVENT_STATE().type:
      return updateUserState(state, action);
    default:
      return state;
  }
}
