import { UserEventsService, UserService } from 'service';
import { QueryOptionsTimesheet } from 'types';
import { request } from 'utils/requester';

const userService = new UserService();
const userEventsService = new UserEventsService();

export async function getUserInformation() {
  const getInformation = userService.me();
  return await request(getInformation);
}

export async function getLastUserEvent(userId: string) {
  const lastEvent = userEventsService.getLastEventByUserId(userId);
  return await request(lastEvent);
}

export async function getLastUsersEvents(argument: any) {
  const lastEvents = userEventsService.getMapEvents(argument);
  return await request(lastEvents);
}

export async function createUserEvent(event: any) {
  const lastEvent = userEventsService.createEvent(event);
  return await request(lastEvent);
}

export async function createManualUserEvent(event: any) {
  const newEvent = userEventsService.createManualEvent(event);
  return await request(newEvent);
}

export async function updateUserPreferences(preference: any) {
  const updatePreference = userService.updateUserPreferences(preference);
  return await request(updatePreference);
}

export async function getTimesheetByUser(userId: string, projectId: string, queryOptions: QueryOptionsTimesheet) {
  const updatePreference = userService.getTimesheetByUser(userId, projectId, queryOptions);
  return await request(updatePreference);
}

export async function disableUser(userId: string) {
  const disable = userService.disableUser(userId);
  return await request(disable);
}

export async function updateUserEvent(
  event: any,
  eventId: string,
  userId: string,
  startDate: string,
  endDate: string,
) {
  const updatedEvent = userEventsService.updateEvent(event, eventId, userId, startDate, endDate);
  return await request(updatedEvent);
}

export async function getUserManager(userId: string) {
  const isUserManager = userService.getUserManager(userId);
  return await request(isUserManager);
}

export async function getUserById(userId: string) {
  const user = userService.getUserById(userId);
  return await request(user);
}
