import { getLastUserEvent, getLastUsersEvents, getTimesheetByUser } from 'axios/UserService';
import { getAttendances, getAllAttendances } from 'axios/Attendance';
import { createAttendanceArgument } from 'utils/argumentsCreator';
import { getDateLabelsByRange } from 'utils/attendance';
import { emit } from 'utils/emitter';
import { EVENT_TYPE } from 'utils/constants';

export default function useRequests(state: any, dispatch: any, actions: any) {
  return () => ({
    updateDate: async (date: any) => dispatch(actions.UPDATE_STATE({ date })),
    updatePagination: (pagination: any) => dispatch(actions.UPDATE_STATE({ pagination })),
    updateProject: (project: any) => dispatch(actions.UPDATE_STATE({ project })),
    cleanUserTimesheet: () => dispatch(actions.UPDATE_STATE({ userTimesheet: null })),
    getLastUserEvents: async (argument: any) => {
      try {
        const { data: lastUsersEvents } = await getLastUsersEvents(argument);
        dispatch(actions.UPDATE_STATE({ lastUsersEvents, error: null }));
        try {
          emit('socket:globalLastUserEvent', { lastUserEvent: lastUsersEvents[0] });
        } catch (error) {
          emit('socket:globalLastUserEvent', { lastUsersEvents: { type: EVENT_TYPE.CHECK_OUT } });
        }
        return lastUsersEvents;
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
        return error;
      }
    },
    getLastUserEventById: async (userId: string) => {
      try {
        const { data: lastUserEvent } = await getLastUserEvent(userId);
        return lastUserEvent;
      } catch (error) {
        return error;
      }
    },
    getAttendances: async () => {
      try {
        const args = createAttendanceArgument(state);
        const { data, ...meta } = await getAttendances(args);
        const week = getDateLabelsByRange(state.date);
        dispatch(actions.UPDATE_STATE({ ...data, meta, week }));
      } catch (error) {
        if (error.message !== 'Operation canceled due to new request.') {
          dispatch(actions.UPDATE_STATE({ error }));
        }
      }
    },
    getUserTimesheet: async (userInfo: any) => {
      const { userId, projectId, ...queryOptions } = userInfo;
      try {
        const { data: userTimesheet } = await getTimesheetByUser(userId, projectId, queryOptions);
        dispatch(actions.UPDATE_STATE({ userTimesheet }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    exportAttendances: async () => {
      try {
        const { date, selectedGroup, selectedGroupUser, project } = state;
        const { startDate, endDate } = date;
        const args = {
          queryOptions: { orders: ['ASC(name)'] },
          startDate,
          endDate,
          selectedGroup,
          selectedGroupUser,
          projectId: project?.value ?? '',
        };
        const data = await getAllAttendances(args);
        return data;
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
      return { data: { attendancesList: [] } };
    },
  });
}
