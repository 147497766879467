import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  content: {
    paddingTop: '25px',
  },
  header: {
    color: '#D20000',
    paddingTop: '4px',
    paddingBottom: '2px',
    fontWeight: 'bold',
    fontFamily: 'HelveticaNeue',
    backgroundColor: '#f1f3f3',
    borderRight: '1pt solid #f1f3f3',
    height: '20px',
    opacity: 0.9,
  },
  firstCellHeader: {
    color: '#D20000',
    paddingTop: '4px',
    paddingBottom: '2px',
    fontWeight: 'bold',
    fontFamily: 'HelveticaNeue',
    backgroundColor: '#f1f3f3',
    borderRight: '1pt solid #f1f3f3',
    height: '20px',
    opacity: 0.9,
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    paddingLeft: '5px',
  },

  lastCellHeader: {
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    alignItems: 'flex-start',
    paddingLeft: '5px',
  },

  cell: {
    opacity: 0.9,
    borderRight: '1pt solid #DBDBDB',
  },

  borderRightNone: {
    borderRight: '1pt solid #ffffff',
  },

  activitiesTable: {
    borderBottom: '1pt solid #DBDBDB',
  },

  paddingLeftCell: {
    paddingLeft: '3px',
  },

  alignRightCell: {
    textAlign: 'right',
    paddingRight: '5px',
  },

  viewCellBackgroundColor: {
    backgroundColor: '#f1f3f3',
    flex: 1,
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  viewCellNormalBackground: {
    flex: 1,
    paddingRight: '5px',
    paddingLeft: '5px',
  },
});
