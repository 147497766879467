import React from 'react';
import { Route } from 'react-router-dom';

import { useGlobalContext } from 'contexts/global';
import { setRedirectPath, cleanRedirectPath } from 'utils/redirect';

import Loading from 'components/Loading';

const PrivateRoute = (propsComponent: any) => {
  const [, request] = useGlobalContext();
  const { isAuthenticated, signinRedirect } = request;
  const { component, ...rest } = propsComponent;
  const renderFn = (Component: any) => (props: any) => {
    const { location: { pathname } } = props;
    setRedirectPath(pathname);
    if (!!Component && isAuthenticated()) {
      cleanRedirectPath();
      return <Component {...props} />;
    }
    signinRedirect();
    return <Loading />;
  };

  return <Route {...rest} render={renderFn(component)} />;
};

export default PrivateRoute;
