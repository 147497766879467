import { QueryOptions } from '../types';

import { BaseService } from './BaseService';

export class WebhookService extends BaseService {
  constructor() {
    super('/api/v1/webhooks');
  }

  async getEnabledHooks(queryOptions: QueryOptions = {}): Promise<any> {
    const path = `${this.path}/channelSettings?`;
    const url = this.urlFor(queryOptions, path);
    const response = this.options({ method: 'GET', url });
    return response;
  }

  async getGroupsByWebhook(id: string, queryOptions: QueryOptions = {}): Promise<any> {
    const path = `${this.path}/${id}/groups`;
    const url = this.urlFor(queryOptions, path);
    const response = this.options({ method: 'GET', url });
    return response;
  }
}
