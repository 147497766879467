import _ from 'lodash';

import { emit } from 'utils/emitter';
import { getUserGroups, getUserFavoriteGroups } from 'axios/GroupService';
import { disableUser } from 'axios/UserService';
import { FILTER_USERS_PICK, GROUP_TYPE, INITIAL_DATA_STATUS_FILTER } from 'utils/constants';
import { GroupService } from 'service';
import { toast } from 'react-toastify';

const groupService = new GroupService();

const GROUP_LIMIT = 20;
const GROUP_USERS_LIMIT = 20;

const usersFiltersBuild = (filters: any) => {
  const { status } = filters;
  return status === FILTER_USERS_PICK.ALL ? null : [`status $eq ${status}`];
};

const groupsFiltersBuild = (filters: any) => {
  const typeFilters = filters.join(',');
  return !filters || !filters.length ? null : [`type $in ${typeFilters}`];
};

export default function useRequests(state: any, dispatch: any, actions: any) {
  return () => ({
    addGroup: (group: any) => dispatch(actions.ADD_GROUP(group)),
    cloneGroup: async (group: any) => {
      const { _id } = group;
      try {
        const groupRequest = await groupService.cloneGroup({ groupId: _id });
        const newGroup = await groupRequest.toAxios();
        dispatch(actions.ADD_GROUP(newGroup?.data.data));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    getUserGroups: async (preferences?: any) => {
      try {
        const filters = groupsFiltersBuild(preferences);
        const { groupPagination, searchQuery } = state;
        const queryOptions = { limit: GROUP_LIMIT, skip: groupPagination.page, search: searchQuery, filters };
        const data = await getUserGroups(null, state.currentDate, queryOptions);
        const groups = data?.data?.groupList || [];
        const newGroups = _.uniqBy([...state.groups, ...groups], '_id');
        const meta = { paginationSetup: data?.paginationSetup, totalPages: data?.totalPages };
        dispatch(actions.SET_USER_GROUPS({ groups: newGroups, meta }));
        const newGroupPagination = { ...groupPagination, hasMore: data?.data?.next, page: data?.data?.page };
        dispatch(actions.UPDATE_STATE({ groupPagination: newGroupPagination }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    getUserFavoriteGroups: async () => {
      try {
        const { searchQuery } = state;
        const queryOptions = { limit: GROUP_LIMIT, search: searchQuery };
        const data = await getUserFavoriteGroups(queryOptions);
        const groups = data?.data?.groupList || [];
        dispatch(actions.UPDATE_STATE({ favoriteGroups: groups }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    getUserCustomGroups: async () => {
      try {
        const { customGroupPagination, searchQuery } = state;
        const queryOptions = {
          limit: GROUP_LIMIT,
          skip: customGroupPagination.page,
          search: searchQuery,
          type: GROUP_TYPE.CUSTOM
        };
        const data = await getUserGroups(null, state.currentDate, queryOptions);
        const groups = data?.data?.groupList || [];
        const newGroups = _.uniqBy([...state.customGroups, ...groups], '_id');
        dispatch(actions.UPDATE_STATE({ customGroups: newGroups }));
        const newGroupPagination = { ...customGroupPagination, hasMore: data?.data?.next, page: data?.data?.page };
        dispatch(actions.UPDATE_STATE({ customGroupPagination: newGroupPagination }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    getUserSystemGroups: async () => {
      try {
        const { systemGroupPagination, searchQuery } = state;
        const queryOptions = {
          limit: GROUP_LIMIT,
          skip: systemGroupPagination.page,
          search: searchQuery,
          type: GROUP_TYPE.SYSTEM
        };
        const data = await getUserGroups(null, state.currentDate, queryOptions);
        const groups = data?.data?.groupList || [];
        const newGroups = _.uniqBy([...state.systemGroups, ...groups], '_id');
        dispatch(actions.UPDATE_STATE({ systemGroups: newGroups }));
        const newGroupPagination = { ...systemGroupPagination, hasMore: data?.data?.next, page: data?.data?.page };
        dispatch(actions.UPDATE_STATE({ systemGroupPagination: newGroupPagination }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    updateGroupPagination: (pagination: any) => dispatch(actions.UPDATE_STATE({
      groupPagination: { ...state.groupPagination, ...pagination } })),
    updateCustomGroupPagination: (pagination: any) => dispatch(actions.UPDATE_STATE({
      customGroupPagination: { ...state.customGroupPagination, ...pagination } })),
    updateSystemGroupPagination: (pagination: any) =>  dispatch(actions.UPDATE_STATE({
      systemGroupPagination: { ...state.systemGroupPagination, ...pagination } })),
    updateUsersPagination: (pagination: any) => dispatch(actions.UPDATE_STATE({
      usersPagination: { ...state.usersPagination, ...pagination } })),
    updateUsersFilter: (filters: any) => dispatch(actions.UPDATE_STATE({
      usersFilters: { ...state.usersFilters, ...filters } })),
    updateGroupsToShow: (groupsToShow: any) => dispatch(actions.UPDATE_STATE({
      groupsToShow: { ...state.groupsToShow, ...groupsToShow } })),
    updateSearchPaginationGroups: (searchPagination:any) => {
      const { query } = searchPagination;
      const groups = { favoriteGroups: [], customGroups: [], systemGroups: [] };
      const customGroupPagination = { ...state.customGroupPagination, page: 1 };
      const systemGroupPagination = { ...state.systemGroupPagination, page: 1 };
      dispatch(actions.UPDATE_STATE({ searchQuery: query, customGroupPagination, systemGroupPagination, ...groups }));
    },
    updateSearchPaginationUsers: (searchPagination:any) => {
      const { query, filters } = searchPagination;
      const usersPagination = { ...state.usersPagination, page: 1 };
      const usersFilters = filters ? { ...state.usersFilters, ...filters } : { ...state.usersFilters };
      dispatch(actions.UPDATE_STATE({ searchQuery: query, usersPagination, groupUsers: [], usersFilters }));
    },
    selectGroup: async (selectedGroup?: any, userList = []) => {
      try {
        emit('sidebar:selectGroup', { selectedGroup });
        const resetGroupUsers = { groupUsers: userList, usersPagination: { ...state.usersPagination, page: 1 } };
        dispatch(actions.UPDATE_STATE({ selectedGroup, ...resetGroupUsers }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    getGroupUsers: async (selectedGroup: any) => {
      try {
        const { usersPagination, searchQuery, usersFilters } = state;
        const filters = usersFiltersBuild(usersFilters);
        const queryOptions = { limit: GROUP_USERS_LIMIT, skip: usersPagination.page, search: searchQuery, filters };
        const data = await getUserGroups(selectedGroup, state.currentDate, queryOptions);
        const { users = [], userPagination, usersByStatus } = data?.data;
        const newUsers = _.uniqBy([...state.groupUsers, ...users], '_id');
        const pagination = userPagination ? { hasMore: userPagination?.next, page: userPagination?.page } : {};
        const newUsersPagination = { ...usersPagination, ...pagination };
        const newUsersByStatus = usersByStatus || INITIAL_DATA_STATUS_FILTER;
        dispatch(actions.UPDATE_STATE({
          groupUsers: newUsers || [],
          usersPagination: newUsersPagination,
          usersByStatus: newUsersByStatus,
        }));
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    disableUser: async (userId: string) => {
      try {
        await disableUser(userId);
        dispatch(actions.DISABLE_USER(userId));
        if (state.selectedGroupUser?._id === userId) {
          dispatch(actions.UPDATE_STATE({ selectedGroupUser: [] }));
          emit('sidebar:selectUser', { selectedGroupUser: [] });
        } else {
          emit('sidebar:disableUser', userId);
        }
      } catch (error) {
        const typedError = error as any;
        toast.error(typedError.response.data.error.message);
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    selectUser: (selectedGroupUser?: any) => {
      dispatch(actions.UPDATE_STATE({ selectedGroupUser }));
      emit('sidebar:selectUser', { selectedGroupUser });
    },
    updateGroup: async (selectedGroup: any, currentUser: any, isUsersContent: boolean) => {
      try {
        let groupUsers = [];
        let { usersByStatus } = state;
        if (state.selectedGroup._id === selectedGroup._id) {
          emit('sidebar:selectGroup', { selectedGroup });
        }
        if (isUsersContent) {
          const data = await getUserGroups(selectedGroup, state.currentDate, { limit: GROUP_USERS_LIMIT });
          groupUsers = data?.data?.users;
          usersByStatus = data?.data?.usersByStatus;
        }
        if (
          !currentUser.isAdmin &&
          !selectedGroup.ownerIds.includes(currentUser._id) &&
          !selectedGroup.sharedWith.includes(currentUser._id)
        ) {
          dispatch(actions.REMOVE_GROUP(selectedGroup._id));
        } else {
          dispatch(actions.UPDATE_GROUP({ selectedGroup, groupUsers, usersByStatus }));
        }
      } catch (error) {
        dispatch(actions.UPDATE_STATE({ error }));
      }
    },
    removeGroup: async (groupId: string) => {
      dispatch(actions.UPDATE_STATE({ selectedGroup: {} }));
      dispatch(actions.REMOVE_GROUP(groupId));
      emit('sidebar:deselectGroup', { selectedGroup: undefined });
    },
    selectPersonalGroup: () => {
      const selectedGroup = {};
      const selectedGroupUser: any[] = [];
      dispatch(actions.UPDATE_STATE({ selectedGroup }));
      emit('sidebar:selectGroup', { selectedGroup });
      emit('sidebar:selectUser', { selectedGroupUser });
    },
  });
}
