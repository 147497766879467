import ActionCreatorFactory from 'utils/actionCreator';

const actionCreator = ActionCreatorFactory('ATTENDANCE');
export default {
  UPDATE_STATE: (properties?: any) => actionCreator('UPDATE_STATE', properties),
  SELECT_GROUP: (group?: any) => actionCreator('SELECT_GROUP', group),
  DESELECT_GROUP: (group?: any) => actionCreator('DESELECT_GROUP', group),
  SELECT_USER: (user?: any) => actionCreator('SELECT_USER', user),
  DISABLE_USER: (userId?: string) => actionCreator('DISABLE_USER', userId),
  UPDATE_USER_EVENT_STATE: (userEvent?: any) => actionCreator('UPDATE_USER_EVENT_STATE', userEvent),
};
