import { APP_CONTENT } from 'utils/constants';

const initialState = {
  language: 'EN-us',
  user: {},
  groupTypesToShow: [],
  lastUserEvent: {},
  contentToShow: APP_CONTENT.HOME,
};

export default initialState;
