import React from 'react';
import { Divider, Input, Dropdown } from 'semantic-ui-react';

import './style.scss';

interface Props {
  children: React.ReactNode,
  onSearch: any,
  onCreate: any,
  webhookSelector: React.ReactNode,
}

export default function GroupMenu({ onSearch, onCreate, children, webhookSelector }: Props) {
  return (
    <section className="group-menu">
      <section className="menu-header">
        <div className="hook-selector">{webhookSelector}</div>
        <div className="action">
          <Dropdown
            text="Create new"
            icon="plus"
            floating
            labeled
            button
            className="icon"
          >
            <Dropdown.Menu>
              <Dropdown.Item icon="tv green" value="channel" text="Channel" onClick={onCreate} />
              <Dropdown.Item icon="users green" value="group" text="Group" onClick={onCreate} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </section>
      <section className="divider">
        <Divider inverted />
      </section>
      <section className="menu-body">
        <div className="search">
          <Input
            size="mini"
            iconPosition="left"
            icon="search"
            placeholder="Search group"
            onChange={(event) => onSearch(event.target.value)}
          />
        </div>
        <div className="wrap-list-item">
          <div className="list-item scroll">{children}</div>
        </div>
      </section>
    </section>
  );
}
