import Axios from 'axios';
import _ from 'lodash';

import { RequestOptions } from '../service/RequestOptions';

import { getOptionsLimit } from './paginationSetup';

let cancelRequestWithPagination: any;

function getData(params: any) {
  const data = params.data?.data;
  const totalPages = Math.ceil(data.totalRows / data.limit);
  const paginationSetup = getOptionsLimit(data.totalRows);
  return { data, totalPages, paginationSetup };
}

export async function requestWithPagination(options: Promise<RequestOptions>) {
  if (typeof cancelRequestWithPagination !== typeof undefined) {
    cancelRequestWithPagination.cancel('Operation canceled due to new request.');
  }
  cancelRequestWithPagination = Axios.CancelToken.source();
  const requestOptions = await (_.isFunction(options) ? options() : options);
  requestOptions.options.cancelToken = cancelRequestWithPagination.token;
  const data = await requestOptions.toAxios();
  return getData(data);
}

export async function request(options: Promise<RequestOptions>) {
  const requestOptions = await (_.isFunction(options) ? options() : options);
  const data = await requestOptions.toAxios();
  return data?.data;
}
