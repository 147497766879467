import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, FormButton, FormField, FormGroup, FormSelect } from 'semantic-ui-react';
import dayjs from 'dayjs';

import TimeInputEditable from 'components/TimeInputEditable';

import style from './ManualRecordForm.module.scss';
import { EVENT_TYPE } from 'utils/constants';

type Props = {
  date: any;
  onSubmit: any;
};

const ManualRecordForm = forwardRef(({ date, onSubmit }: Props, ref): JSX.Element => {
  const [action, setAction] = useState('');
  const [time, setTime] = useState(dayjs().format('HH:mm'));
  const options = [
    { key: EVENT_TYPE.CHECK_IN, value: EVENT_TYPE.CHECK_IN, text: 'Check In' },
    { key: EVENT_TYPE.CHECK_OUT, value: EVENT_TYPE.CHECK_OUT, text: 'Check Out' },
  ];

  // Exposing the clearForm method to the parent
  useImperativeHandle(ref, () => ({
    clearForm,
  }));

  function handleTimeChange(value: any) {
    setTime(value);
  }

  function handleTypeChange(e: any, selected: any) {
    setAction(selected.value);
  }

  function handleSubmit() {
    onSubmit({ action, time, date });
    clearForm();
  }

  function clearForm() {
    setAction('');
    setTime(dayjs().format('HH:mm'));
  }

  function isFormValid() {
    return action !== '' && time !== '';
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup widths={3}>
        <FormSelect
          fluid
          label="Action"
          options={options}
          value={action}
          placeholder="Action"
          required
          onChange={handleTypeChange}
        />
        <FormField required>
          <label>
            Time
            <span className={style.hint}>(12H format)</span>
          </label>
          <TimeInputEditable className={style.timeInput} currentValue={time} format="12" onInputChange={handleTimeChange} />
        </FormField>
        <FormButton className={style.submitButton} disabled={!isFormValid()}>
          Save
        </FormButton>
      </FormGroup>
    </Form>
  );
});

export default ManualRecordForm;
