import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

import style from './style.module.scss';

import { SkypeGroup } from 'types';

interface Props {
  group: SkypeGroup,
  onChange: any,
  error: boolean,
}

const nameRange = {
  maxLenght: 32,
  minLenght: 3,
};

const skype = {
  re: /^[0-9]+:([0-9a-fA-F]{32})@thread\.(skype|tacv2|v2)$/i,
  error: 'The group identifier is not valid',
};

export default function GroupSection({ group, onChange, error }: Props) {
  const [errors, setErrors] = useState({ name: '', _id: '' });
  function handleChange(
    value: any,
    key: string,
    maxLenght?: number,
    minLenght?: number,
    regex?: RegExp,
    regexError?: string,
  ) {
    let stringErrors = '';
    if (minLenght) {
      stringErrors = value[key].length < minLenght ? `The minimum number of characters is ${minLenght}. ` : '';
    }
    if (maxLenght) {
      stringErrors += value[key].length > maxLenght ? `The maximum number of characters is ${maxLenght}. ` : '';
    }
    if (regex) {
      stringErrors += regex.test(value[key]) ? '' : `${regexError}.`;
    }
    const newState = key === 'name' ? { name: stringErrors } : { _id: stringErrors };
    setErrors({ ...errors, ...newState });
    onChange({ ...group, ...value, error: Boolean(stringErrors.length) });
  }

  useEffect(() => {
    if (!error) {
      setErrors({ name: '', _id: '' });
    }
  }, [error]);

  return (
    <section className={style.container}>
      <section className={style.groupInput}>
        <Form.Input
          label="Name"
          placeholder="Group name"
          onChange={(e, { value: name }) => handleChange({ name }, 'name', nameRange.maxLenght, nameRange.minLenght)}
          value={group.name}
          error={errors.name ? { content: errors.name, pointing: 'below' } : null}
          id="form-input-skype-name"
        />
      </section>
      <section className={style.groupInput}>
        <Form.Input
          label="GroupId"
          placeholder="19:64a871935e96485examplec1438c33f36@thread.skype"
          value={group._id}
          error={errors._id ? { content: errors._id, pointing: 'below' } : null}
          id="form-input-skype-id"
          onChange={(e, { value: _id }) => handleChange({ _id }, '_id', undefined, undefined, skype.re, skype.error)}
        />
      </section>
    </section>
  );
}
