import React from 'react';

import style from './MarkersError.module.scss';

export default function MarkersError(): JSX.Element {
  return (
    <div className={style.contentMessage}>
      <div className="ui floating negative message">
        <div className="header">
          There is a problem loading the Map.
        </div>
        <p>
          Please call system administrator
        </p>
      </div>
    </div>
  );
}
