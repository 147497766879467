export const DEFAULT_ZOOM = 3;
export const MIN_ZOOM = 3;
export const GROUP_ZOOM = 6;
export const USER_ZOOM = 16;
export const CATEGORIES: { [key: string]: string } = {
  work: 'Work',
  meeting: 'Meeting',
  training: 'Training',
  pto: 'PTO',
  holiday: 'Holiday',
  other: 'Other',
};

export const AVAILABLE_DAYS: { [key: string]: string } = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
};

export const WORLD_COORDINATES = [-11.6560545, -75.9438316];

export const DEFAULT_EMPLOYEE_LOCATION = {
  LATITUDE: 24.9999986,
  LONGITUDE: -71.0175096,
};

export const BERMUDA_TRIANGULE_COORDINATES: [number, number] = [
  DEFAULT_EMPLOYEE_LOCATION.LONGITUDE,
  DEFAULT_EMPLOYEE_LOCATION.LATITUDE,
];

export const DAY_SECONDS = 86400;

export const EVENT_TYPE = {
  CHECK_IN: 'checkIn',
  CHECK_OUT: 'checkOut',
};

export const MODAL_MODE = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
};

export const GROUP_TYPE = {
  CUSTOM: 'custom', // A custom group shared with other users
  PRIVATE: 'private', // An unshared custom group (not visible to other users)
  SYSTEM: 'system', // A system group
  PUBLIC: 'public', // A system group marked as public in the JOA integration
};

export const DEFAULT_PAGE = 1;

export const DEFAULT_LIMIT_PAGE = 10;

export const DEFAULT_TOTAL_PAGES = 1;

export const DEFAULT_TOTAL_ROWS = 1;

export const SIDEBAR_CONTENT = {
  GROUP: 'group',
  USERS: 'users',
};

export const SUMMARY_HEADER = [
  { key: 'name', label: 'Name' },
  { key: 'date', label: 'Date' },
  { key: 'dailyHours', label: 'Daily Hours' },
  { key: 'total', label: 'Total Hours' },
  { key: 'project', label: 'Project' },
  { key: 'category', label: 'Category' },
  { key: 'notes', label: 'Notes' },
];

export const CANCEL_CALLBACK_ERROR = 'Operation canceled';

export const FILTER_USERS_PICK = {
  ALL: '',
  CHECKIN: 'checkIn',
  CHECKOUT: 'checkOut',
};

export const APP_CONTENT = {
  HOME: 'home',
  ATTENDANCE: 'attendance',
  TIMESHEET: 'timesheet',
};

export const CURRENT_DATE = new Date();

export const LAST_ACTIVITY_LABEL = 'last activity';

export const HHMM = { hour: 'numeric', minute: 'numeric' };

export const WEEKDAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const INITIAL_DATA_STATUS_FILTER = {
  all: 0,
  available: 0,
  notAvailable: 0,
};

export const CONTACT_SUPPORT_URL = 'https://forms.office.com/r/rqXn0AS6A3'
