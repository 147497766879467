import { noop } from 'lodash';
import React, { useRef } from 'react';

import style from './AutosizeTextarea.module.scss';

export default function AutosizeTextArea({ placeholder = null, value = null, onChange = noop }: any): JSX.Element {
  const refContainer = useRef<any>(null);

  function onInput() {
    const { current } = refContainer;
    current.parentNode.dataset.replicatedValue = current.value;
  }

  function onChangeHandler(e: any) {
    onChange(e, refContainer.current);
  }

  return (
    <div className={style.container}>
      <textarea
        ref={refContainer}
        placeholder={placeholder}
        className={style.textarea}
        onInput={onInput}
        onChange={onChangeHandler}
        value={value}
      />
    </div>
  );
}
