import emitter from 'utils/emitter';

export default function listenEvents(state: any, dispatch: any, actions: any) {
  function onNewUserEvent(userEvent: any) {
    dispatch(actions.UPDATE_GLOBAL_EVENT_STATE(userEvent));
  }

  function setLastUserKindEvent({ lastUserEvent: userEvent }: any) {
    if (userEvent) {
      const { type } = userEvent;
      const { lastUserEvent } = state;
      if (!lastUserEvent.type) {
        dispatch(actions.UPDATE_GLOBAL_EVENT_STATE({ lastUserEvent: { type } }));
      }
    }
  }

  emitter.on('socket:globalUserEvent', onNewUserEvent);
  emitter.on('socket:globalLastUserEvent', setLastUserKindEvent);

  return () => {
    emitter.off('socket:globalUserEvent', onNewUserEvent);
    emitter.off('socket:globalLastUserEvent', setLastUserKindEvent);
  };
}
