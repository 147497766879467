import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import Icon from '../../../components/Icon';
import Profile from '../Profile';

import style from './MenuTabs.module.scss';

type MenuTabProps = {
  id: string,
  name: string,
  url: string,
  icon: any,
}

const tabIsActive = (location: any, id: string): boolean => {
  const re = new RegExp(id, 'g');
  return !!location.pathname.match(re);
};

function MenuTab({ id, name, url, icon }: MenuTabProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const isActive = tabIsActive(location, id);

  return (
    <Menu.Item
      onClick={() => history.push(url)}
      active={isActive}
      className={isActive ? style.selectedTab : style.tabItem}
    >
      <span className={style.contentTab}>
        {icon}
        {isActive && <span className={style.menuLabel}>{name}</span>}
      </span>
    </Menu.Item>
  );
}

export default function MenuTabs(): JSX.Element {
  return (
    <div className={style.container}>
      <Menu className={style.tabPicker}>
        <MenuTab
          id="home"
          url="/home"
          name="Map"
          icon={<Icon.Map size={20} />}
        />
        <MenuTab
          id="reports"
          name="Attendance"
          url="/reports"
          icon={<Icon.Clock size={20} />}
        />
        <MenuTab
          id="timesheet"
          name="Timesheet"
          url="/timesheet"
          icon={<Icon.Report size={20} />}
        />
      </Menu>
      <Profile />
    </div>
  );
}
