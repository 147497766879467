import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  footer: {
    flexDirection: 'column',
    opacity: 0.8,
  },
  summary: {
    fontSize: '10px',
    fontFamily: 'HelveticaNeue',
    color: '#D20000',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
  },
  totalHours: {
    width: '115px',
    fontFamily: 'HelveticaNeueLight',
    height: '25px',
    flexDirection: 'row',
    fontSize: '8px',
    justifyContent: 'space-between',
    backgroundColor: '#f1f3f3',
    borderRadius: '6px',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});
