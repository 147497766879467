import React, { useState } from 'react';
import { Modal, Grid, Popup, Button } from 'semantic-ui-react';

import Title from 'components/Title';
import { SkypeGroup } from 'types';

import './style.scss';

interface Props {
  group: SkypeGroup,
  selected: boolean,
  onSelect: any,
  onRemove: any,
}

export default function GroupItem({ group, selected, onSelect, onRemove }: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showOptionsPopup, setShowOptionsPopup] = useState<boolean>(false);

  function deleteItemClick(event: any) {
    event.stopPropagation();
    setShowDeleteModal(false);
    onRemove();
  }

  return (
    <section className={`groupItem ${selected ? 'active' : ''}`}>
      <Grid columns={3}>
        <Grid.Row onClick={onSelect}>
          <Grid.Column className="icon-grid">
            <i className={`${group.type === 'channel' ? 'tv' : 'users'} icon green`} />
          </Grid.Column>
          <Grid.Column className="title-grid">
            <Title title={group.name} offset="15px, 0px" position="top center" />
          </Grid.Column>
          <Popup
            trigger={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Grid.Column className="icon-grid" onClick={(event: Event) => event.stopPropagation()}>
                <i className="ellipsis vertical icon black" />
              </Grid.Column>
            }
            position="bottom right"
            basic
            on="click"
            onClose={() => setShowOptionsPopup(false)}
            onOpen={() => setShowOptionsPopup(true)}
            open={showOptionsPopup}
            id="removeGroupPopup"
          >
            <Button
              negative
              onClick={(event) => {
                event.stopPropagation();
                setShowOptionsPopup(false);
                setShowDeleteModal(true);
              }}
            >
              <i className="trash alt icon white" />
              remove
            </Button>
          </Popup>
        </Grid.Row>
      </Grid>
      <Modal size="tiny" open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Modal.Header>Remove group</Modal.Header>
        <Modal.Content>
          <h4>{`Are you sure you want to delete the ${group.name} group?`}</h4>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button onClick={deleteItemClick} negative>
            Remove
          </Button>
        </Modal.Actions>
      </Modal>
    </section>
  );
}
