import actions from './actions';

import { Action } from 'utils/actionCreator';
import { emit } from 'utils/emitter';

function updateState(state: any, action: Action) {
  return { ...state, ...action.payload };
}

function initSocket(state: any, action: Action) {
  const socket = action.payload;
  return { ...state, socket };
}

function sendCheckInOut(state: any, action: Action) {
  const { socket } = state;
  if (!socket) {
    return { ...state };
  }
  const userEvent = action.payload;
  // Emit locally
  emit('socket:userEvent', userEvent);
  return { ...state };
}

function joinGroup(state: any, action: Action) {
  const { socket } = state;
  const { selectedGroup } = action.payload;
  if (socket) {
    if (selectedGroup?._id) socket.emit('joinGroup', selectedGroup._id);
    else socket.emit('leaveGroups');
  }
  return { ...state, selectedGroup };
}

function setError(state: any, action: Action) {
  const { error } = action.payload;
  return { state, error };
}

export default function eventReducer(state: any, action: Action) {
  switch (action.type) {
    case actions.UPDATE_STATE().type:
      return updateState(state, action);
    case actions.INIT_SOCKET().type:
      return initSocket(state, action);
    case actions.JOIN_GROUP().type:
      return joinGroup(state, action);
    case actions.SET_ERROR().type:
      return setError(state, action);
    case actions.SEND_CHECK_IN_OUT().type:
      return sendCheckInOut(state, action);
    default:
      return state;
  }
}
