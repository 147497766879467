import { QueryOptions } from '../types';

import { BaseService } from './BaseService';

export class SkypeGroupService extends BaseService {
  constructor() {
    super('/api/v1/skypeGroups');
  }

  async getSkypeGroups(queryOptions: QueryOptions = {}): Promise<any> {
    return this.getAll(queryOptions);
  }

  async createSkypeGroup(payload: any, userId?: string, queryOptions: QueryOptions = {}) {
    return this.options({ method: 'post', url: this.urlFor(queryOptions, this.path, userId), data: payload as any });
  }

  async deleteSkypeGroup(groupId: string, userId?: string, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/${groupId}`;
    return this.options({ method: 'delete', url: this.urlFor(queryOptions, path, userId) });
  }

  async updateSkypeGroup(groupId: string, payload: any, userId?: string, queryOptions: QueryOptions = {}) {
    const path = `${this.path}/${groupId}`;
    return this.options({ method: 'patch', url: this.urlFor(queryOptions, path, userId), data: payload as any });
  }

}
