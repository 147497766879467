import dayjs from 'dayjs';

import { FILTER_USERS_PICK, INITIAL_DATA_STATUS_FILTER } from 'utils/constants';

const initialState = {
  groups: [],
  favoriteGroups: [],
  customGroups: [],
  systemGroups: [],
  groupPagination: {
    page: 1,
    hasMore: false,
  },
  favoriteGroupPagination: {
    page: 1,
    hasMore: false,
  },
  customGroupPagination: {
    page: 1,
    hasMore: false,
  },
  systemGroupPagination: {
    page: 1,
    hasMore: false,
  },
  groupsToShow: {
    favorites: true,
    system: false,
    custom: false
  },
  meta: {
    paginationSetup: {},
    totalPages: 0,
  },
  selectedGroup: {},
  groupUsers: [],
  usersPagination: {
    page: 1,
    hasMore: false,
  },
  usersFilters: {
    status: FILTER_USERS_PICK.ALL,
  },
  usersByStatus: INITIAL_DATA_STATUS_FILTER,
  searchQuery: '',
  selectedGroupUser: {},
  error: null,
  currentDate: dayjs().startOf('w').day(1).toDate(),
};

export default initialState;
