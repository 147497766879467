import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import style from './Notification.module.scss';
import Loading from '../../components/Loading';

import NotFound from '../NotFound';

import GroupWorksapce from './organisms/GroupWorkspace';
import {
  formattGroup,
  getEnabledHooks,
  getGroupsByWebhook,
  handleUpdateGroup,
  handleRemoveGroup,
  handleSaveGroup,
} from './helper';

import { useGlobalContext } from 'contexts';

import { useHistory  } from 'react-router-dom';

type SelectedHook = {
  id: string;
  type: string;
};

export default function Notification(): JSX.Element {
  const initialState = { groups: [], enabledWebhooks: [], loading: true, admin: false };
  const [, globalRequest] = useGlobalContext();
  const { isAdmin } = globalRequest;
  const [data, setData] = useState<any>(initialState);
  const [selectedHook, setSelectedHook] = useState<SelectedHook>({ id: '', type: '' });
  const history = useHistory();

  const load = useCallback(async () => {
    try {
      const admin = await isAdmin();
      let groups = [];
      let enabledWebhooks = [];
      if (!admin) {
        return history.push('/home');
      }
      if (admin) {
        enabledWebhooks = await getEnabledHooks();
        if (enabledWebhooks.length) {
          const initialHook = { id: enabledWebhooks[0]._id, type: enabledWebhooks[0].type };
          setSelectedHook(initialHook);
          groups = await getGroupsByWebhook(initialHook.id);
        }
      }
      setData({ groups, enabledWebhooks, loading: false, admin });
    } catch (error) {
      toast.error('There was an error getting the groups information.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  }, [isAdmin]);

  async function removeGroup(groupId: any, groupType: string) {
    try {
      const groups = await handleRemoveGroup(selectedHook.type, groupType, groupId, data.groups);
      setData({ ...data, groups });
      toast.success('group removed successfully.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    } catch (error) {
      toast.error('There was an error deleting the group.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  }

  async function createGroup(group: any) {
    try {
      const groups = await handleSaveGroup(selectedHook.type, group, data.groups);
      setData({ ...data, groups });
      toast.success('group created successfully.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    } catch (error) {
      toast.error('There was an error creating the group.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  }

  async function updateGroup(groupId: string, newGroup: any) {
    try {
      const groups = await handleUpdateGroup(selectedHook.type, groupId, newGroup, data.groups);
      setData({ ...data, groups });
      toast.success('group updated successfully.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    } catch (error) {
      toast.error('There was an error updating the group.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  }

  const onChangeWebhook = async (option: SelectedHook) => {
    const currentGroups = await getGroupsByWebhook(option.id);
    setSelectedHook(option);
    setData({ ...data, groups: currentGroups });
  };

  useEffect(() => {
    load();
  }, [load]);

  return (
    <section className={style["notification-body"]}>
      {data.loading ? (
        <Loading />
      ) : data.admin ? (
        <GroupWorksapce
          groups={formattGroup(data.groups)}
          onRemove={removeGroup}
          onSave={createGroup}
          onUpdate={updateGroup}
          webhooks={data.enabledWebhooks}
          onSelectHook={onChangeWebhook}
        />
      ) : (
        <NotFound />
      )}
    </section>
  );
}
