import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from 'components/Loading';

import { getRedirectPath } from 'utils/redirect';
import { useGlobalContext } from 'contexts';

export default function CallBack(): JSX.Element {
  const [, request] = useGlobalContext();
  const history = useHistory();
  const { getUser } = request;

  useEffect(() => {
    (async () => {
      const user = await getUser();
      await request.setCurrentUser();
      history.push({
        pathname: getRedirectPath('/home'),
        state: { user },
      });
    })();
  });
  return <Loading />;
}
