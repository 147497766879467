export type SocketContextState = {
  socket: any,
  error: string | null,
  selectedGroup: any,
};

const initialState: SocketContextState = {
  socket: null,
  error: null,
  selectedGroup: null,
};

export default initialState;
