import React from 'react';
import dayjs from 'dayjs';

import { Form, Dropdown } from 'semantic-ui-react';

import TimeInput from '../../../../components/TimeInput';

import style from './style.module.scss';

import { SkypeGroup, Time } from 'types';

import { AVAILABLE_DAYS } from 'utils/constants';

interface Props {
  group: SkypeGroup,
  onChange: any,
}

const availableDays = Object.entries(AVAILABLE_DAYS).map(([key, value]) => ({ key, text: value, value: key }));

export default function NotificationSection({ group, onChange }: Props) {
  function handleChange(setting: Time, field: string) {
    onChange({ [`weekly${field}`]: setting });
  }

  return (
    <section className={style.container}>
      <NotificationFieldsSection setting={group?.weeklyTimesheet} onChange={handleChange} field="Timesheet" />
      <NotificationFieldsSection setting={group?.weeklyCheckInOut} onChange={handleChange} field="CheckInOut" />
    </section>
  );
}

interface FieldProps {
  setting?: Time,
  onChange: any,
  field: string,
}

function NotificationFieldsSection({ setting, onChange, field }: FieldProps) {
  function onChangefield(newTimeString: string, key = 'time') {
    const timezoneOffset = dayjs().format('Z');
    onChange({ ...setting, [key]: newTimeString, timezoneOffset }, field);
  }

  function onChangeDay(e: any, data: any) {
    return onChangefield(data.value, 'day');
  }

  return (
    <>
      <span className={style.title}>{field}</span>
      <Form.Group className={style.categoryTime}>
        <Form.Field
          control={Dropdown}
          onChange={onChangeDay}
          options={availableDays}
          placeholder="Day"
          openOnFocus={false}
          search
          fluid
          selection
          selectOnBlur={false}
          value={setting?.day || ''}
          className={style.category}
          autoFocus
        />
        <Form.Field>
          <TimeInput
            currentValue={setting?.time || ''}
            onInputChange={onChangefield}
            className={style.time}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
}

NotificationFieldsSection.defaultProps = {
  setting: {
    day: '',
    time: '',
    timezoneOffset: '',
  },
};
