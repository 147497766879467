import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HiOutlineLogin, HiOutlineLogout } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { Menu, Dropdown, Loader } from 'semantic-ui-react';

import { getCurrentPosition } from '../../../utils/location';

import { Avatar } from 'components/Avatar';
import style from './Profile.module.scss';

import { useGlobalContext, useSocketContext, useAttendanceContext } from 'contexts';
import { SourceApp } from 'types';

import { CONTACT_SUPPORT_URL, EVENT_TYPE } from 'utils/constants';

type CheckInOutEventProps = {
  loading: boolean,
  isCheckIn: boolean,
  onClick: () => void,
};

function CheckInOutEvent({ loading, isCheckIn, onClick }: CheckInOutEventProps): JSX.Element {
  return (
    <Dropdown.Item
      className={style.checkInOut}
      onClick={onClick}
      disabled={loading}
    >
      <div className={style.ciCoButton}>
        {isCheckIn ? (
          <HiOutlineLogin size={20} className={`${style.icon} ${style.checkOutIcon}`} />
        ) : (
          <HiOutlineLogout size={20} className={`${style.icon} ${style.checkInIcon}`} />
        )}
        <span className={isCheckIn ? style.checkInLabel : style.checkOutLabel}>
          {isCheckIn ? 'Check Out' : 'Check In'}
        </span>
        {loading && <Loader active inline="centered" size="mini" />}
      </div>
    </Dropdown.Item>
  );
}

function getEventInformation(type: string, place: string, createdAt: string): CheckInOutMessageProps {
  return {
    place,
    date: dayjs(createdAt).format('ddd, MMMM Do'),
    hour: dayjs(createdAt).format('h:mm'),
    format: dayjs(createdAt).format('A'),
    type: type === EVENT_TYPE.CHECK_IN ? 'IN' : 'OUT',
  };
}

type CheckInOutMessageProps = {
  date: string,
  hour: string,
  format: string,
  type: string,
  place: string,
};

function CheckInOutMessage({ date, hour, format, type, place }: CheckInOutMessageProps): any {
  return () => (
    <div>
      <span className={style.headerMessage}>{date}</span>
      <p className={style.bodyMessage}>
        <span className={style.hourMessage}>{hour}</span>
        <span className={style.formatMessage}>{format}</span>
        <span className={`${type === 'IN' ? style.inMessage : style.outMessage}`}>{type}</span>
        <span className={style.placeMessage}>{place}</span>
      </p>
    </div>
  );
}

export default function Profile(): JSX.Element {
  const [globalState, globalRequest, globalActions, globalDispatch] = useGlobalContext();
  const [, attendanceRequest] = useAttendanceContext();
  const { getLastUserEventById } = attendanceRequest;
  const { user, lastUserEvent } = globalState;
  const { createUserEvent } = globalRequest;
  const [, socketActions, socketDispatch] = useSocketContext();
  const initialState = { admin: false };
  const [data, setData] = useState<any>(initialState);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { isAdmin } = globalRequest;

  const load = useCallback(async () => {
    if (!user._id) return;
    const result = await getLastUserEventById(user._id);
    const admin = await isAdmin();
    setData({ admin });
    globalDispatch(globalActions.UPDATE_STATE({ lastUserEvent: result }));
  }, [globalDispatch, globalActions, getLastUserEventById, user, isAdmin]);

  useEffect(() => {
    load();
  }, [user]); // eslint-disable-line

  function buildEventAction(isCheckIn: boolean, location: any) {
    return {
      type: isCheckIn ? EVENT_TYPE.CHECK_OUT : EVENT_TYPE.CHECK_IN,
      location,
      timezoneOffset: dayjs().format('Z'),
      sourceApp: SourceApp.WEB,
    };
  }

  async function onCheckInOutHandler() {
    setLoading(true);
    const isCheckIn = lastUserEvent.type === EVENT_TYPE.CHECK_IN;
    const location = await getCurrentPosition();
    const eventData = buildEventAction(isCheckIn, location);
    const eventResponse = await createUserEvent(eventData);
    const { type, place, createdAt } = eventResponse;
    const eventInformation = getEventInformation(type, place, createdAt);
    const MessageComponent = CheckInOutMessage(eventInformation);
    socketDispatch(socketActions.SEND_CHECK_IN_OUT(eventResponse));
    globalDispatch(globalActions.UPDATE_STATE({ lastUserEvent: eventData }));
    toast(<MessageComponent />);
    setLoading(false);
  }

  return (
    <Menu secondary className={style.profile}>
      <Dropdown
        as={Menu.Item}
        floating
        icon={(
          <Avatar
            src={user?.picture}
            name={user?.preferredUsername}
            size={40}
            isCheckIn={lastUserEvent.type === EVENT_TYPE.CHECK_IN}
          />
        )}
      >
        <Dropdown.Menu direction="left">
          <CheckInOutEvent
            loading={loading}
            isCheckIn={lastUserEvent.type === EVENT_TYPE.CHECK_IN}
            onClick={onCheckInOutHandler}
          />
          { data.admin === true ? (<Dropdown.Item onClick={() => history.push('/settings/notification')}>Settings</Dropdown.Item>) : null}
          <Dropdown.Item onClick={() => window.open(CONTACT_SUPPORT_URL, '_blank')}>Support</Dropdown.Item>
          <Dropdown.Item onClick={() => history.push('/logout')}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
}

Profile.defaultProps = {
  profilePictureSrc: null,
};
