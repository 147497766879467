import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import dayjs from 'dayjs';

import User from '../User';
import ErrorMessage from '../ErrorMessage';

import style from './UserList.module.scss';

import { APP_CONTENT, DEFAULT_PAGE, FILTER_USERS_PICK } from 'utils/constants';
import { useAttendanceContext } from 'contexts';
import AttendanceModal from 'components/Attendance/AttendanceModal';
import ActivitiesModal from 'components/Attendance/ActivitiesModal';
import AttendancePagination from 'components/Attendance/Pagination';
import Loader from 'components/Loader';
import { filterUsers } from 'layout/Sidebar/helper';
import { getUserInformation } from 'utils/attendance';
import { usePDF } from 'hooks/usePDF';
import useLoadItems from 'hooks/useLoadItems';

import { useUser } from 'hooks/useUser';

const ATTENDANCE_MODAL_STATE_DEFAULT = () => ({ userId: '', date: '', isOpen: false });
const ATTENDANCE_MODAL_STATE_DETAIL = (userId: string, date: any) => ({ userId, date, isOpen: true });

const ACTIVITY_MODAL_STATE_DEFAULT = () => ({ userId: '', date: '', selectedGroupId: '', isOpen: false });
const ACTIVITY_MODAL_STATE_DETAIL = (userId: string, selectedGroupId: string, date: any) =>
  ({ userId, date, selectedGroupId, isOpen: true });

function isUserSelected(selectedUsers: any[], user: any): boolean {
  return selectedUsers.some((selectedUser: any) => user._id === selectedUser._id);
}

type UserListHomeProps = {
  loading: boolean,
  selectedUsers: any[],
  users: any[],
  mode: string,
  error: any,
  globalUserIsAdmin: boolean,
  globalUserIsManager: boolean,
  globalUserId: string,
  onClick: (user: any) => void,
  loadMore: () => void,
  request: () => void,
  pagination: any,
};

function UserListHome({
  loading,
  users,
  mode,
  error,
  onClick,
  selectedUsers,
  loadMore,
  pagination,
  request,
  globalUserIsAdmin,
  globalUserIsManager,
  globalUserId,
}: UserListHomeProps): JSX.Element {
  const { isLoading } = useLoadItems(request, pagination.page, pagination.query);
  const hasLoadMore = !isLoading && pagination.hasMore;
  if (loading) {
    return (
      <div className={style.loader}><Loader active inline="centered" size="medium" /></div>
    );
  }
  if (error) {
    return (
      <ErrorMessage title="There is a problem loading the users." content="Please call system administrator" />
    );
  }
  if (!users.length && !isLoading) {
    return (
      <div className={style.noUsers}>
        No users found
      </div>
    );
  }
  return (
    <>
      {
        users.map((user: any) => (
          <User
            selected={isUserSelected(selectedUsers, user)}
            key={user._id}
            user={user}
            mode={mode}
            globalUserIsAdmin={globalUserIsAdmin}
            globalUserIsManager={globalUserIsManager}
            globalUserId={globalUserId}
            onClick={onClick}
          />
        ))
      }
      {hasLoadMore && (
        <div className="loadMore">
          <Button basic onClick={loadMore}>LOAD MORE</Button>
        </div>
      )}
      {isLoading && <div className={style.loader}><Loader active inline="centered" size="medium" /></div>}
    </>
  );
}

type UserListAttendanceTimesheetProps = {
  loading: boolean,
  users: any[],
  mode: string,
  dateRange: any[],
  error: any,
  globalUserIsAdmin: boolean,
  globalUserIsManager: boolean,
  globalUserId: string,
  onAttendanceDetailClick: (userId: string, date: any) => void,
  onAddActivityClick: (userId: string, date: any) => void,
  onDownloadReportClick: (userId: string) => void,
  onDownloadDailyReportClick: (userId: string, date: any) => void,
}

function UserListAttendanceTimesheet({
  loading,
  users,
  mode,
  dateRange,
  error,
  globalUserIsAdmin,
  globalUserIsManager,
  globalUserId,
  onAttendanceDetailClick,
  onAddActivityClick,
  onDownloadReportClick,
  onDownloadDailyReportClick,
}: UserListAttendanceTimesheetProps) {
  if (loading) {
    return (
      <div className={style.loader}><Loader active inline="centered" size="medium" /></div>
    );
  }
  if (error) {
    return (
      <ErrorMessage title="There is a problem loading the attendances." content="Please call system administrator" />
    );
  }
  if (!users.length) {
    return (
      <div className={style.noUsers}>
        No users found
      </div>
    );
  }
  return (
    <>
      {
        users.map((user: any) => (
          <User
            key={user.id}
            user={user}
            mode={mode}
            globalUserIsAdmin={globalUserIsAdmin}
            globalUserIsManager={globalUserIsManager}
            globalUserId={globalUserId}
            dateRange={dateRange}
            onAttendanceDetailClick={onAttendanceDetailClick}
            onAddActivityClick={onAddActivityClick}
            onDownloadDailyReportClick={onDownloadDailyReportClick}
            onDownloadReportClick={onDownloadReportClick}
          />
        ))
      }
    </>
  );
}

type UserListProps = {
  loadingGroupUsers: boolean,
  mode: string,
  groupUsers: any[],
  searchInput: string,
  errorGroupUsers: any,
  onSelectUser: (user: any) => void,
  loadMoreUsers: () => void,
  requestUsers: () => void,
  usersPagination: any,
}

export default function UserList({
  loadingGroupUsers,
  mode,
  groupUsers,
  errorGroupUsers,
  searchInput,
  onSelectUser,
  usersPagination,
  loadMoreUsers,
  requestUsers,
}: UserListProps): JSX.Element {
  // Attendance Context
  const [attendanceState, attendanceRequest] = useAttendanceContext();
  const {
    selectedGroup,
    project,
    selectedGroupUser: selectedUsers,
    date: { startDate, endDate },
    error,
    attendancesList,
    meta,
    pagination,
    week,
  } = attendanceState;
  const {
    getAttendances,
    updatePagination,
    getUserTimesheet,
    cleanUserTimesheet,
    getLastUserEventById,
  } = attendanceRequest;
  const { totalPages, paginationSetup } = meta;
  const { activePage, limit } = pagination;

  const [loadingAttendancesTimesheet, setLoadingAttendancesTimesheet] = useState(true);

  const [attendanceModalState, setAttendanceModalState] = useState(ATTENDANCE_MODAL_STATE_DEFAULT());
  const [activityModalState, setActivityModalState] = useState(ACTIVITY_MODAL_STATE_DEFAULT());
  const [attendanceDate, setAttendanceDate] = useState(attendanceModalState?.date || dayjs().format('YYYY-MM-DD'));

  const fetchAttendanceTimesheet = useCallback(async () => {
    setLoadingAttendancesTimesheet(true);
    await getAttendances();
    setLoadingAttendancesTimesheet(false);
  }, [getAttendances]);

  useEffect(() => {
    if (mode !== APP_CONTENT.HOME) {
      fetchAttendanceTimesheet();
    }
  }, [startDate, endDate, activePage, limit, selectedUsers, selectedGroup, project, mode]); // eslint-disable-line

  const { currentUserIsAdmin, currentUserIsManager, currentUser } = useUser();

  function onChangePage(event: any, pageInfo: any) {
    updatePagination({ ...pagination, activePage: pageInfo.activePage });
  }

  function onSetOptionValue(event: any, optionValue: any) {
    updatePagination({ limit: optionValue.value, activePage: DEFAULT_PAGE });
  }

  async function onDownloadReportClick(userId: string) {
    const userInfo = getUserInformation(userId, { startDate, endDate }, project);
    await getUserTimesheet(userInfo);
  }

  async function onDownloadDailyReportClick(userId: string, date: any) {
    const userInfo = getUserInformation(userId, date, project);
    await getUserTimesheet(userInfo);
  }

  async function handleOpenMarker(userId: string) {
    return getLastUserEventById(userId);
  }

  const handleAttendanceDetailClick = (userId: string, date: string) => {
    setAttendanceDate(date)
    setAttendanceModalState(ATTENDANCE_MODAL_STATE_DETAIL(userId, date));
  };

  function handleOnDateChange(date: any) {
    setAttendanceDate(date);
  }

  usePDF(() => {
    cleanUserTimesheet();
  });

  return (
    <div className={style.container}>
      <div className={style.listContainer}>
        {
          mode === APP_CONTENT.HOME ? (
            <UserListHome
              selectedUsers={selectedUsers}
              loading={loadingGroupUsers}
              users={filterUsers(groupUsers, FILTER_USERS_PICK.ALL)}
              error={errorGroupUsers}
              onClick={onSelectUser}
              mode={mode}
              globalUserIsAdmin={currentUserIsAdmin}
              globalUserIsManager={currentUserIsManager}
              globalUserId={currentUser?.profile.sub}
              loadMore={loadMoreUsers}
              pagination={usersPagination}
              request={requestUsers}
            />
          ) : (
            <UserListAttendanceTimesheet
              loading={loadingAttendancesTimesheet}
              users={filterUsers(attendancesList, FILTER_USERS_PICK.ALL)}
              dateRange={week}
              mode={mode}
              error={error}
              globalUserIsAdmin={currentUserIsAdmin}
              globalUserIsManager={currentUserIsManager}
              globalUserId={currentUser?.profile.sub}
              onAttendanceDetailClick={handleAttendanceDetailClick}
              onAddActivityClick={
                (userId: string, date: any) => setActivityModalState(
                  ACTIVITY_MODAL_STATE_DETAIL(userId, selectedGroup?._id ?? '', date.format('YYYY-MM-DD')),
                )
              }
              onDownloadReportClick={onDownloadReportClick}
              onDownloadDailyReportClick={onDownloadDailyReportClick}
            />
          )
        }
      </div>
      {
        mode !== APP_CONTENT.HOME && (
          <AttendancePagination
            activePage={activePage}
            limit={limit}
            onChangePage={onChangePage}
            setOptionValue={onSetOptionValue}
            totalPages={totalPages}
            paginationSetup={paginationSetup}
            boundaryRange={0}
            siblingRange={0}
            ellipsisItem={null}
            className={style.pagination}
          />
        )
      }
      {
        attendanceModalState.isOpen && (
          <AttendanceModal
            opened={attendanceModalState.isOpen}
            userId={attendanceModalState.userId}
            date={attendanceDate}
            onClose={() => setAttendanceModalState(ATTENDANCE_MODAL_STATE_DEFAULT())}
            onOpenMarker={handleOpenMarker}
            onDateChange={handleOnDateChange}
          />
        )
      }
      {
        activityModalState.isOpen && (
          <ActivitiesModal
            opened={activityModalState.isOpen}
            date={activityModalState.date}
            selectedGroupId={activityModalState.selectedGroupId}
            userId={activityModalState.userId}
            globalUserId={currentUser?.profile.sub}
            onClose={() => {
              setActivityModalState(ACTIVITY_MODAL_STATE_DEFAULT());
              fetchAttendanceTimesheet();
            }}
          />
        )
      }
    </div>
  );
}
