import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    backgroundColor: '#f1f3f3',
    height: '15%',
    paddingTop: '40px',
    paddingLeft: '50px',
    paddingRight: '50px',
    marginTop: -60,
  },
  userInfo: {
    flexDirection: 'column',
    width: '74%',
    paddingTop: '10px',
  },
  userName: {
    fontSize: '18px',
    paddingTop: '5px',
    fontFamily: 'HelveticaNeueLight',
  },
  userEmail: {
    fontSize: '10px',
    color: '#D20000',
    fontFamily: 'HelveticaNeueLight',
    paddingTop: '5px',
    opacity: 0.8,
  },
  week: {
    flexDirection: 'row',
    fontSize: '10px',
    fontFamily: 'HelveticaNeueLight',
    paddingTop: '5px',
  },
  weekLabel: {
    paddingRight: '3px',
  },
  weekSeparator: {
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  logo: {
    width: '26%',
    alignItems: 'flex-end',
  },
  image: {
    height: '20px',
    width: '85px',
  },
});
