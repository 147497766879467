import React from 'react';
import _ from 'lodash';
import { Grid } from 'semantic-ui-react';

import GroupItem from '../GroupItem';
import './style.scss';

interface Props {
  groups: any[],
  selected: string,
  emptyMessage: string,
  onSelect: any,
  onRemove: any,
}

export default function GroupItemList({ groups, selected, emptyMessage, onSelect, onRemove }: Props) {
  return (
    <section className="groupItemList">
      {!groups.length ? (
        <div className="isEmpty">
          <Grid centered>
            <Grid.Row>
              <p>{emptyMessage}</p>
            </Grid.Row>
            <Grid.Row>
              <i className="frown icon grey large" />
            </Grid.Row>
          </Grid>
        </div>
      ) : (
        _.map(groups, (group: any) => (
          <GroupItem
            key={group._id}
            group={group}
            onSelect={() => onSelect(group)}
            onRemove={() => onRemove(group._id, group.type)}
            selected={selected === group._id}
          />
        ))
      )}
    </section>
  );
}
