import emitter from 'utils/emitter';

export default function listenEvents(state: any, dispatch: any, actions: any, requests: any) {
  async function onNewUserEvent(userEvent: any) {
    const { selectedGroup } = state;
    if (selectedGroup._id) {
      const { getGroupUsers } = requests;
      getGroupUsers(selectedGroup);
    }
    dispatch(actions.UPDATE_USER_EVENT_STATE(userEvent));
  }

  emitter.on('socket:userEvent', onNewUserEvent);

  return () => {
    emitter.off('socket:userEvent', onNewUserEvent);
  };
}
