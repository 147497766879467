import React, { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

const mapHooks = (hooks?: Array<any>) => {
  const mapCallback = (hook: any) => ({ text: hook.name, value: hook._id, key: hook._id });
  const hooksMapped = hooks?.map(mapCallback) ?? [];
  return hooksMapped;
};

type Props = {
  hooks: Array<any>;
  onChange: any;
};

export default function WebhookSelector({ hooks, onChange }: Props): JSX.Element {
  const defaultOptions = mapHooks(hooks);
  const selectedOption = hooks[0]?._id || '';

  const onChangeHandle = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const selectedHook = hooks.find((hook) => hook._id === data.value);
    const value = { type: selectedHook.type, id: data.value };
    onChange(value);
  };

  return (
    <Dropdown
      fluid
      search
      selection
      options={defaultOptions}
      onChange={onChangeHandle}
      defaultValue={selectedOption}
      placeholder="Select webhook"
    />
  );
}
